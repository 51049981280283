import { ScheduleModalProgress } from '../../../components/schedule/schedule-modal-progress/schedule-modal-progress.component'
import { useScheduleLongtermPageModalProgress } from './schedule-longterm-page-modal-progress.hook'
import { ScheduleLongtermPageModalProgressProps } from './schedule-longterm-page-modal-progress.types'

export const ScheduleLongtermPageModalProgress: React.FC<ScheduleLongtermPageModalProgressProps> = props => {
  const { action, totalExercises, totalBookings } = props

  const { messages, studioOffset, onCancelHandler, onConflictResolutionHandler } =
    useScheduleLongtermPageModalProgress(props)

  return (
    <ScheduleModalProgress
      title={action === 'CREATE' ? 'Создание длинной записи' : 'Обновление длинной записи'}
      type={action === 'CREATE' ? 'create' : 'edit'}
      totalExercises={totalExercises}
      totalBookings={totalBookings}
      messages={messages}
      studioOffset={studioOffset}
      onConflictResolution={onConflictResolutionHandler}
      onCancel={onCancelHandler}
    />
  )
}
