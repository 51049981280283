import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import { contractsPageActions } from '../../store/pages/contracts-page/contracts-page.slice'
import { getCounterpartiesIsLoading } from '../../store/pages/contracts-page/contracts-page.selectors'

export function useContractsPage() {
  const dispatch = useDispatch()
  const isLoading = useSelector(getCounterpartiesIsLoading)

  React.useEffect(() => {
    dispatch(contractsPageActions.fetchAllCounterparties())
    dispatch(contractsPageActions.fetchAllManagers())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(contractsPageActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
  }
}
