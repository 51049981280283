import { Alert, Badge, Col, Flex, Row, Select, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { FC } from 'react'
import {
  CalendarOutlined,
  InboxOutlined,
  InsertRowAboveOutlined,
  SolutionOutlined,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons'

import { formatPhoneNumber } from '../../../format/phone.format'
import { formatPluralized } from '../../../format/text.format'
import { ScheduleOverviewProps } from './schedule-overview.types'
import './schedule-overview.styles.less'

export const ScheduleOverview: FC<ScheduleOverviewProps> = props => {
  const { schedule, onClient } = props

  const onClientHandler = () => {
    if (schedule.client?.id) {
      onClient(schedule.client.id)
    }
  }

  return (
    <Flex vertical gap={16}>
      <Typography.Title level={4} style={{ margin: 0 }}>
        {schedule.client ? `Длинная запись клиента ${formatPhoneNumber(schedule.client.phone)}` : 'Расписание'}
      </Typography.Title>
      {schedule.comment && <Alert message={schedule.comment} type="info" showIcon />}
      <Flex vertical gap={16}>
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <InsertRowAboveOutlined className="schedule-overview__icon" />
              Количество
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>
              {formatPluralized(schedule.totalExercises, ['запись', 'записи', 'записей'])}
            </Typography.Text>
          </Col>
        </Row>
        <Row align="middle">
          <Col style={{ width: '250px' }}>
            <Typography.Text type="secondary">
              <CalendarOutlined className="schedule-overview__icon" />
              Период
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>
              С {dayjs(schedule.dateFrom).format('DD MMMM YYYY')} по {dayjs(schedule.dateTo).format('DD MMMM YYYY')}
            </Typography.Text>
          </Col>
        </Row>
        {schedule.direction && (
          <Row align="middle">
            <Col style={{ width: '250px' }}>
              <Typography.Text type="secondary">
                <InboxOutlined className="schedule-overview__icon" />
                Услуга
              </Typography.Text>
            </Col>
            <Col>
              <Tag color="magenta">{schedule.direction?.name}</Tag>
            </Col>
          </Row>
        )}
        {schedule.client && (
          <>
            <Row align="middle">
              <Col style={{ width: '250px' }}>
                <Typography.Text type="secondary">
                  <SolutionOutlined className="schedule-overview__icon" />
                  Статус оплаты
                </Typography.Text>
              </Col>
              <Col>
                {schedule.payedBookingsCount === schedule.totalExercises ? (
                  <Tag color="green">Оплачено</Tag>
                ) : schedule.payedBookingsCount === 0 ? (
                  <Tag color="orange">Не оплачено</Tag>
                ) : (
                  <Badge
                    status="processing"
                    text={`Оплачено ${schedule.payedBookingsCount} из ${schedule.totalExercises}`}
                  />
                )}
              </Col>
            </Row>
            <Row align="middle">
              <Col style={{ width: '250px' }}>
                <Typography.Text type="secondary">
                  <StarOutlined className="schedule-overview__icon" />
                  Клиент
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Link onClick={onClientHandler}>
                  {formatPhoneNumber(`+${schedule.client.phone}`)}, {schedule.client.name}
                </Typography.Link>
              </Col>
            </Row>
            <Row align="middle">
              <Col style={{ width: '250px' }}>
                <Typography.Text type="secondary">
                  <UserOutlined className="schedule-overview__icon" />
                  Менеджер
                </Typography.Text>
              </Col>
              <Col>
                <Select style={{ width: '177px' }} placeholder="Менеджер" disabled />
              </Col>
            </Row>
          </>
        )}
      </Flex>
    </Flex>
  )
}
