import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import { ContractsApi } from '../../../api/types/contracts-api.types'
import { contractsPageActions } from '../../../store/pages/contracts-page/contracts-page.slice'
import {
  getCounterpartiesCardsList,
  getCounterpartiesIsLoading,
  getManagersCardsList,
} from '../../../store/pages/contracts-page/contracts-page.selectors'

export function useContractsPageList() {
  const dispatch = useDispatch()
  const counterparties = useSelector(getCounterpartiesCardsList)
  const managers = useSelector(getManagersCardsList)
  const isLoading = useSelector(getCounterpartiesIsLoading)

  const onChangeManagerHandler = useCallback(
    (managerId: ContractsApi.ManagerId, contractId: string) => {
      if (counterparties) {
        dispatch(
          contractsPageActions.updateManager({
            contractId,
            managerId,
          })
        )
      }
    },
    [counterparties, dispatch]
  )

  return {
    counterparties,
    managers,
    isLoading,
    onChangeManagerHandler,
  }
}
