import { Form } from 'antd'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ScheduleFormValuesDTO } from '../../../components/schedule/schedule-form/schedule-form.types'
import { modalActions } from '../../../store/common/modal/modal.slice'
import {
  getScheduleLongtermPageModalCreateDirections,
  getScheduleLongtermPageModalCreateDirectionsOptions,
  getScheduleLongtermPageModalCreateExercisesTypesOptions,
  getScheduleLongtermPageModalCreateIsLoading,
  getScheduleLongtermPageModalCreateStudiosRoomsOptions,
  getScheduleLongtermPageModalCreateTrainersOptions,
} from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-modal-create/schedule-longterm-page-modal-create.selectors'
import { scheduleLongtermPageModalCreateActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-modal-create/schedule-longterm-page-modal-create.slice'

export function useScheduleLongtermPageModalCreate(studioId: string) {
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const isLoading = useSelector(getScheduleLongtermPageModalCreateIsLoading)
  const directions = useSelector(getScheduleLongtermPageModalCreateDirections)
  const directionsOptions = useSelector(getScheduleLongtermPageModalCreateDirectionsOptions)
  const exercisesTypesOptions = useSelector(getScheduleLongtermPageModalCreateExercisesTypesOptions)
  const studiosRoomsOptions = useSelector(getScheduleLongtermPageModalCreateStudiosRoomsOptions)
  const trainersOptions = useSelector(getScheduleLongtermPageModalCreateTrainersOptions)

  const onSaveHandler = useCallback(
    (scheduleFormValues: ScheduleFormValuesDTO) => {
      dispatch(
        scheduleLongtermPageModalCreateActions.createSchedule({
          studioId,
          scheduleFormValues,
        })
      )
    },
    [dispatch, studioId]
  )

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  useEffect(() => {
    dispatch(scheduleLongtermPageModalCreateActions.fetchDictionaries(studioId))
  }, [dispatch, studioId])

  useEffect(() => {
    return () => {
      dispatch(scheduleLongtermPageModalCreateActions.reset())
    }
  }, [dispatch])

  return {
    form,
    isLoading,
    directions,
    directionsOptions,
    exercisesTypesOptions,
    studiosRoomsOptions,
    trainersOptions,
    onCancelHandler,
    onSaveHandler,
  }
}
