import { Form, FormInstance } from 'antd'
import { useCallback, useState } from 'react'

import { useTheme } from '../../../hooks/use-theme.hook'
import { ScheduleEditExercisesFormValues } from './schedule-edit-exercises-form.types'

interface Props {
  form: FormInstance<ScheduleEditExercisesFormValues>
  onEditExercises: () => void
}

export function useScheduleEditExercisesForm(props: Props) {
  const { form, onEditExercises } = props

  const { theme } = useTheme()

  const [activeKeys, setActiveKeys] = useState<string[]>([])

  // const studioId = Form.useWatch(['studio', 'newValue'], form)
  const selectedTrainers = Form.useWatch(['trainers', 'newValue'], form) || []

  const onCollapseChangeHandler = useCallback((keys: string | string[]) => {
    setActiveKeys(Array.isArray(keys) ? keys : [keys])
  }, [])

  const isTrainerOptionDisabled = (optionValue: string) => {
    return selectedTrainers.includes('noTrainer') && optionValue !== 'noTrainer'
  }

  const onChangeTrainersHandler = useCallback(
    (value: string[]) => {
      if (value.includes('noTrainer')) {
        form.setFieldsValue({ trainers: { newValue: ['noTrainer'] } })
      }
    },
    [form]
  )

  const onChangeStudioHandler = useCallback(
    (value: string): void => {
      const values = form.getFieldsValue()

      form.setFieldsValue({
        ...values,
        studio: {
          ...values.studio,
          newValue: value,
        },
        room: {
          ...values.room,
          newValue: undefined,
        },
      })
    },
    [form]
  )

  const onEditHandler = useCallback(async () => {
    try {
      await form.validateFields()
      onEditExercises()
    } catch (errorInfo) {}
  }, [form, onEditExercises])

  return {
    theme,
    activeKeys,
    // studioId,
    isTrainerOptionDisabled,
    onChangeTrainersHandler,
    onCollapseChangeHandler,
    onChangeStudioHandler,
    onEditHandler,
  }
}
