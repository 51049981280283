import { useCallback, useEffect } from 'react'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import {
  ScheduleFormValues,
  ScheduleFormValuesDTO,
} from '../../../components/schedule/schedule-form/schedule-form.types'
import {
  genScheduleManagementPageModalCreateIsLoading,
  getScheduleManagementPageModalCreateDirections,
  getScheduleManagementPageModalCreateDirectionsOptions,
  getScheduleManagementPageModalCreateExercisesTypesOptions,
  getScheduleManagementPageModalCreateStudiosRoomsOptions,
  getScheduleManagementPageModalCreateTrainersOptions,
} from '../../../store/pages/schedule-management-page/schedule-management-page-modal-create/schedule-management-page-modal-create.selectors'
import { scheduleManagementPageModalCreateActions } from '../../../store/pages/schedule-management-page/schedule-management-page-modal-create/schedule-management-page-modal-create.slice'
import { modalActions } from '../../../store/common/modal/modal.slice'

export function useScheduleManagementPageModalCreate(studioId: string) {
  const [form] = Form.useForm<ScheduleFormValues>()

  const dispatch = useDispatch()

  const isLoading = useSelector(genScheduleManagementPageModalCreateIsLoading)
  const directions = useSelector(getScheduleManagementPageModalCreateDirections)
  const directionsOptions = useSelector(getScheduleManagementPageModalCreateDirectionsOptions)
  const exercisesTypesOptions = useSelector(getScheduleManagementPageModalCreateExercisesTypesOptions)
  const studiosRoomsOptions = useSelector(getScheduleManagementPageModalCreateStudiosRoomsOptions)
  const trainersOptions = useSelector(getScheduleManagementPageModalCreateTrainersOptions)

  const onSaveHandler = useCallback(
    (scheduleFormValues: ScheduleFormValuesDTO) => {
      dispatch(
        scheduleManagementPageModalCreateActions.createSchedule({
          studioId,
          scheduleFormValues,
        })
      )
    },
    [dispatch, studioId]
  )

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  useEffect(() => {
    dispatch(scheduleManagementPageModalCreateActions.fetchDictionaries(studioId))
  }, [dispatch, studioId])

  useEffect(() => {
    return () => {
      dispatch(scheduleManagementPageModalCreateActions.reset())
    }
  }, [dispatch])

  return {
    form,
    isLoading,
    directions,
    directionsOptions,
    exercisesTypesOptions,
    studiosRoomsOptions,
    trainersOptions,
    onCancelHandler,
    onSaveHandler,
  }
}
