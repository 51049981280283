import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { Form } from 'antd'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { genScheduleLongtermPagePeekAboutTrainersOptions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPageModalAddActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-modal-add/schedule-longterm-page-modal-add.slice'
import { genScheduleLongtermPageListStudiosRoomsOptions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.selectors'
import { getScheduleLongtermPageModalAddIsLoading } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-modal-add/schedule-longterm-page-modal-add.selectors'

export function useScheduleLongtermPageModalAdd(scheduleId: string, studioId: string) {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const isLoading = useSelector(getScheduleLongtermPageModalAddIsLoading)
  const trainersOptions = useSelector(genScheduleLongtermPagePeekAboutTrainersOptions)
  const roomsOptions = useSelector(genScheduleLongtermPageListStudiosRoomsOptions)

  const onSaveHandler = useCallback(() => {
    const scheduleFormValues = form.getFieldsValue()
    dispatch(scheduleLongtermPageModalAddActions.addExercises({ studioId, scheduleId, scheduleFormValues }))
  }, [dispatch, form, scheduleId, studioId])

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(scheduleLongtermPageModalAddActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    form,
    trainersOptions,
    roomsOptions,
    onCancelHandler,
    onSaveHandler,
  }
}
