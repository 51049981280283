import { Form, FormListFieldData, FormListOperation, DatePicker, Flex, Typography } from 'antd'
import clsx from 'clsx'

import { genDefaultDateFormat } from '../../../format/date.format'
import { Days } from '../../../types/days.types'
import { getStrEnumValues } from '../../../utils/enum.utils'
import { DaysDropdown } from '../../controls/days-dropdown/days-dropdown.component'
import { DaysSelectBar } from '../../controls/days-select-bar/days-select-bar.component'
import { ScheduleSlotsAdd } from './schedule-slots-add/schedule-slots-add.component'
import { ScheduleSlotsItems } from './schedule-slots-items/schedule-slots-items.component'
import { ScheduleSlotsProps } from './schedule-slots.types'
import { useScheduleSlots } from './schedule-slots.hook'
import './schedule-slots.styles.less'

export const ScheduleSlots: React.FC<ScheduleSlotsProps> = props => {
  const { isLoading, isDisabled = false, form } = props
  const { trainersOptions, studiosRoomsOptions } = props

  const { timeRangePlaceholder, currentDay, dateRange, changeCurrentDay, handleDateChange, onCopyHandler } =
    useScheduleSlots({ form })

  return (
    <Flex vertical gap={16}>
      <Typography.Text className="schedule-slots__title" strong>
        Расписание записей
      </Typography.Text>

      <div className="schedule-slots__wrapper">
        <Flex vertical gap={16}>
          <Form.Item>
            <DaysSelectBar disabled={isDisabled} value={currentDay} onChange={changeCurrentDay} />
          </Form.Item>
          <div className="schedule-slots__days-dropdown">
            <DaysDropdown disabled={isDisabled} label="Скопировать расписание из" onClick={onCopyHandler} />
          </div>

          {getStrEnumValues<Days>(Days).map(day => (
            <Flex vertical gap={16} key={day} className={clsx({ 'schedule-slots_hidden': day !== currentDay })}>
              <Form.List name={['slots', day]}>
                {(fields: FormListFieldData[], { add, remove }: FormListOperation, { errors }) => (
                  <>
                    <ScheduleSlotsItems
                      day={day}
                      form={form}
                      fields={fields}
                      disabled={isDisabled}
                      loading={isLoading}
                      trainersOptions={trainersOptions}
                      studiosRoomsOptions={studiosRoomsOptions}
                      onRemove={remove}
                    />
                    <ScheduleSlotsAdd disabled={isDisabled} onAdd={add} />
                    <Form.ErrorList errors={errors} />
                  </>
                )}
              </Form.List>
            </Flex>
          ))}

          <Form.Item
            required
            className="schedule-slots__time"
            label="Период расписания"
            name="date"
            rules={[
              {
                validator: (_, value) => {
                  if (!value[0] && !value[1]) return Promise.reject('Выберите дату начала и окончания')
                  // if (value[0].isSame(value[1])) return Promise.reject('Время начала не может быть равно времени окончания')

                  return Promise.resolve()
                },
              },
            ]}
          >
            <DatePicker.RangePicker
              disabled={isDisabled}
              value={dateRange}
              onChange={handleDateChange}
              placeholder={timeRangePlaceholder}
              format={genDefaultDateFormat()}
            />
          </Form.Item>
        </Flex>
      </div>
    </Flex>
  )
}
