import { ScheduleModalCreate } from '../../../components/schedule/schedule-modal-create/schedule-modal-create.component'
import { useScheduleLongtermPageModalCreate } from './schedule-longterm-page-modal-create.hook'
import { ScheduleLongtermPageModalCreateProps } from './schedule-longterm-page-modal-create.types'

export const ScheduleLongtermPageModalCreate: React.FC<ScheduleLongtermPageModalCreateProps> = props => {
  const { studioId, studioOffset } = props

  const {
    form,
    isLoading,
    directions,
    directionsOptions,
    exercisesTypesOptions,
    studiosRoomsOptions,
    trainersOptions,
    onCancelHandler,
    onSaveHandler,
  } = useScheduleLongtermPageModalCreate(studioId)

  return (
    <ScheduleModalCreate
      type="create"
      title="Создание персонального длинного расписания"
      longterm
      form={form}
      loading={isLoading}
      studioOffset={studioOffset || 0}
      directions={directions}
      directionsOptions={directionsOptions}
      exercisesTypesOptions={exercisesTypesOptions}
      studiosRoomsOptions={studiosRoomsOptions}
      trainersOptions={trainersOptions}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
    />
  )
}
