import { Flex, Typography, Space, Divider, Switch, Form, Select } from 'antd'
import clsx from 'clsx'

import { PositionsFormPermissionsGroupProps } from './positions-form-permissions-group.types'
import './positions-form-permissions-group.styles.less'
import { CustomCard } from '../../../../custom-card/custom-card.component'
import { isDefAndNotEmpty } from '../../../../../types/lang.types'
import { usePositionsFormPermissionsGroup } from './positions-form-permissions-group.hook'

export const PositionsFormPermissionsGroup: React.FC<PositionsFormPermissionsGroupProps> = props => {
  const { permissionsForm, groupItem, isLast } = props
  const { positionsOptions, studiosOptions, clientsCategoriesOptions } = props
  const { handlePermissionChange } = props

  const { theme, switchStates, selectOptions, handleSwitchChange } = usePositionsFormPermissionsGroup({
    permissionsForm,
    groupItem,
    positionsOptions,
    studiosOptions,
    clientsCategoriesOptions,
    handlePermissionChange,
  })

  return (
    <Flex
      vertical
      gap="middle"
      className={clsx('positions-form-permissions-group', {
        'positions-form-permissions-group--dark': theme === 'dark',
      })}
    >
      <Typography.Title level={5} className="positions-form-permissions-group__subtitle">
        {groupItem.displayName}
      </Typography.Title>
      <Flex vertical gap={10}>
        {!groupItem.granular &&
          groupItem.permissions.map((permission, index) => (
            <Space key={index} size="small" align="center">
              <Form.Item name={[groupItem.name, permission.action]}>
                <Switch
                  size="small"
                  onChange={checked => handlePermissionChange(groupItem.name, permission.action, checked)}
                />
              </Form.Item>
              <Typography.Text>{permission.actionDisplayName}</Typography.Text>
            </Space>
          ))}
        {groupItem.granular &&
          groupItem.actions.map((action, index) => (
            <Space key={index} size="small" align="baseline">
              <Form.Item valuePropName="checked">
                <Switch
                  size="small"
                  checked={switchStates[action.name] || false}
                  onChange={checked => handleSwitchChange(action.name, checked)}
                />
              </Form.Item>
              <Flex vertical gap="small">
                <Typography.Text>{action.displayName}</Typography.Text>

                <CustomCard
                  customClassName={clsx('positions-form-permissions-group__card', {
                    'positions-form-permissions-group__card--hidden': !switchStates[action.name],
                  })}
                >
                  <Form.Item name={[groupItem.name, action.name]}>
                    <Select
                      style={{ width: '100%' }}
                      options={selectOptions}
                      placeholder="Выберите"
                      mode="multiple"
                      onDeselect={value => handlePermissionChange(groupItem.name, action.name, false, [value])}
                      onSelect={value => handlePermissionChange(groupItem.name, action.name, true, [value])}
                      onChange={value => handleSwitchChange(action.name, isDefAndNotEmpty(value))}
                    />
                  </Form.Item>
                </CustomCard>
              </Flex>
            </Space>
          ))}
      </Flex>
      {!isLast && <Divider className="positions-form-permissions-group__divider" />}
    </Flex>
  )
}
