import { ScheduleModalProgress } from '../../../components/schedule/schedule-modal-progress/schedule-modal-progress.component'
import { useScheduleManagementPageModalProgress } from './schedule-management-page-modal-progress.hook'
import { ScheduleManagementPageModalProgressProps } from './schedule-management-page-modal-progress.types'

export const ScheduleManagementPageModalProgress: React.FC<ScheduleManagementPageModalProgressProps> = props => {
  const { action, totalExercises } = props

  const { messages, studioOffset, onCancelHandler, onConflictResolutionHandler } =
    useScheduleManagementPageModalProgress(props)

  return (
    <ScheduleModalProgress
      title={action === 'CREATE' ? 'Создание расписания' : 'Обновление расписания'}
      type={action === 'CREATE' ? 'create' : 'edit'}
      totalExercises={totalExercises}
      messages={messages}
      studioOffset={studioOffset}
      onConflictResolution={onConflictResolutionHandler}
      onCancel={onCancelHandler}
    />
  )
}
