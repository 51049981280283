import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { PositionsApi } from '../../../api/types/positions-api.types'
import {
  PositionsEditPageCopyPermissionsPayload,
  PositionsEditPageCreateGradePayload,
  PositionsEditPageFetchPageDataSuccessPayload,
  PositionsEditPageRemoveGradePayload,
  PositionsEditPageUpdateGradePayload,
  PositionsEditPageUpdatePermissionPayload,
  PositionsEditPageUpdatePositionPayload,
} from './positions-edit-page.types'
import { PermissionsApi } from '../../../api/types/permissions.types'
import { Pagination } from '../../../api/types/api.types'
import { StudiosApi } from '../../../api/types/studios-api.types'
import { ClientsCategoriesApi } from '../../../api/types/clients-categories-api.types'

export interface PositionsEditPageState {
  position: Nullable<PositionsApi.Position>
  permissions: Nullable<PermissionsApi.Permissions[]>
  positions: Nullable<Pagination<PositionsApi.Position>>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  clientsCategories: Nullable<Pagination<ClientsCategoriesApi.ClientCategory>>
  isPermissionsLoading: boolean
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: PositionsEditPageState = {
  position: null,
  permissions: null,
  positions: null,
  studios: null,
  clientsCategories: null,
  isLoading: false,
  isPermissionsLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: positionsEditPageActions, reducer: positionsEditPageReducer } = createSlice({
  name: '@@positions-edit-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<PositionsEditPageState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<PositionsEditPageState>,
      action: PayloadAction<PositionsEditPageFetchPageDataSuccessPayload>
    ) => {
      state.position = action.payload.position
      state.permissions = action.payload.permissions
      state.positions = action.payload.positions
      state.studios = action.payload.studios
      state.clientsCategories = action.payload.clientsCategories
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updatePosition: (
      state: Draft<PositionsEditPageState>,
      _: PayloadAction<PositionsEditPageUpdatePositionPayload>
    ) => {
      state.isLoading = true
    },
    updatePositionSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isLoading = false
    },
    updatePositionError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    updateGrade: (state: Draft<PositionsEditPageState>, _: PayloadAction<PositionsEditPageUpdateGradePayload>) => {
      state.isLoading = true
    },
    updateGradeSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isLoading = false
    },
    updateGradeError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    createGrade: (state: Draft<PositionsEditPageState>, _: PayloadAction<PositionsEditPageCreateGradePayload>) => {
      state.isLoading = true
    },
    createGradeSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isLoading = false
    },
    createGradeError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    removeGrade: (state: Draft<PositionsEditPageState>, _: PayloadAction<PositionsEditPageRemoveGradePayload>) => {
      state.isLoading = true
    },
    removeGradeSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isLoading = false
    },
    removeGradeError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    updatePermission: (
      state: Draft<PositionsEditPageState>,
      _action: PayloadAction<PositionsEditPageUpdatePermissionPayload>
    ) => {
      state.isPermissionsLoading = true
    },
    updatePermissionSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isPermissionsLoading = false
    },
    updatePermissionError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.isPermissionsLoading = false
      state.error = action.payload
    },
    fetchPermissions: (state: Draft<PositionsEditPageState>, _action: PayloadAction<string>) => {
      state.isPermissionsLoading = true
    },
    fetchPermissionsSuccess: (
      state: Draft<PositionsEditPageState>,
      action: PayloadAction<Nullable<PermissionsApi.Permissions[]>>
    ) => {
      state.permissions = action.payload
      state.isPermissionsLoading = false
    },
    fetchPermissionsError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.isPermissionsLoading = false
      state.error = action.payload
    },
    copyPermissions: (
      state: Draft<PositionsEditPageState>,
      _action: PayloadAction<PositionsEditPageCopyPermissionsPayload>
    ) => {
      state.isPermissionsLoading = true
    },
    copyPermissionsSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isPermissionsLoading = false
    },
    copyPermissionsError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.isPermissionsLoading = false
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
