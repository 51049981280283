import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { TrainersApi } from '../../../../api/types/trainers-api.types'
import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleManagementPageModalConflictsCreateExercisePayload,
  ScheduleManagementPageModalConflictsEditExercisePayload,
} from './schedule-management-page-modal-conflicts.types'

export interface ScheduleManagementPageModalConflictsState {
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleManagementPageModalConflictsState = {
  trainers: null,
  isLoading: false,
  error: null,
}

export const {
  actions: scheduleManagementPageModalConflictsActions,
  reducer: scheduleManagementPageModalConflictsReducer,
} = createSlice({
  name: '@@schedule-management-page-modal-conflicts',
  initialState,
  reducers: {
    fetchModalData: (state: Draft<ScheduleManagementPageModalConflictsState>): void => {
      state.isLoading = true
    },
    fetchModalDataSuccess: (
      state: Draft<ScheduleManagementPageModalConflictsState>,
      action: PayloadAction<Nullable<Pagination<TrainersApi.Trainer>>>
    ): void => {
      state.trainers = action.payload
      state.isLoading = false
    },
    fetchModalDataError: (
      state: Draft<ScheduleManagementPageModalConflictsState>,
      action: PayloadAction<Error>
    ): void => {
      state.error = action.payload
      state.isLoading = false
    },
    createExercise: (
      state: Draft<ScheduleManagementPageModalConflictsState>,
      _: PayloadAction<ScheduleManagementPageModalConflictsCreateExercisePayload>
    ) => {
      state.isLoading = true
    },
    createExerciseSuccess: (state: Draft<ScheduleManagementPageModalConflictsState>) => {
      state.isLoading = false
    },
    createExerciseError: (state: Draft<ScheduleManagementPageModalConflictsState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    editExercise: (
      state: Draft<ScheduleManagementPageModalConflictsState>,
      _: PayloadAction<ScheduleManagementPageModalConflictsEditExercisePayload>
    ) => {
      state.isLoading = true
    },
    editExerciseSuccess: (state: Draft<ScheduleManagementPageModalConflictsState>) => {
      state.isLoading = false
    },
    editExerciseError: (state: Draft<ScheduleManagementPageModalConflictsState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
