import { DEFAULT_EMPTY_SYMBOL } from '@format/text.format'
import { Typography } from 'antd'
import dayjs from 'dayjs'

import { isDef, NString } from '../../../../types/lang.types'
import './schedule-table-period.styles.less'

interface Props {
  date: NString
}

export const ScheduleTablePeriod = (props: Props) => {
  const { date } = props
  if (isDef(date)) {
    return (
      <Typography.Paragraph className="schedule-table-period" ellipsis>
        {dayjs(date).format('D MMMM')}
      </Typography.Paragraph>
    )
  }

  return <Typography.Text>{DEFAULT_EMPTY_SYMBOL}</Typography.Text>
}
