import { ScheduleModalConflicts } from '../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.component'
import { useScheduleLongtermPageModalConflicts } from './schedule-longterm-page-modal-conflicts.hook'
import { ScheduleLongtermPageModalConflictsProps } from './schedule-longterm-page-modal-conflicts.types'

export const ScheduleLongtermPageModalConflicts: React.FC<ScheduleLongtermPageModalConflictsProps> = props => {
  const { action, studioId, studioOffset } = props

  const { isLoading, conflicts, studiosRoomsOptions, trainersOptions, onSaveHandler, onSkipHandler, onCancelHandler } =
    useScheduleLongtermPageModalConflicts(props)

  return (
    <ScheduleModalConflicts
      longterm
      studioId={studioId}
      action={action}
      isLoading={isLoading}
      conflicts={conflicts}
      trainersOptions={trainersOptions}
      studiosRoomsOptions={studiosRoomsOptions}
      studioOffset={studioOffset}
      onCancel={onCancelHandler}
      onSkip={onSkipHandler}
      onSave={onSaveHandler}
    />
  )
}
