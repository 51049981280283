export type InternalPath = string

export type RoutePathParams = { [paramName: string]: string | number | boolean | undefined }

export enum AppPath {
  HOME = '/',
  NOT_FOUND = '*',

  SCHEDULE = '/schedule/:studioId',
  SCHEDULE_GROUP = '/schedule/:studioId/:scheduleId/:section?/:date?',

  SCHEDULE_MANAGEMENT = '/schedule/:studioId/management',
  SCHEDULE_MANAGEMENT_EDIT = '/schedule/:studioId/management/:scheduleId',

  SCHEDULE_LONGTERM = '/schedule/:studioId/longterm',
  SCHEDULE_LONGTERM_EDIT = '/schedule/:studioId/longterm/:scheduleId',

  DISCOUNTS = '/discounts',
  TRANSACTIONS = '/transactions/:studioId',
  TRANSACTIONS_CREATE = '/transactions/:studioId/create',

  PRODUCTS = '/products/:section?',
  PRODUCTS_CREATE_SUBSCRIPTION = '/products/create/subscription/:type',
  PRODUCTS_EDIT_SUBSCRIPTION = '/products/edit/subscription/:type/:id',
  PRODUCTS_CREATE_SERVICE = '/products/create/service',
  PRODUCTS_EDIT_SERVICE = '/products/edit/service/:id',

  STUDIOS = '/studios',
  STUDIOS_CREATE = '/studios/create',
  STUDIOS_EDIT = '/studios/:id',

  SALARIES = '/salaries',

  SETTINGS = '/settings',

  FRANCHISES = '/franchises',
  FRANCHISES_CREATE = '/franchises/create',
  FRANCHISES_EDIT = '/franchises/:id',

  EMPLOYEES = '/employees',
  EMPLOYEES_SCHEDULE = '/employees/schedule',
  EMPLOYEES_SCHEDULE_CREATE = '/employees/schedule/create',
  EMPLOYEES_SCHEDULE_EDIT = '/employees/schedule/edit/:id',
  EMPLOYEES_CREATE = '/employees/create',
  EMPLOYEES_EDIT = '/employees/:id',

  DIRECTIONS = '/directions',
  DIRECTIONS_CREATE = '/directions/create',
  DIRECTIONS_EDIT = '/directions/:id',

  EXERCISES_TYPES = '/exercises-types',
  EXERCISES_TYPES_CREATE = '/exercises-types/create',
  EXERCISES_TYPES_EDIT = '/exercises-types/:id',

  CLIENTS = '/clients',
  CLIENTS_CREATE = '/clients/create',
  CLIENTS_EDIT = '/clients/:id/:section?',

  MASTER_SERVICES = '/master-services',
  MASTER_SERVICES_CREATE = '/master-services/create',
  MASTER_SERVICES_EDIT = '/master-services/:id',

  MASTER_SERVICES_WIDGET_CREATE = '/master-services-widget/create',

  POSITIONS = '/positions',
  POSITIONS_CREATE = '/positions/create',
  POSITIONS_EDIT = '/positions/:id',

  CUSTOM_FIELDS = '/custom-fields',

  ANALYTICS = '/analytics',

  GOODS = '/goods',
  CONSIGNMENT_CREATE = '/consignment-create',
  CONSIGNMENT_EDIT = '/consignment-edit/:id',

  REPORTS = '/reports',

  CONTRACTS = '/contracts',
  CONTRACTS_INFO = '/contracts/:id',
}
