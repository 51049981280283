import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { ScheduleList } from '../../../components/schedule/schedule-list/schedule-list.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { useScheduleLongtermPageTable } from './schedule-longterm-page-table.hook'

export const ScheduleLongtermPageTable = () => {
  const { isLoading, schedule, onCreateTransactionHandler, onEditHandler, onRemoveHandler, onHistoryHandler } =
    useScheduleLongtermPageTable()

  if (isLoading) {
    return <PageLoader />
  }
  if (isDefAndNotEmpty(schedule)) {
    return (
      <ScheduleList
        schedule={schedule}
        onCreateTransaction={onCreateTransactionHandler}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        onHistory={onHistoryHandler}
      />
    )
  }
  return <PageEmpty description="Нет расписания" />
}
