import { all, spawn } from 'redux-saga/effects'

import { scheduleManagementPageModalCreateSagas } from './schedule-management-page-modal-create/schedule-management-page-modal-create.sagas'
import { scheduleManagementPageModalEditSagas } from './schedule-management-page-modal-edit/schedule-management-page-modal-edit.sagas'
import { scheduleManagementPageTableSagas } from './schedule-management-page-table/schedule-management-page-table.sagas'
import { scheduleManagementPagePeekHistorySagas } from './schedule-management-page-peek-history/schedule-management-page-peek-history.sagas'
import { scheduleManagementPagePeekAboutSagas } from './schedule-management-page-peek-about/schedule-management-page-peek-about.sagas'
import { scheduleManagementPageModalAddSagas } from './schedule-management-page-modal-add/schedule-management-page-modal-add.sagas'
import { scheduleManagementPageModalConflictsSagas } from './schedule-management-page-modal-conflicts/schedule-management-page-modal-conflicts.sagas'

export function* scheduleManagementPageSagas() {
  yield all(
    [
      scheduleManagementPageModalCreateSagas,
      scheduleManagementPageModalEditSagas,
      scheduleManagementPageTableSagas,
      scheduleManagementPagePeekHistorySagas,
      scheduleManagementPagePeekAboutSagas,
      scheduleManagementPageModalAddSagas,
      scheduleManagementPageModalConflictsSagas,
    ].map(saga => spawn(saga))
  )
}
