import { createSelector } from '@reduxjs/toolkit'

import { EmployeesApi } from '../../../api/types/employees-api.types'
import { ContractsApi } from '../../../api/types/contracts-api.types'
import { mapCounterpartiesToCardList, mapManagersToDropdown } from '../../../mapping/contracts.mapping'
import { Pagination } from '../../../api/types/api.types'
import { Nullable } from '../../../types/lang.types'
import { AppState } from '../../app.store'

const getCounterpartiesInternal = (state: AppState): Nullable<Pagination<ContractsApi.Counterparties>> =>
  state.contractsPage.counterparties

const getManagersInternal = (state: AppState): Nullable<Pagination<EmployeesApi.Employee>> =>
  state.contractsPage.managers

export const getCounterpartiesIsLoading = (state: AppState): boolean => state.contractsPage.isLoading

export const getCounterpartiesIsCreating = (state: AppState): boolean => state.contractsPage.isCreating

export const getCounterpartiesCardsList = createSelector(getCounterpartiesInternal, counterparties =>
  mapCounterpartiesToCardList(counterparties?.content)
)

export const getManagersCardsList = createSelector(getManagersInternal, managers =>
  mapManagersToDropdown(managers?.content)
)
