import { Select } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'

import { useTheme } from '../../hooks/use-theme.hook'
import { CustomSelectProps } from './custom-select.types'
import './custom-select.styles.less'

export const CustomSelect: FC<CustomSelectProps> = props => {
  const { options, placeholder, mode, value, customClassName, disabled, size = 'middle', maxCount } = props
  const { onChange } = props

  const { theme } = useTheme()

  return (
    <Select
      className={clsx('custom-select', customClassName, {
        'custom-select--dark': theme === 'dark',
      })}
      style={{ '--custom-select-placeholder': `'${placeholder}'` } as React.CSSProperties}
      maxCount={maxCount}
      data-placeholder
      allowClear
      suffixIcon={null}
      showSearch={false}
      options={options}
      value={value}
      mode={mode}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  )
}
