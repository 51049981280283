import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import {
  WebsocketTimetableMessage,
  WebsocketTimetableUpdateExerciseStatusPayload,
  WebsocketTimetableUpdateMessagePayload,
} from './websocket-timetable.types'

interface WebsocketTimetableState {
  isConnecting: boolean
  isConnected: boolean
  messages: WebsocketTimetableMessage[]
  error: Nullable<Error>
}

const initialState: WebsocketTimetableState = {
  isConnecting: false,
  isConnected: false,
  messages: [],
  error: null,
}

export const { actions: websocketTimetableActions, reducer: websocketTimetableReducer } = createSlice({
  name: '@@websocket-timetable',
  initialState,
  reducers: {
    connect: (state, _action: PayloadAction<string>) => {
      state.isConnecting = true
      state.error = null
    },
    connectionSuccess: state => {
      state.isConnecting = false
      state.isConnected = true
    },
    connectionError: (state, action: PayloadAction<Error>) => {
      state.isConnecting = false
      state.isConnected = false
      state.error = action.payload
    },
    receiveMessage: (state, action: PayloadAction<WebsocketTimetableMessage>) => {
      state.messages = [...state.messages, action.payload]
    },
    addMessage: (state, action: PayloadAction<WebsocketTimetableMessage>) => {
      state.messages = [...state.messages, action.payload]
    },
    updateExerciseStatus: (state, action: PayloadAction<WebsocketTimetableUpdateExerciseStatusPayload>) => {
      state.messages = state.messages.map(message => {
        if (message.exercise.id === action.payload.exerciseId && message.action === action.payload.action) {
          return {
            ...message,
            exercise: {
              ...message.exercise,
              isCanceled: true,
            },
          }
        }
        return message
      })
    },
    updateMessage: (state, action: PayloadAction<WebsocketTimetableUpdateMessagePayload>) => {
      state.messages = state.messages.map(message =>
        message.exercise.id === action.payload.id
          ? {
              ...message,
              error: null,
              conflicts: null,
              exercise: { ...message.exercise, trainers: action.payload.trainers, roomId: action.payload.roomId },
            }
          : message
      )
    },
    resetMessages: state => {
      state.messages = []
    },
    disconnect: state => {
      state.isConnecting = false
      state.isConnected = false
    },
  },
})
