import { FilterOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Dropdown, Flex, TimePicker, Typography } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import clsx from 'clsx'
import timezone from 'dayjs/plugin/timezone'
import { FC, useMemo } from 'react'

import { isDefAndNotEmpty } from '../../../types/lang.types'
import { CustomSelect } from '../../custom-select/custom-select.component'
import { ScheduleExercisesFiltersProps } from './schedule-exercises-filters.types'
import { useScheduleExercisesFilters } from './schedule-exercises-filters.hook'
import { genDaysOptions } from '../../../utils/days.utils'
import { genScheduleExercisesFiltersMenuItems } from './schedule-exercises-filters.utils'
import { sortOptionsByLocale } from '../../../utils/options.utils'
import './schedule-exercises-filters.styles.less'

dayjs.extend(utc)
dayjs.extend(timezone)

export const ScheduleExercisesFilters: FC<ScheduleExercisesFiltersProps> = props => {
  const {
    isExercisesLoading,
    studioId,
    exercisesFilter,
    totalExercises,
    dateFrom,
    dateTo,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    longterm = false,
    onSelectFilter,
    onResetFilter,
    onEditExercises,
    onAddExercises,
  } = props

  const {
    theme,
    timeFilter,
    dateFilter,
    paymentStatusOptions,
    paymentTypeOptions,
    timeScopeOptions,
    bookingStatusOptions,
    isTimeFilterOpen,
    isDateFilterOpen,
    hasActiveFilters,
    hasOpenTimeFilters,
    disabledDate,
    handleTrainersChange,
    handleStudioChange,
    handleTimeChange,
    handleDatesChange,
    handleResetFilter,
    toggleTimeFilter,
    toggleDateFilter,
  } = useScheduleExercisesFilters({
    dateFrom,
    dateTo,
    exercisesFilter,
    onSelectFilter,
    onResetFilter,
  })

  const items = useMemo(
    () =>
      genScheduleExercisesFiltersMenuItems(
        {
          trainersOptions,
          roomsOptions,
          paymentStatusOptions,
          paymentTypeOptions,
          timeScopeOptions,
          bookingStatusOptions,
        },
        isTimeFilterOpen,
        isDateFilterOpen,
        exercisesFilter,
        longterm,
        toggleTimeFilter,
        toggleDateFilter,
        handleTrainersChange,
        onSelectFilter
      ),
    [
      trainersOptions,
      roomsOptions,
      paymentStatusOptions,
      paymentTypeOptions,
      timeScopeOptions,
      bookingStatusOptions,
      isTimeFilterOpen,
      isDateFilterOpen,
      exercisesFilter,
      longterm,
      toggleTimeFilter,
      toggleDateFilter,
      handleTrainersChange,
      onSelectFilter,
    ]
  )

  return (
    <div
      className={clsx('schedule-exercises-filters', {
        'schedule-exercises-filters--dark': theme === 'dark',
      })}
    >
      <Flex justify="space-between" align="center">
        <Flex gap={16}>
          <Typography.Title level={4} className="schedule-exercises-filters__title">
            Записи
          </Typography.Title>
          <Dropdown className="chedule-exercises-filters__button--filters" menu={{ items }}>
            <Button icon={<FilterOutlined />} loading={isExercisesLoading} disabled={isExercisesLoading}>
              Фильтры
            </Button>
          </Dropdown>
        </Flex>
        <Flex gap={16}>
          <Button
            icon={<FilterOutlined />}
            loading={isExercisesLoading}
            disabled={isExercisesLoading}
            onClick={onAddExercises}
          >
            Добавить запись
          </Button>
          <Button
            type="primary"
            loading={isExercisesLoading}
            onClick={onEditExercises}
            disabled={isExercisesLoading || !hasActiveFilters || (totalExercises || 0) === 0}
          >
            Действия с {totalExercises} {totalExercises === 1 ? 'записью' : 'записями'}
          </Button>
        </Flex>
      </Flex>
      {(hasActiveFilters || isTimeFilterOpen || isDateFilterOpen) && (
        <Card className="schedule-exercises-filters__card">
          <Flex wrap="wrap" gap="small">
            {isDefAndNotEmpty(trainersOptions) && isDefAndNotEmpty(exercisesFilter['trainerIds']) && (
              <CustomSelect
                options={[{ label: 'Без исполнителя', value: 'noTrainer' }, ...trainersOptions]}
                mode="multiple"
                placeholder="Исполнители"
                value={exercisesFilter.trainerIds}
                disabled={isExercisesLoading}
                onChange={handleTrainersChange}
              />
            )}
            {/* {isDefAndNotEmpty(studiosOptions) && (
              <CustomSelect
                options={studiosOptions}
                value={exercisesFilter.studioId}
                mode="multiple"
                placeholder="Студия"
                maxCount={1}
                onChange={handlStudioChange}
              />
            )} */}
            {isDefAndNotEmpty(roomsOptions) && isDefAndNotEmpty(exercisesFilter['roomId']) && (
              <CustomSelect
                options={sortOptionsByLocale(roomsOptions)}
                value={exercisesFilter.roomId}
                mode="multiple"
                placeholder="Зал"
                maxCount={1}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('roomId', value)}
              />
            )}
            {isDefAndNotEmpty(exercisesFilter['dayOfWeek']) && (
              <CustomSelect
                options={genDaysOptions(true)}
                placeholder="День недели"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.dayOfWeek}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('dayOfWeek', value)}
              />
            )}
            {/* {isTimeFilterOpen['timeFrom'] && (
              <TimePicker
                className={clsx('schedule-exercises-filters__time-picker', {
                  'schedule-exercises-filters__time-picker--has-value': isDef(exercisesFilter['timeFrom']),
                })}
                style={{ '--custom-select-placeholder': `'Время начала'` } as React.CSSProperties}
                onChange={date => handleTimeChange(date, 'timeFrom')}
                allowClear={{
                  clearIcon: <CloseOutlined style={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.45)' }} />,
                }}
                value={timeFilter.timeFrom}
                format="HH:mm"
                placeholder="--:--"
                disabled={isExercisesLoading}
              />
            )}
            {isTimeFilterOpen['timeTo'] && (
              <TimePicker
                className={clsx('schedule-exercises-filters__time-picker', {
                  'schedule-exercises-filters__time-picker--has-value': isDef(exercisesFilter['timeTo']),
                })}
                style={{ '--custom-select-placeholder': `'Время окончания'` } as React.CSSProperties}
                allowClear={{
                  clearIcon: <CloseOutlined style={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.45)' }} />,
                }}
                value={timeFilter.timeTo}
                onChange={date => handleTimeChange(date, 'timeTo')}
                format="HH:mm"
                placeholder="--:--"
                disabled={isExercisesLoading}
              />
            )} */}
            {isTimeFilterOpen && (
              <TimePicker.RangePicker
                className={clsx(
                  'schedule-exercises-filters__range-picker',
                  'schedule-exercises-filters__range-picker--time',
                  {
                    'schedule-exercises-filters__range-picker--has-value': timeFilter,
                  }
                )}
                style={{ '--custom-select-placeholder': `'Время'` } as React.CSSProperties}
                placeholder={['--:--', '--:--']}
                format="HH-mm"
                disabled={isExercisesLoading}
                value={timeFilter}
                onChange={dates => handleTimeChange(dates)}
              />
            )}
            {isDateFilterOpen && (
              <DatePicker.RangePicker
                className={clsx('schedule-exercises-filters__range-picker', {
                  'schedule-exercises-filters__range-picker--has-value': dateFilter,
                })}
                style={{ '--custom-select-placeholder': `'Даты'` } as React.CSSProperties}
                format="DD-MMMM"
                disabled={isExercisesLoading}
                disabledDate={disabledDate}
                value={dateFilter}
                onChange={dates => handleDatesChange(dates)}
              />
            )}
            {isDefAndNotEmpty(exercisesFilter['bookingPaymentStatus']) && (
              <CustomSelect
                options={paymentStatusOptions}
                placeholder="Статус оплаты"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.bookingPaymentStatus}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('bookingPaymentStatus', value)}
              />
            )}
            {isDefAndNotEmpty(exercisesFilter['paymentType']) && (
              <CustomSelect
                options={paymentTypeOptions}
                placeholder="Метод оплаты"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.paymentType}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('paymentType', value)}
              />
            )}
            {isDefAndNotEmpty(exercisesFilter['timeScope']) && (
              <CustomSelect
                options={timeScopeOptions}
                placeholder="Статус записи"
                mode="multiple"
                maxCount={1}
                value={exercisesFilter.timeScope}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('timeScope', value)}
              />
            )}
            {isDefAndNotEmpty(exercisesFilter['bookingStatuses']) && (
              <CustomSelect
                options={bookingStatusOptions}
                placeholder="Статус бронирования"
                mode="multiple"
                value={exercisesFilter.bookingStatuses}
                disabled={isExercisesLoading}
                onChange={value => onSelectFilter('bookingStatuses', value)}
              />
            )}
            <Button
              type="text"
              className="schedule-exercises-filters__button"
              disabled={isExercisesLoading}
              onClick={handleResetFilter}
            >
              Сбросить
            </Button>
          </Flex>
        </Card>
      )}
    </div>
  )
}
