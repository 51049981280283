import qs from 'qs'

import { isDef, isString, NString } from '../../types/lang.types'
import {
  ScheduleManagementPageParams,
  ScheduleManagementPageQueryParams,
  ScheduleManagementPageUrlParams,
} from './schedule-management-page.types'

export function genScheduleManagementPageParams(
  params: ScheduleManagementPageUrlParams,
  search: NString
): ScheduleManagementPageParams {
  return {
    ...genScheduleLongtermPageUrlParams(params),
    ...genSchedulePageQueryParams(search),
  }
}

export function genScheduleLongtermPageUrlParams(params: ScheduleManagementPageUrlParams) {
  const { studioId, scheduleId } = params

  return { studioId, scheduleId }
}

function genSchedulePageQueryParams(search: NString): ScheduleManagementPageQueryParams {
  const queryParams = isDef(search) ? qs.parse(search, { ignoreQueryPrefix: true }) : null

  const roomId = isString(queryParams?.roomId) ? queryParams?.roomId : null

  return {
    roomId,
  }
}
