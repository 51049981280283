import { PositionsForm } from '../../../components/positions/positions-form/positions-form.component'
import { usePositionsEditPageForm } from './positions-edit-page-form.hook'

export const PositionsEditPageForm = () => {
  const {
    form,
    permissionsForm,
    isLoading,
    isPermissionsLoading,
    positionGrades,
    positionPermissions,
    positionsOptions,
    studiosOptions,
    clientsCategoriesOptions,
    positionGradesOptions,
    positionId,
    sourcePositionId,
    onUpdateGradeHandler,
    onCreateGradeHandler,
    onRemoveGradeHandler,
    onUpdatePermissionHandler,
    onCopyPermissionsHandler,
    onChangePermissionsPositionHandler,
    onFinishHandler,
  } = usePositionsEditPageForm()

  return (
    <PositionsForm
      form={form}
      permissionsForm={permissionsForm}
      type="edit"
      submitText="Редактировать должность"
      isLoading={isLoading}
      isPermissionsLoading={isPermissionsLoading}
      positionGrades={positionGrades}
      positionPermissions={positionPermissions}
      positionsOptions={positionsOptions}
      studiosOptions={studiosOptions}
      clientsCategoriesOptions={clientsCategoriesOptions}
      positionGradesOptions={positionGradesOptions}
      positionId={positionId}
      sourcePositionId={sourcePositionId}
      onUpdateGrade={onUpdateGradeHandler}
      onCreateGrade={onCreateGradeHandler}
      onRemoveGrade={onRemoveGradeHandler}
      onUpdatePermission={onUpdatePermissionHandler}
      onChangePermissionsPosition={onChangePermissionsPositionHandler}
      onCopyPermissions={onCopyPermissionsHandler}
      onFinish={onFinishHandler}
    />
  )
}
