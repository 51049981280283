import { DefaultOptionType } from 'antd/es/select'

import { WebsocketTimetableMessageExerciseAction } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.types'
import { AppModalBaseProps } from '../../../types/modal.types'
import {
  ScheduleModalConflictItem,
  ScheduleModalConflictsExerciseValues,
} from './schedule-modal-conflicts-exercise/schedule-modal-conflicts-exercise.types'

export enum ScheduleModalConflictsAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  ADD = 'ADD',
}

export interface ScheduleModalConflictsProps extends AppModalBaseProps {
  longterm?: boolean
  studioId: string
  action: ScheduleModalConflictsAction
  isLoading: boolean
  conflicts?: ScheduleModalConflictItem[]
  studiosRoomsOptions?: DefaultOptionType[]
  trainersOptions?: DefaultOptionType[]
  studioOffset?: number
  onSkip: (exerciseId: string, action: WebsocketTimetableMessageExerciseAction) => void
  onSave: (exerciseId: string, timeFrom: string, timeTo: string, values?: ScheduleModalConflictsExerciseValues) => void
}
