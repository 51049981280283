import * as React from 'react'

import { ScheduleManagementPageModalCreateProps } from './schedule-management-page-modal-create.types'
import { useScheduleManagementPageModalCreate } from './schedule-management-page-modal-create.hook'
import { ScheduleModalCreate } from '../../../components/schedule/schedule-modal-create/schedule-modal-create.component'

export const ScheduleManagementPageModalCreate: React.FC<ScheduleManagementPageModalCreateProps> = props => {
  const { studioId, studioOffset } = props
  const {
    form,
    isLoading,
    directions,
    directionsOptions,
    exercisesTypesOptions,
    studiosRoomsOptions,
    trainersOptions,
    onCancelHandler,
    onSaveHandler,
  } = useScheduleManagementPageModalCreate(studioId)

  return (
    <ScheduleModalCreate
      type="create"
      title="Создание расписания"
      form={form}
      loading={isLoading}
      studioOffset={studioOffset || 0}
      directions={directions}
      directionsOptions={directionsOptions}
      exercisesTypesOptions={exercisesTypesOptions}
      studiosRoomsOptions={studiosRoomsOptions}
      trainersOptions={trainersOptions}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
    />
  )
}
