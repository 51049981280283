import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects'
import * as H from 'history'
import { createMatchSelector, getSearch } from 'connected-react-router'
import { match } from 'react-router-dom'

import { api } from '../../../../api/api'
import { isExercisesTimetableCancelConflict } from '../../../../api/utils/exercises-timetable-api.utils'
import { ScheduleManagementPageUrlParams } from '../../../../pages/schedule-management-page/schedule-management-page.types'
import { genScheduleManagementPageParams } from '../../../../pages/schedule-management-page/schedule-management-page.utils'
import { AppPath } from '../../../../types/path.types'
import { AppModal } from '../../../../types/modal.types'
import { AppState } from '../../../app.store'
import { modalActions } from '../../../common/modal/modal.slice'
import { scheduleManagementPageTableActions } from './schedule-management-page-table.slice'
import { callApi } from '../../../../utils/sagas.utils'

function* fetchPageData(action: ReturnType<typeof scheduleManagementPageTableActions.fetchPageData>) {
  try {
    const { studioId, roomId } = action.payload

    const [exercisesTimetableResponse, studiosRoomsResponse]: [
      Awaited<ReturnType<typeof api.exercisesTimetableService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosRoomsService.fetchAll>>
    ] = yield all([
      callApi(api.exercisesTimetableService.fetchAll, {
        studioId,
        roomId,
        longterm: false,
      }),
      callApi(api.studiosRoomsService.fetchAll, studioId),
    ])

    yield put(
      scheduleManagementPageTableActions.fetchPageDataSuccess({
        exercisesTimetable: exercisesTimetableResponse.data,
        studiosRooms: studiosRoomsResponse.data.content,
      })
    )
  } catch (e) {
    yield put(scheduleManagementPageTableActions.fetchPageDataError())
  }
}

export function* reFetchPageData() {
  const search: H.Search = yield select(getSearch)
  const { params }: match<ScheduleManagementPageUrlParams> = yield select(
    createMatchSelector<AppState, ScheduleManagementPageUrlParams>(AppPath.SCHEDULE_MANAGEMENT)
  )
  const pageParams = genScheduleManagementPageParams(params, search)

  yield put(scheduleManagementPageTableActions.fetchPageData(pageParams))

  yield race([
    take(scheduleManagementPageTableActions.fetchPageDataSuccess.type),
    take(scheduleManagementPageTableActions.fetchPageDataError.type),
  ])
}

function* fetchExercisesTimetable(
  _action: ReturnType<typeof scheduleManagementPageTableActions.fetchExercisesTimetable>
) {
  try {
    const search: H.Search = yield select(getSearch)
    const { params }: match<ScheduleManagementPageUrlParams> = yield select(
      createMatchSelector<AppState, ScheduleManagementPageUrlParams>(AppPath.SCHEDULE_MANAGEMENT)
    )
    const pageParams = genScheduleManagementPageParams(params, search)
    const { studioId, roomId } = pageParams
    const response: Awaited<ReturnType<typeof api.exercisesTimetableService.fetchAll>> = yield callApi(
      api.exercisesTimetableService.fetchAll,
      {
        studioId,
        roomId,
        longterm: false,
      }
    )
    yield put(scheduleManagementPageTableActions.fetchExercisesTimetableSuccess(response?.data))
  } catch (e) {
    yield put(scheduleManagementPageTableActions.fetchExercisesTimetableError())
  }
}

function* cancelSchedule(action: ReturnType<typeof scheduleManagementPageTableActions.cancelSchedule>) {
  try {
    yield callApi(api.exercisesTimetableService.cancel, action.payload)
    yield put(scheduleManagementPageTableActions.cancelScheduleSuccess())
    yield put(modalActions.closeLast())
    yield call(reFetchPageData)
  } catch (e) {
    yield put(scheduleManagementPageTableActions.cancelScheduleError())

    if (isExercisesTimetableCancelConflict(e)) {
      yield put(
        modalActions.replaceAll({
          modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_ERROR,
        })
      )
    }
  }
}

export function* scheduleManagementPageTableSagas() {
  yield takeLatest(scheduleManagementPageTableActions.fetchPageData.type, fetchPageData)
  yield takeLatest(scheduleManagementPageTableActions.fetchExercisesTimetable.type, fetchExercisesTimetable)
  yield takeLatest(scheduleManagementPageTableActions.cancelSchedule.type, cancelSchedule)
}
