import { put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { ScheduleOverview } from '../../../../components/schedule/schedule-overview/schedule-overview.types'
import {
  genExerciseBookingCreateDTO,
  mapConflictsFormValuesToExerciseCreateDTO,
  mapConflictsFormValuesToExerciseUpdateDTO,
} from '../../../../mapping/exercises.mapping'
import { isDef, Nullable } from '../../../../types/lang.types'
import { callApi } from '../../../../utils/sagas.utils'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { WebsocketTimetableMessageExerciseAction } from '../../../common/websocket/websocket-timetable/websocket-timetable.types'
import { genScheduleLongtermPagePeekAboutSchedule } from '../schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPageModalConflictsActions } from './schedule-longterm-page-modal-conflicts.slice'

function* fetchModalData(_action: ReturnType<typeof scheduleLongtermPageModalConflictsActions.fetchModalData>) {
  try {
    const response: Awaited<ReturnType<typeof api.trainersService.fetchAll>> = yield callApi(
      api.trainersService.fetchAll,
      { size: 300 }
    )

    yield put(scheduleLongtermPageModalConflictsActions.fetchModalDataSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(scheduleLongtermPageModalConflictsActions.fetchModalDataError(new Error()))
  }
}

export function* createExercise(action: ReturnType<typeof scheduleLongtermPageModalConflictsActions.createExercise>) {
  try {
    const { scheduleId, exerciseId, values, createdExercises, timeFrom, timeTo, studioOffset } = action.payload
    const { directionId, typeId, timeslots, phone, paymentType } = createdExercises

    if (values && phone && paymentType) {
      const exercisesCreateDTO = mapConflictsFormValuesToExerciseCreateDTO(
        scheduleId,
        values,
        timeFrom,
        directionId,
        typeId,
        timeslots,
        studioOffset
      )

      if (isDef(exercisesCreateDTO)) {
        const response: Awaited<ReturnType<typeof api.exercisesService.create>> = yield callApi(
          api.exercisesService.create,
          exercisesCreateDTO
        )

        const exerciseBookingCreateDTO = genExerciseBookingCreateDTO({
          phone,
          paymentType,
          place: 1,
        })

        if (response && exerciseBookingCreateDTO) {
          const { id, room, trainers, studio } = response.data

          yield callApi(api.exercisesService.createBooking, response.data.id, exerciseBookingCreateDTO)

          yield put(
            websocketTimetableActions.updateMessage({ id: exerciseId, trainers: values.trainers, roomId: room.id })
          )
          yield put(
            websocketTimetableActions.addMessage({
              action: WebsocketTimetableMessageExerciseAction.CREATE_BOOKING,
              exercise: {
                id,
                timeFrom,
                timeTo,
                roomId: room.id,
                trainers,
                timetableId: scheduleId,
                studio,
              },
              conflicts: null,
              error: null,
            })
          )
          yield put(scheduleLongtermPageModalConflictsActions.createExerciseSuccess())
        } else {
          yield put(scheduleLongtermPageModalConflictsActions.createExerciseError(new Error()))
        }
      } else {
        yield put(scheduleLongtermPageModalConflictsActions.createExerciseError(new Error()))
      }
    } else {
      yield put(scheduleLongtermPageModalConflictsActions.createExerciseError(new Error()))
    }
  } catch (e) {
    yield put(scheduleLongtermPageModalConflictsActions.createExerciseError(new Error()))
  }
}

export function* editExercise(action: ReturnType<typeof scheduleLongtermPageModalConflictsActions.editExercise>) {
  try {
    const { exerciseId, studioId, values } = action.payload

    const schedule: Nullable<ScheduleOverview> = yield select(genScheduleLongtermPagePeekAboutSchedule)

    if (values) {
      const exercisesUpdateDTO = mapConflictsFormValuesToExerciseUpdateDTO(values, studioId, schedule?.direction?.id)

      if (isDef(exercisesUpdateDTO)) {
        yield callApi(api.exercisesService.update, exerciseId, exercisesUpdateDTO)
        yield put(
          websocketTimetableActions.updateMessage({ id: exerciseId, trainers: values.trainers, roomId: values.roomId })
        )
        yield put(scheduleLongtermPageModalConflictsActions.editExerciseSuccess())
      } else {
        yield put(scheduleLongtermPageModalConflictsActions.editExerciseError(new Error()))
      }
    } else {
      yield put(scheduleLongtermPageModalConflictsActions.editExerciseError(new Error()))
    }
  } catch (e) {
    yield put(scheduleLongtermPageModalConflictsActions.editExerciseError(new Error()))
  }
}

export function* scheduleLongtermPageModalConflictsSagas() {
  yield takeLatest(scheduleLongtermPageModalConflictsActions.fetchModalData.type, fetchModalData)
  yield takeLatest(scheduleLongtermPageModalConflictsActions.createExercise.type, createExercise)
  yield takeLatest(scheduleLongtermPageModalConflictsActions.editExercise.type, editExercise)
}
