import { ContractsApi } from '../../../api/types/contracts-api.types'
import { formatRublesPenniesCurrency } from '../../../format/number.format'

export const useCalculateContractBalance = (contractAmount: number, contractType: ContractsApi.ContractsType) => {
  const adjustedBalance = contractType === 'POSTPAY' ? -contractAmount : contractAmount
  const formattedBalance = formatRublesPenniesCurrency(adjustedBalance)

  return {
    balance: formattedBalance,
  }
}
