import { createSelector } from '@reduxjs/toolkit'

import { mapTrainersToOptions } from '../../../../mapping/trainers.mapping'
import { AppState } from '../../../app.store'

export const getScheduleManagementPageModalConflictsIsLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModalConflicts.isLoading

const getScheduleManagementPageModalConflictsTrainersInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModalConflicts.trainers

export const getScheduleManagementPageModalConflictsTrainersOptions = createSelector(
  getScheduleManagementPageModalConflictsTrainersInternal,
  trainers => mapTrainersToOptions(trainers?.content)
)
