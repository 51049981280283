import * as React from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useScheduleManagementPageAdd } from './schedule-management-page-add.hook'

export const ScheduleManagementPageAdd: React.FC = () => {
  const { onAddHandler } = useScheduleManagementPageAdd()

  return (
    <div className="schedule-management-page-add">
      <Button type="primary" icon={<PlusOutlined />} onClick={onAddHandler}>
        Добавить расписание
      </Button>
    </div>
  )
}
