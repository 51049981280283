import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'

import './schedule-slots-add.styles.less'

interface Props {
  onAdd: () => void
  disabled: boolean
}

export const ScheduleSlotsAdd = (props: Props) => {
  const { onAdd, disabled } = props

  return (
    <Form.Item>
      <Button
        className="schedule-slots-add"
        disabled={disabled}
        type="dashed"
        block
        icon={<PlusCircleOutlined />}
        onClick={() => onAdd()}
      >
        Ещё
      </Button>
    </Form.Item>
  )
}
