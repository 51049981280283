import { CheckOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { DefaultOptionType } from 'antd/es/select'

import { formatBookingStatus } from '../../../format/text.format'
import { BookingStatus } from '../../../types/bookings.types'
import { isDefAndNotEmpty, Nullable } from '../../../types/lang.types'
import { genDaysOptions } from '../../../utils/days.utils'
import { getStrEnumValues } from '../../../utils/enum.utils'
import { sortOptionsByLocale } from '../../../utils/options.utils'
import { ScheduleExercisesFilter } from './schedule-exercises-filters.types'

export function genScheduleExercisesFiltersPaymentStatusOptions() {
  return [
    { label: 'Оплачено', value: 'PAID' },
    { label: 'Не оплачено', value: 'UNPAID' },
  ]
}

export function genScheduleExercisesFiltersTimeScopeOptions() {
  return [
    { label: 'Запланированные', value: 'FUTURE' },
    { label: 'Прошедшие', value: 'PAST' },
  ]
}

export function genScheduleExercisesFiltersPaymentTypeOptions() {
  return [
    { label: 'Абонемент', value: 'SUBSCRIPTION' },
    { label: 'Оплата на месте', value: 'ON_PLACE' },
    { label: 'Ожидаем оплату', value: 'RESERVED' },
    { label: 'Единоразовая оплата', value: 'ONE_TIME' },
  ]
}

export function genScheduleExercisesFiltersBookingStatusOptions() {
  const bookingStatuses = getStrEnumValues<BookingStatus>(BookingStatus)

  return bookingStatuses.map(status => ({
    label: formatBookingStatus(status),
    value: status,
  }))
}

function optionsToMenuItems(
  exercisesFilter: ScheduleExercisesFilter,
  options: Nullable<DefaultOptionType[]>,
  type: keyof ScheduleExercisesFilter,
  onSelect: (newValue: string | string[]) => void,
  maxCountOptions?: number
) {
  if (!options) return

  return options.map(option => {
    const filterValue = exercisesFilter[type]
    const isArray = Array.isArray(filterValue)
    const isSelected = isArray && filterValue.find(item => item === option.value)

    const handleClick = () => {
      let newFilterValue: string[] = []

      if (isSelected) {
        newFilterValue = isArray ? filterValue.filter(item => item !== option.value) : []
      } else {
        if (typeof option.value === 'string') {
          if (maxCountOptions && (filterValue?.length || 0) >= maxCountOptions) {
            newFilterValue = [option.value]
          } else {
            newFilterValue = isArray ? [...filterValue, option.value] : [option.value]
          }
        }
      }

      onSelect(newFilterValue)
    }

    return {
      key: option.value,
      label: (
        <Button type="text" className="exercises-filters__item" onClick={handleClick}>
          <span>{option.label}</span>
          {isSelected && <CheckOutlined />}
        </Button>
      ),
    }
  })
}

export function genScheduleExercisesFiltersMenuItems(
  options: {
    trainersOptions: Nullable<DefaultOptionType[]>
    roomsOptions: Nullable<DefaultOptionType[]>
    paymentStatusOptions: Nullable<DefaultOptionType[]>
    paymentTypeOptions: Nullable<DefaultOptionType[]>
    timeScopeOptions: Nullable<DefaultOptionType[]>
    bookingStatusOptions: Nullable<DefaultOptionType[]>
  },
  isTimeFilterOpen: boolean,
  isDateFilterOpen: boolean,
  exercisesFilter: ScheduleExercisesFilter,
  longterm: boolean,
  toggleTimeFilter: () => void,
  toggleDateFilter: () => void,
  handleTrainersChange: (newValue: string | string[]) => void,
  handleSelectFilter: (name: string, value?: string | string[] | undefined) => void
) {
  const {
    trainersOptions,
    roomsOptions,
    paymentStatusOptions,
    paymentTypeOptions,
    timeScopeOptions,
    bookingStatusOptions,
  } = options

  return [
    ...(isDefAndNotEmpty(trainersOptions)
      ? [
          {
            key: '1',
            label: 'Исполнитель',
            children: optionsToMenuItems(
              exercisesFilter,
              [{ label: 'Без исполнителя', value: 'noTrainer' }, ...sortOptionsByLocale(trainersOptions)],
              'trainerIds',
              handleTrainersChange
            ),
          },
        ]
      : []),
    ...(isDefAndNotEmpty(roomsOptions)
      ? [
          {
            key: '2',
            label: 'Зал',
            children: optionsToMenuItems(
              exercisesFilter,
              sortOptionsByLocale(roomsOptions),
              'roomId',
              value => handleSelectFilter('roomId', value),
              1
            ),
          },
        ]
      : []),
    {
      key: '3',
      label: 'День недели',
      children: optionsToMenuItems(
        exercisesFilter,
        genDaysOptions(true),
        'dayOfWeek',
        value => handleSelectFilter('dayOfWeek', value),
        1
      ),
    },
    {
      key: '4',
      label: (
        <Flex justify="space-between" onClick={toggleTimeFilter}>
          Время {isTimeFilterOpen && <CheckOutlined />}
        </Flex>
      ),
    },
    {
      key: '5',
      label: (
        <Flex justify="space-between" onClick={toggleDateFilter}>
          Дата {isDateFilterOpen && <CheckOutlined />}
        </Flex>
      ),
    },
    ...(longterm
      ? [
          {
            key: '6',
            label: '',
            type: 'divider',
          },
          {
            key: '7',
            label: 'Статус оплаты',
            children: optionsToMenuItems(
              exercisesFilter,
              paymentStatusOptions,
              'bookingPaymentStatus',
              value => handleSelectFilter('bookingPaymentStatus', value),
              1
            ),
          },
          {
            key: '8',
            label: 'Метод оплаты',
            children: optionsToMenuItems(
              exercisesFilter,
              paymentTypeOptions,
              'paymentType',
              value => handleSelectFilter('paymentType', value),
              1
            ),
          },
          {
            key: '9',
            label: 'Статус записи',
            children: optionsToMenuItems(
              exercisesFilter,
              timeScopeOptions,
              'timeScope',
              value => handleSelectFilter('timeScope', value),
              1
            ),
          },
          {
            key: '10',
            label: 'Статус бронирования',
            children: optionsToMenuItems(exercisesFilter, bookingStatusOptions, 'bookingStatuses', value =>
              handleSelectFilter('bookingStatuses', value)
            ),
          },
        ]
      : []),
  ]
}
