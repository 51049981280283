import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { DefaultOptionType } from 'antd/es/select'

import { isDef } from '../../../types/lang.types'
import { PositionsFormValues } from '../../../components/positions/positions-form/positions-form.types'
import { usePositionsEditPageParams } from '../positions-edit-page-hooks/positions-edit-page-params.hook'
import {
  getPositionEditPageIsLoading,
  getPositionEditPageFormValues,
  getPositionEditPagePermissionsFormValues,
  getPositionEditPageGrades,
  getPositionEditPagePermissions,
  getPositionEditPagePositionsOptions,
  getPositionEditPageStudiosOptions,
  getPositionEditPageClientsCategoriesOptions,
  getPositionEditPageGradesOptions,
  getPositionEditPagePermissionsIsLoading,
} from '../../../store/pages/positions-edit-page/positions-edit-page.selectors'
import { positionsEditPageActions } from '../../../store/pages/positions-edit-page/positions-edit-page.slice'
import { DictionaryItem } from '../../../api/types/api.types'
import { PositionType } from '../../../types/positions.types'
import { PositionsFormPermissionsValues } from '../../../components/positions/positions-form/positions-form-permissions/positions-form-permissions.types'
import { AppModal } from '../../../types/modal.types'
import { modalActions } from '../../../store/common/modal/modal.slice'

export function usePositionsEditPageForm() {
  const [form] = Form.useForm<PositionsFormValues>()
  const [permissionsForm] = Form.useForm<PositionsFormPermissionsValues>()

  const { id } = usePositionsEditPageParams()

  const dispatch = useDispatch()

  const isLoading = useSelector(getPositionEditPageIsLoading)
  const isPermissionsLoading = useSelector(getPositionEditPagePermissionsIsLoading)
  const positionFormValues = useSelector(getPositionEditPageFormValues)
  const permissionsFormValues = useSelector(getPositionEditPagePermissionsFormValues)
  const positionGrades = useSelector(getPositionEditPageGrades)
  const positionPermissions = useSelector(getPositionEditPagePermissions)
  const positionsOptions = useSelector(getPositionEditPagePositionsOptions)
  const studiosOptions = useSelector(getPositionEditPageStudiosOptions)
  const clientsCategoriesOptions = useSelector(getPositionEditPageClientsCategoriesOptions)
  const positionGradesOptions = useSelector(getPositionEditPageGradesOptions)

  const [positionId, setPositionId] = React.useState<string>()
  const [sourcePositionId, setSourcePositionId] = React.useState<string | undefined>()

  const onUpdateGradeHandler = React.useCallback(
    (data: DictionaryItem): void => {
      if (isDef(id)) {
        dispatch(positionsEditPageActions.updateGrade({ positionId: id, grade: data }))
      }
    },
    [dispatch, id]
  )

  const onCreateGradeHandler = React.useCallback(
    (value: string, positionType: PositionType): void => {
      if (isDef(id)) {
        dispatch(positionsEditPageActions.createGrade({ positionId: id, name: value, positionType }))
      }
    },
    [dispatch, id]
  )

  const onRemoveGradeHandler = React.useCallback(
    (gradeId: string): void => {
      if (isDef(id)) {
        dispatch(positionsEditPageActions.removeGrade({ positionId: id, gradeId: gradeId }))
      }
    },
    [dispatch, id]
  )

  const onUpdatePermissionHandler = React.useCallback(
    (resourceName: string, permissionAction: string, isAllow: boolean, resourceIds?: string[]): void => {
      if (isDef(positionId)) {
        dispatch(
          positionsEditPageActions.updatePermission({
            positionId,
            resourceName,
            permissionAction,
            data: {
              policy: isAllow ? 'ALLOW' : 'DENY',
              resourceIds,
            },
          })
        )
      }
    },
    [dispatch, positionId]
  )

  const onCopyPermissionsHandler = React.useCallback(
    (sourcePositionId: string, sourcePosition: DefaultOptionType): void => {
      if (isDef(positionId)) {
        setSourcePositionId(sourcePositionId)

        const onCancel = () => {
          setSourcePositionId(undefined)
        }

        const onSubmit = () => {
          dispatch(
            positionsEditPageActions.copyPermissions({
              positionId,
              sourcePositionId,
            })
          )
        }

        dispatch(
          modalActions.show({
            modal: AppModal.POSITIONS_EDIT_PAGE_MODAL_CONFIRM,
            props: {
              sourcePosition: sourcePosition.label?.toString(),
              onSubmit,
              onCancel,
            },
          })
        )
      }
    },
    [dispatch, positionId]
  )

  const onChangePermissionsPositionHandler = React.useCallback(
    (id: string) => {
      setPositionId(id)
      setSourcePositionId(undefined)
      dispatch(positionsEditPageActions.fetchPermissions(id))
    },
    [dispatch]
  )

  const onFinishHandler = React.useCallback(
    (values: PositionsFormValues): void => {
      if (isDef(id)) {
        dispatch(positionsEditPageActions.updatePosition({ id, data: values }))
      }
    },
    [dispatch, id]
  )

  React.useEffect(() => {
    if (isDef(positionFormValues)) {
      form.setFieldsValue(positionFormValues)
    }
  }, [form, positionFormValues])

  React.useEffect(() => {
    if (isDef(permissionsFormValues)) {
      permissionsForm.setFieldsValue(permissionsFormValues)
    }
  }, [permissionsForm, permissionsFormValues])

  React.useEffect(() => {
    if (id) {
      setPositionId(id)
    }
  }, [id])

  return {
    form,
    permissionsForm,
    isLoading,
    isPermissionsLoading,
    positionGrades,
    positionPermissions,
    positionsOptions,
    studiosOptions,
    clientsCategoriesOptions,
    positionGradesOptions,
    positionId,
    sourcePositionId,
    onUpdateGradeHandler,
    onCreateGradeHandler,
    onRemoveGradeHandler,
    onUpdatePermissionHandler,
    onCopyPermissionsHandler,
    onChangePermissionsPositionHandler,
    onFinishHandler,
  }
}
