import { Form } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import dayjs from 'dayjs'
import { useCallback, useEffect } from 'react'

import { useTheme } from '../../../../hooks/use-theme.hook'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import {
  ScheduleModalConflictItem,
  ScheduleModalConflictsExerciseValues,
  ScheduleModalConflictsFormValues,
} from './schedule-modal-conflicts-exercise.types'
import { getScheduleModalConflictsExerciseValues } from './schedule-modal-conflicts-exercise.utils'

export interface UseScheduleModalConflictsExerciseProps {
  conflict: ScheduleModalConflictItem
  trainersOptions?: DefaultOptionType[]
  studioOffset?: number
  onSaveHandler: (
    exerciseId: string,
    timeFrom: string,
    timeTo: string,
    values?: ScheduleModalConflictsExerciseValues
  ) => void
}

export function useScheduleModalConflictsExercise(props: UseScheduleModalConflictsExerciseProps) {
  const { conflict, trainersOptions, studioOffset, onSaveHandler } = props
  const { exercise } = conflict

  const { theme } = useTheme()

  const offset = studioOffset || 0

  const timeFrom = dayjs(exercise.timeFrom).utcOffset(offset).format('HH:mm')
  const timeTo = dayjs(exercise.timeTo).utcOffset(offset).format('HH:mm')

  const trainerNames = exercise.trainers?.map(trainer => trainer.name).join(', ')

  const [form] = Form.useForm()

  const hasTrainersInConflict = isDefAndNotEmpty(conflict.conflicts?.trainers)
  const hasRoomsInConflict = isDefAndNotEmpty(conflict.conflicts?.rooms)

  const onFinish = useCallback(
    (values: ScheduleModalConflictsFormValues) => {
      const exerciseId = exercise.id
      const exerciseValues = getScheduleModalConflictsExerciseValues(values, conflict, trainersOptions)
      onSaveHandler(exerciseId, exercise.timeFrom, exercise.timeTo, exerciseValues)
    },
    [conflict, onSaveHandler, trainersOptions]
  )

  useEffect(() => {
    if (conflict?.conflicts) {
      form.setFieldsValue({
        ...(isDefAndNotEmpty(conflict.conflicts.rooms) && {
          room: conflict.conflicts.rooms?.[0].room?.id,
        }),
        ...(isDefAndNotEmpty(conflict.conflicts.trainers) && {
          trainers: [],
          noTrainer: false,
        }),
      })
    }
  }, [form, conflict, exercise.roomId, exercise.trainers])

  return {
    theme,
    form,
    timeFrom,
    timeTo,
    trainerNames,
    hasTrainersInConflict,
    hasRoomsInConflict,
    onFinish,
  }
}
