import { SelectRecordType } from '@components/ui/select-record-type/ui/select-record-type.component'
import { Card, Col, Flex, Form, Input, Row, Select, Switch, Typography } from 'antd'

import { ClientsAutocompleteContainer } from '../../../containers/clients-autocomplete/clients-autocomplete.container'
import { ScheduleSlots } from '../schedule-slots/schedule-slots.component'
import { useScheduleForm } from './schedule-form.hook'
import { ScheduleFormProps } from './schedule-form.types'
import './schedule-form.styles.less'

const { TextArea } = Input

export const ScheduleForm = (props: ScheduleFormProps) => {
  const { form, loading, type } = props
  const { directions, studiosRoomsOptions } = props
  const { exercisesTypesOptions: exercisesTypes, trainersOptions: trainers } = props
  const { longterm } = props
  const { onFieldsChange } = props

  const {
    direction,
    initialValues,
    directionValidationRules,
    typeValidationRules,
    phoneValidationRules,
    onFieldsChangeHandler,
    onChangeDirectionHandler,
    onChangePaymentTypeHandler,
    toggleShowComment,
    toggleShowClientRecord,
    toggleHasFocusGuestsSearch,
    isShowComment,
    isShowClientRecord,
    paymentMethodOptions,
    disabledClients,
    isDirectionDisabled,
    selectedTypeExercise,
    onChangeExercisesTypeHandler,
    onChangeMasterServiceHandler,
    onChangeSubServicesHandler,
    exercisesOptions,
    directionsOptions,
    masterServicesOptions,
    subServicesOptions,
    disabledType,
    trainersOptions,
    typeForm,
  } = useScheduleForm({ directions, exercisesTypes, trainers, form, onFieldsChange, type })

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      disabled={loading}
      initialValues={initialValues}
      onFieldsChange={onFieldsChangeHandler}
      autoComplete="off"
      layout="vertical"
    >
      <Card className="schedule-form__card" style={{ marginBottom: '16px' }}>
        <Flex vertical gap={10}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label="Категория записей" name="type" rules={typeValidationRules}>
                <SelectRecordType
                  placeholder="Категория записей"
                  showIcon={true}
                  loading={loading}
                  disabled={disabledType}
                  options={exercisesOptions}
                  value={form.getFieldValue('type')}
                  onChangeSelect={onChangeExercisesTypeHandler}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {selectedTypeExercise === 'PERSONAL' ? (
                <Form.Item label="Персональные услуги" name="masterService" rules={directionValidationRules}>
                  <SelectRecordType
                    placeholder="Персональные услуги"
                    loading={loading}
                    disabled={loading || isDirectionDisabled}
                    showIcon={false}
                    options={masterServicesOptions}
                    onChangeSelect={onChangeMasterServiceHandler}
                    value={form.getFieldValue('masterService')}
                  />
                </Form.Item>
              ) : (
                <Form.Item label="Групповые направления" name="direction" rules={directionValidationRules}>
                  <Select
                    placeholder="Групповые направления"
                    options={directionsOptions}
                    disabled={loading || isDirectionDisabled}
                    loading={loading}
                    onChange={onChangeDirectionHandler}
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownClassName="customDropdown"
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          {selectedTypeExercise === 'PERSONAL' && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Под-услуга" name="subService" rules={directionValidationRules}>
                  <SelectRecordType
                    placeholder="Под-услуга"
                    loading={loading}
                    disabled={loading || isDirectionDisabled}
                    showIcon={false}
                    options={subServicesOptions}
                    onChangeSelect={onChangeSubServicesHandler}
                    value={form.getFieldValue('subService')}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {!longterm && (
            <>
              <Flex gap="small" align="center">
                <Switch
                  disabled={!typeForm}
                  size="small"
                  defaultValue={isShowComment}
                  value={isShowComment}
                  onChange={toggleShowComment}
                />
                <Typography.Text
                  disabled={!typeForm}
                  className="exercises-form__section-label"
                  onClick={() => {
                    if (!!typeForm) {
                      toggleShowComment()
                    }
                  }}
                >
                  Комментарий ко всем созданным записям
                </Typography.Text>
              </Flex>

              <div style={{ display: isShowComment ? 'block' : 'none' }}>
                <Form.Item name="comment">
                  <TextArea value={form.getFieldValue('comment')} />
                </Form.Item>
              </div>
            </>
          )}
        </Flex>
      </Card>

      <ScheduleSlots
        isDisabled={!typeForm}
        isLoading={loading}
        form={form}
        trainersOptions={trainersOptions}
        studiosRoomsOptions={studiosRoomsOptions}
      />

      {longterm && (
        <Card className="schedule-form__card schedule-form__card--client">
          <Flex vertical gap={16}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Выберите клиента
            </Typography.Title>

            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  rules={phoneValidationRules}
                  validateTrigger="onBlur"
                  label={<span onClick={toggleHasFocusGuestsSearch}>Клиент</span>}
                  name="phone"
                >
                  <ClientsAutocompleteContainer
                    defaultValue={form.getFieldValue('phone')}
                    name="phone"
                    form={form}
                    disabled={loading || !typeForm}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста, выберите метод оплаты',
                    },
                  ]}
                  label="Метод оплаты"
                  name="paymentType"
                >
                  <Select
                    placeholder="Выбрать"
                    options={paymentMethodOptions}
                    disabled={loading || !typeForm}
                    onChange={onChangePaymentTypeHandler}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="comment" label="Комментарий к записи">
              <TextArea value={form.getFieldValue('comment')} disabled={loading || !typeForm} />
            </Form.Item>
          </Flex>
        </Card>
      )}
    </Form>
  )
}
