import { createSelector } from '@reduxjs/toolkit'

import { mapStudiosRoomsToOptions, mapStudiosRoomsToTags } from '../../../../mapping/studios-rooms.mapping'
import { mapExercisesTimetableToScheduleTableDataItems } from '../../../../mapping/exercises-timetable.mapping'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { Nullable } from '../../../../types/lang.types'
import { AppState } from '../../../app.store'

const genExercisesTimetableInternal = (state: AppState): Nullable<ExercisesTimetableApi.ExercisesTimetable[]> =>
  state.scheduleManagementPage.scheduleManagementPageTable.exercisesTimetable

const genStudiosRoomsInternal = (state: AppState): Nullable<StudiosRoomsApi.StudioRoom[]> =>
  state.scheduleManagementPage.scheduleManagementPageTable.studiosRooms

export const genScheduleManagementPageTableIsLoading = (state: AppState): boolean =>
  state.scheduleManagementPage.scheduleManagementPageTable.isLoading

export const genScheduleManagementPageTableIsLoaded = (state: AppState): boolean =>
  state.scheduleManagementPage.scheduleManagementPageTable.isLoaded

export const genScheduleManagementPageTableIsCanceling = (state: AppState): boolean =>
  state.scheduleManagementPage.scheduleManagementPageTable.isCanceling

export const genScheduleManagementPageTableTableDataItems = createSelector(
  genExercisesTimetableInternal,
  mapExercisesTimetableToScheduleTableDataItems
)

export const genScheduleManagementPageTableStudiosRoomsTags = createSelector(
  genStudiosRoomsInternal,
  mapStudiosRoomsToTags
)

export const genScheduleManagementPageTableStudiosRoomsOptions = createSelector(
  genStudiosRoomsInternal,
  mapStudiosRoomsToOptions
)
