import { put, takeLatest, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { notification } from 'antd'

import { api } from '../../../api/api'
import { callApi } from '../../../utils/sagas.utils'
import { positionsEditPageActions } from './positions-edit-page.slice'
import { genPositionsPagePath } from '../../../format/path.format'
import { genGradeCreateDTO, genGradeUpdateDTO, genPositionDTO } from '../../../mapping/positions.mapping'

export function* fetchPageData(action: ReturnType<typeof positionsEditPageActions.fetchPageData>) {
  try {
    const [positionResponse, permissionsResponse, positionsResponse, studiosResponse, clientsCategoriesResponse]: [
      Awaited<ReturnType<typeof api.positionsService.fetchPositionById>>,
      Awaited<ReturnType<typeof api.permissionsService.fetchPermissionsByPositionId>>,
      Awaited<ReturnType<typeof api.positionsService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosService.fetchAll>>,
      Awaited<ReturnType<typeof api.clientsCategoryService.fetchAll>>
    ] = yield all([
      callApi(api.positionsService.fetchPositionById, action.payload),
      callApi(api.permissionsService.fetchPermissionsByPositionId, action.payload),
      callApi(api.positionsService.fetchAll, {
        size: 100,
      }),
      callApi(api.studiosService.fetchAll, {
        size: 100,
        sort: 'name',
      }),
      callApi(api.clientsCategoryService.fetchAll, {
        size: 100,
      }),
    ])

    yield put(
      positionsEditPageActions.fetchPageDataSuccess({
        position: positionResponse.data,
        permissions: permissionsResponse.data,
        positions: positionsResponse.data,
        studios: studiosResponse.data,
        clientsCategories: clientsCategoriesResponse.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.fetchPageDataError(new Error()))
  }
}

export function* fetchPermissions(action: ReturnType<typeof positionsEditPageActions.fetchPermissions>) {
  try {
    const response: Awaited<ReturnType<typeof api.permissionsService.fetchPermissionsByPositionId>> = yield callApi(
      api.permissionsService.fetchPermissionsByPositionId,
      action.payload
    )

    yield put(positionsEditPageActions.fetchPermissionsSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.fetchPermissionsError(new Error()))
  }
}

export function* updatePosition(action: ReturnType<typeof positionsEditPageActions.updatePosition>) {
  try {
    const { id, data } = action.payload
    const positionDTO = genPositionDTO(data)

    yield callApi(api.positionsService.updatePosition, id, positionDTO)

    yield put(positionsEditPageActions.updatePositionSuccess())
    yield put(push(genPositionsPagePath()))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.updatePositionError(new Error()))
  }
}

export function* updateGrade(action: ReturnType<typeof positionsEditPageActions.updateGrade>) {
  try {
    const { positionId } = action.payload
    const { id, name } = action.payload.grade
    const gradeDTO = genGradeUpdateDTO(name)

    yield callApi(api.positionsService.updateGrade, id, gradeDTO)

    yield put(positionsEditPageActions.updateGradeSuccess())
    yield put(positionsEditPageActions.fetchPageData(positionId))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.updateGradeError(new Error()))
  }
}

export function* removeGrade(action: ReturnType<typeof positionsEditPageActions.removeGrade>) {
  try {
    const { positionId, gradeId } = action.payload
    yield callApi(api.positionsService.removeGrade, gradeId)

    yield put(positionsEditPageActions.removeGradeSuccess())
    yield put(positionsEditPageActions.fetchPageData(positionId))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.removeGradeError(new Error()))
  }
}

export function* createGrade(action: ReturnType<typeof positionsEditPageActions.createGrade>) {
  try {
    const { name, positionId, positionType } = action.payload
    const positionDTO = genGradeCreateDTO(name, positionId, positionType)

    yield callApi(api.positionsService.createGrade, positionDTO)

    yield put(positionsEditPageActions.createGradeSuccess())
    yield put(positionsEditPageActions.fetchPageData(positionId))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.createGradeError(new Error()))
  }
}

export function* updatePermission(action: ReturnType<typeof positionsEditPageActions.updatePermission>) {
  try {
    const { positionId, resourceName, permissionAction, data } = action.payload

    yield callApi(api.permissionsService.updatePositionPermission, positionId, resourceName, permissionAction, data)

    yield put(positionsEditPageActions.updatePermissionSuccess())
    yield notification.success({
      message: 'Настройки сохранены',
    })
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.updatePermissionError(new Error()))
  }
}

export function* copyPermissions(action: ReturnType<typeof positionsEditPageActions.copyPermissions>) {
  try {
    const { positionId, sourcePositionId } = action.payload

    yield callApi(api.permissionsService.copyPositionPermissions, positionId, { sourcePositionId })

    yield put(positionsEditPageActions.copyPermissionsSuccess())
    yield put(positionsEditPageActions.fetchPermissions(positionId))

    yield notification.success({
      message: 'Доступы скопированы',
    })
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.copyPermissionsError(new Error()))
  }
}

export function* positionsEditPageSagas() {
  yield takeLatest(positionsEditPageActions.fetchPageData, fetchPageData)
  yield takeLatest(positionsEditPageActions.fetchPermissions, fetchPermissions)
  yield takeLatest(positionsEditPageActions.updatePosition, updatePosition)
  yield takeLatest(positionsEditPageActions.updateGrade, updateGrade)
  yield takeLatest(positionsEditPageActions.createGrade, createGrade)
  yield takeLatest(positionsEditPageActions.removeGrade, removeGrade)
  yield takeLatest(positionsEditPageActions.updatePermission, updatePermission)
  yield takeLatest(positionsEditPageActions.copyPermissions, copyPermissions)
}
