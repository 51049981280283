import { Weekdays, Weekend } from '../../types/days.types'
import { NNumber, NString, Nullable } from '../../types/lang.types'
import { ExercisesTypesApi } from './exercises-types-api.types'

export interface Range<T> {
  from: T
  to: T
}

export enum Days {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}
export type DaysKeys = keyof typeof Days

export type DaysRange = Range<Days>

export type TimeRange = Range<string>

export type WorkTime = {
  [Day in Days]: TimeRange
}

export type WeekdaysWorkTime<T> = {
  [Weekday in Weekdays]: Range<T>
}

export type WeekendWorkTime<T> = {
  [Day in Weekend]: Range<T>
}

export interface PaginationParamsDTO {
  page: NNumber
  size: NNumber
  sort: NString
}

export interface PaginationParamsClientsBookingDTO extends PaginationParamsDTO {
  productId: string
  studioId?: string
}

export interface PaginationParamsActiveClientsBookingDTO extends PaginationParamsDTO {
  studioId?: string
}

export interface PaginationParamsDirectionsDTO extends PaginationParamsDTO {
  format: ExercisesTypesApi.ExerciseTypeFormat
}

export type Pagination<T> = {
  content: Nullable<T[]>
  totalPages: number
  totalElements: number
  size: number
  number: number
  pageable?: PaginationPageable
  sort?: PaginationSort
}

type PaginationSort = {
  sorted: boolean
  unsorted: boolean
  empty: boolean
}

type PaginationPageable = {
  paged: boolean
  unpaged: boolean
  pageNumber: number
  pageSize: number
  sort: PaginationSort
  offset: number
}

export type DictionaryItem<ID = string> = {
  id: ID
  name: string
  isMasterService?: boolean
}

export type ColoredDictionaryItem<ID = string> = {
  id: ID
  name: string
  color: string
}

export type Rate = {
  juniorRate: number
  middleRate: number
  seniorRate: number
  leadRate: number
  directionId: number
  roomId: string
}

export enum PaymentType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ON_PLACE = 'ON_PLACE',
  ONE_TIME = 'ONE_TIME',
  CREATE_TRANSACTION = 'CREATE_TRANSACTION',
  WAITING = 'WAITING',
}

export enum PaymentMethod {
  CARD = 'CARD',
  CASH = 'CASH',
  SMS = 'SMS',
  QR = 'QR',
  NO_PAYMENT = 'NO_PAYMENT',
}

export enum ProductType {
  SERVICE = 'SERVICE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  GOODS = 'GOODS',
  FULL_PAYMENT_SERVICE = 'FULL_PAYMENT_SERVICE',
  ADVANCE_SUB_SERVICE = 'ADVANCE_SUB_SERVICE',
  INSTANT_SUB_SERVICE = 'INSTANT_SUB_SERVICE',
}

export enum TimeLimitation {
  NONE = 'NONE',
  UNTIL_1645 = 'UNTIL_1645',
}

export interface Receipt {
  id: string
  number: NString
  createDate: NString
  url: NString
}

export enum ImpactType {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

export enum ImpactDirection {
  DISCOUNT = 'DISCOUNT',
  MARKUP = 'MARKUP',
}

export enum ConditionTypes {
  NONE = 'NONE',
  TIME = 'TIME',
  GRADE = 'GRADE',
  DURATION = 'DURATION',
  WITH_TRAINER = 'WITH_TRAINER',
}

export enum ExerciseTypeFormat {
  GROUP = 'GROUP',
  PERSONAL = 'PERSONAL',
  UNKNOWN = 'UNKNOWN',
}

export enum ExerciseTimeScope {
  FUTURE = 'FUTURE',
  PAST = 'PAST',
  ALL = 'ALL',
}

export enum BookingPaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  ALL = 'ALL',
}
