import * as React from 'react'
import { Button, Flex, Alert } from 'antd'

import { ScheduleForm } from '../schedule-form/schedule-form.component'
import { genScheduleFormValuesDTO, isCheckSlotsEmpty } from '../schedule-form/schedule-form.utils'
import { ScheduleModalCreateProps } from './schedule-modal-create.types'
import { ScheduleModal } from '../schedule-modal/schedule-modal.component'

export const ScheduleModalCreate: React.FC<ScheduleModalCreateProps> = props => {
  const { form, title, studioOffset, loading, type, longterm } = props
  const { directions, directionsOptions, exercisesTypesOptions, studiosRoomsOptions, trainersOptions } = props
  const { onCancel, onSave } = props

  const [isValid, setIsValid] = React.useState<boolean>(false)
  const [errorText, setErrorText] = React.useState<null | string>(null)

  const onSaveHandler = React.useCallback(async () => {
    const values = form.getFieldsValue()
    const scheduleFormValuesDTO = genScheduleFormValuesDTO(values, studioOffset)

    let hasFormErrors = false
    let hasSlotsErrors = false

    await form.validateFields().catch(err => {
      hasFormErrors = true
    })

    const iSlotsEmpty = isCheckSlotsEmpty(values)
    if (iSlotsEmpty) {
      setErrorText('Заполните хотя бы один слот в днях недели')
      hasSlotsErrors = true
    } else {
      setErrorText(null)
      hasSlotsErrors = false
    }

    !hasFormErrors && !hasSlotsErrors && onSave(scheduleFormValuesDTO)
  }, [form, onSave, studioOffset])

  const onFieldChangeHandler = React.useCallback(setIsValid, [setIsValid])

  return (
    <ScheduleModal
      title={title}
      onCancel={onCancel}
      footer={
        <Flex vertical gap="small">
          {errorText && <Alert showIcon message={errorText} type="error" style={{ alignSelf: 'baseline' }} />}
          <Flex gap="middle" justify="center">
            <Button loading={loading} onClick={onCancel}>
              Отменить
            </Button>
            <Button type="primary" loading={loading} onClick={onSaveHandler}>
              {`Создать ${longterm ? 'длинную запись' : 'расписание'}`}
            </Button>
          </Flex>
        </Flex>
      }
    >
      <ScheduleForm
        type={type}
        form={form}
        loading={loading}
        longterm={longterm}
        directions={directions}
        directionsOptions={directionsOptions}
        exercisesTypesOptions={exercisesTypesOptions}
        studiosRoomsOptions={studiosRoomsOptions}
        trainersOptions={trainersOptions}
        onFieldsChange={onFieldChangeHandler}
      />
    </ScheduleModal>
  )
}
