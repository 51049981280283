import { DefaultOptionType } from 'antd/lib/select'

import { DictionaryItem } from '../../../../api/types/api.types'
import { isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'
import {
  ScheduleModalConflictItem,
  ScheduleModalConflictsExerciseValues,
  ScheduleModalConflictsFormValues,
} from './schedule-modal-conflicts-exercise.types'

export function getScheduleModalConflictsExerciseValues(
  formValues: ScheduleModalConflictsFormValues,
  conflict: ScheduleModalConflictItem,
  trainersOptions?: DefaultOptionType[]
): ScheduleModalConflictsExerciseValues | undefined {
  const { exercise, conflicts } = conflict

  const getTrainerIds = (): Nullable<DictionaryItem[]> => {
    const hasTrainerConflict = formValues.noTrainer === true || formValues.trainers

    if (conflicts?.trainers && hasTrainerConflict) {
      const trainersInExercise: Nullable<DictionaryItem[]> = exercise.trainers
      const trainersInConflict: Nullable<DictionaryItem[]> = conflicts.trainers.flatMap(
        trainer => trainer.trainers || []
      )
      const difference = trainersInExercise?.filter(item1 => !trainersInConflict.find(item2 => item2.id === item1.id))

      const trainers: DictionaryItem[] = isDefAndNotEmpty(difference) ? difference : []

      if (formValues.noTrainer) {
        return undefined
      } else if (formValues.trainers) {
        const trainersArray = Object.values(formValues.trainers).map(trainer => {
          const name = trainersOptions?.find(option => option.value === trainer)?.label

          return {
            id: trainer,
            name: name ? name.toString() : '',
          }
        })

        trainers.push(...trainersArray)
      }

      return trainers.length > 0
        ? trainers.filter((item, index, self) => index === self.findIndex(t => t.id === item.id))
        : undefined
    }
    return exercise.trainers
  }

  const getRoomId = (): string | undefined => {
    const roomConflict = formValues.room

    if (roomConflict) {
      return roomConflict
    }
    return exercise.roomId
  }

  const roomId = getRoomId()
  const trainers = getTrainerIds()

  if (roomId) {
    return {
      timeFrom: exercise.timeFrom,
      timeTo: exercise.timeTo,
      trainers,
      roomId,
    }
  }
}
