import { Form, FormInstance } from 'antd'
import { useEffect, useMemo, useState } from 'react'

import { Days } from '../../../../../types/days.types'
import { isDefAndNotEmpty } from '../../../../../types/lang.types'
import { TimeRangePicker } from '../../../../ui/time-range-picker/time-range-picker.component'
import { ITimeRangePickerValue } from '../../../../ui/time-range-picker/time-range-picker.types'
import { ScheduleFormValues } from '../../../schedule-form/schedule-form.types'
import { ScheduleSlot } from '../../schedule-slots.types'
import { genScheduleSlotsItemTimeValidationRules } from './schedule-slots-items-time.utils'

interface Props {
  day: Days
  name: number
  form: FormInstance<ScheduleFormValues>
  disabled: boolean
}

export const ScheduleSlotsItemsTime = (props: Props) => {
  const { day, name, form, disabled } = props

  const [startTime, setStartTime] = useState<string | null>()
  const [endTime, setEndTime] = useState<string | null>()

  const timeRangeWatch = Form.useWatch(['slots', day, name, 'time'])
  const timeSlots = Form.useWatch(['slots', day])

  const onChangeTimeHandler = (value: ITimeRangePickerValue, name: number): void => {
    const values = form.getFieldsValue()
    const slotsByDay = values.slots[day]

    if (isDefAndNotEmpty(slotsByDay)) {
      if (value?.start) setStartTime(value.start)
      if (value?.end) setEndTime(value.end)
      const time = [value?.start ?? startTime, value?.end ?? endTime]

      const daysSlots = slotsByDay.map((slot: ScheduleSlot, index: number): ScheduleSlot => {
        return index === name ? { ...slot, time } : slot
      })
      form.setFieldsValue({
        ...values,
        slots: {
          ...values.slots,
          [day]: daysSlots,
        },
      })
    }
  }

  useEffect(() => {
    setStartTime(timeRangeWatch?.[0])
    setEndTime(timeRangeWatch?.[1])
  }, [timeRangeWatch])

  const timeRangeForTimePicker = useMemo(() => {
    return { start: timeRangeWatch?.[0], end: timeRangeWatch?.[1] }
  }, [timeRangeWatch])

  const timeValidationRules = useMemo(
    () => genScheduleSlotsItemTimeValidationRules(timeRangeForTimePicker.start, timeRangeForTimePicker.end, timeSlots),
    [timeRangeForTimePicker, day]
  )

  return (
    <Form.Item className="schedule-slots-item__form-item" name={[name, 'time']} rules={timeValidationRules}>
      <TimeRangePicker
        times={timeRangeForTimePicker}
        disabled={disabled}
        onChange={value => onChangeTimeHandler(value, name)}
      />
    </Form.Item>
  )
}
