import { combineReducers } from '@reduxjs/toolkit'

import { scheduleManagementPageModalCreateReducer } from './schedule-management-page-modal-create/schedule-management-page-modal-create.slice'
import { scheduleManagementPageModalEditReducer } from './schedule-management-page-modal-edit/schedule-management-page-modal-edit.slice'
import { scheduleManagementPageTableReducer } from './schedule-management-page-table/schedule-management-page-table.slice'
import { scheduleManagementPagePeekHistoryReducer } from './schedule-management-page-peek-history/schedule-management-page-peek-history.slice'
import { scheduleManagementPagePeekAboutReducer } from './schedule-management-page-peek-about/schedule-management-page-peek-about.slice'
import { scheduleManagementPageModalAddReducer } from './schedule-management-page-modal-add/schedule-management-page-modal-add.slice'
import { scheduleManagementPageModalConflictsReducer } from './schedule-management-page-modal-conflicts/schedule-management-page-modal-conflicts.slice'

export const scheduleManagementPageReducer = combineReducers({
  scheduleManagementPageModalCreate: scheduleManagementPageModalCreateReducer,
  scheduleManagementPageModalEdit: scheduleManagementPageModalEditReducer,
  scheduleManagementPageTable: scheduleManagementPageTableReducer,
  scheduleManagementPagePeekHistory: scheduleManagementPagePeekHistoryReducer,
  scheduleManagementPagePeekAbout: scheduleManagementPagePeekAboutReducer,
  scheduleManagementPageModalAdd: scheduleManagementPageModalAddReducer,
  scheduleManagementPageModalConflicts: scheduleManagementPageModalConflictsReducer,
})
