import { MinusOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Form, FormInstance, FormListFieldData, InputNumber, Select, Table } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { useCallback } from 'react'
import { genExercisesFormMaxClientsCountValidationRules } from '@components/exercises/exercises-form/exercises-form.utils'

import { ExercisesDirectionsApi } from '../../../../api/types/exercises-directions-api.types'
import { Days } from '../../../../types/days.types'
import './schedule-slots-items.styles.less'
import {
  genScheduleSlotsItemMaxClientCountValidationRules,
  genScheduleSlotsItemRoomValidationRules,
} from './schedule-slots-items.utils'
import { ScheduleSlotsItemsTime } from './schedule-slots-items-time/schedule-slots-items-time.component'
import { ScheduleFormValues } from '../../schedule-form/schedule-form.types'

interface Props {
  form: FormInstance<ScheduleFormValues>
  day: Days
  loading: boolean
  fields: FormListFieldData[]
  studiosRoomsOptions?: DefaultOptionType[]
  trainersOptions?: DefaultOptionType[]
  onRemove: (index: number) => void
  direction?: ExercisesDirectionsApi.ExerciseDirection
  disabled: boolean
}

export const ScheduleSlotsItems = (props: Props) => {
  const { form, day, fields, loading, studiosRoomsOptions, trainersOptions, disabled } = props
  const { onRemove } = props

  const onRemoveHandler = useCallback(
    (name: number): void => {
      onRemove(name)
    },
    [onRemove]
  )

  const roomValidationRules = genScheduleSlotsItemRoomValidationRules()
  const maxClientCountsValidationRules = [
    ...genScheduleSlotsItemMaxClientCountValidationRules(),
    ...genExercisesFormMaxClientsCountValidationRules(),
  ]

  return (
    <Table dataSource={fields} pagination={false} className="schedule-slots-items" loading={loading}>
      <Table.Column
        dataIndex={'time'}
        title={'Начало и конец'}
        render={(value, row, index) => {
          return <ScheduleSlotsItemsTime day={day} name={index} form={form} disabled={disabled} />
        }}
      />
      <Table.Column
        dataIndex={'room'}
        title={'Зал'}
        render={(value, row, index) => {
          return (
            <Form.Item className="schedule-slots-items__form-item" name={[index, 'room']}>
              <Select placeholder="Зал" options={studiosRoomsOptions} disabled={disabled} loading={loading} />
            </Form.Item>
          )
        }}
      />
      <Table.Column
        dataIndex={'maxClientsCount'}
        title={'Места'}
        render={(value, row, index) => {
          return (
            <Form.Item className="schedule-slots-items__form-item" name={[index, 'maxClientsCount']} initialValue={1}>
              <InputNumber
                style={{ width: '64px' }}
                disabled={disabled}
                placeholder="Места"
                min={1}
                suffix={<TeamOutlined style={{ color: '#00000040' }} />}
              />
            </Form.Item>
          )
        }}
      />
      <Table.Column
        dataIndex={'trainers'}
        title={'Исполнители'}
        render={(value, row, index) => {
          return (
            <Form.Item className="schedule-slots-items__form-item" name={[index, 'trainers']}>
              <Select
                disabled={disabled}
                placeholder="Выберите"
                options={trainersOptions}
                loading={loading}
                mode="multiple"
                showSearch={true}
                filterOption={(input, option) => {
                  const label = option?.label ? String(option.label) : ''
                  return label.toLowerCase().includes(input.toLowerCase())
                }}
                dropdownClassName="customDropdown"
              />
            </Form.Item>
          )
        }}
      />
      <Table.Column
        dataIndex={'actions'}
        render={(value, row, index) => {
          return (
            <Form.Item className="schedule-slots-items__form-item">
              <Button
                disabled={disabled}
                icon={<MinusOutlined />}
                size="middle"
                danger
                onClick={() => onRemoveHandler(index)}
              />
            </Form.Item>
          )
        }}
      />
    </Table>
  )
}
