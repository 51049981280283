import { Button, Modal } from 'antd'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { PositionsEditPageModalConfirmProps } from './positions-edit-page-modal-confirm.types'

export const PositionsEditPageModalConfirm: React.FC<PositionsEditPageModalConfirmProps> = props => {
  const { sourcePosition, onSubmit, onCancel } = props

  const dispatch = useDispatch()

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onCancelHandler = React.useCallback((): void => {
    onCancel()
    onCloseHandler()
  }, [onCancel, onCloseHandler])

  const onSubmitHandler = React.useCallback((): void => {
    onSubmit()
    onCloseHandler()
  }, [onCloseHandler, onSubmit])

  return (
    <Modal
      title={`Подтвердите что вы хотите скопировать доступы из должности "${sourcePosition}"`}
      visible
      onCancel={onCancelHandler}
      footer={[
        <Button key="back" onClick={onCancelHandler}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmitHandler}>
          Подтвердить
        </Button>,
      ]}
    />
  )
}
