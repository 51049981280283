import { all, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { positionsCreatePageActions } from './positions-create-page.slice'
import { callApi } from '../../../utils/sagas.utils'
import { api } from '../../../api/api'
import { genPositionsEditPagePath } from '../../../format/path.format'
import { genPositionDTO, genGradeCreateDTO } from '../../../mapping/positions.mapping'
import { isDef, isDefAndNotEmpty } from '../../../types/lang.types'

export function* createPosition(action: ReturnType<typeof positionsCreatePageActions.createPosition>) {
  try {
    const positionDTO = genPositionDTO(action.payload)
    const grades = action.payload.grades

    const response: Awaited<ReturnType<typeof api.positionsService.createPosition>> = yield callApi(
      api.positionsService.createPosition,
      positionDTO
    )

    if (isDef(response.data.id) && isDefAndNotEmpty(grades)) {
      yield all(
        grades.map(grade => {
          return put(
            positionsCreatePageActions.createGrade({
              name: grade,
              positionId: response.data.id,
              positionType: response.data.positionType,
            })
          )
        })
      )
    }

    yield put(positionsCreatePageActions.createPositionSuccess())
    yield put(push(genPositionsEditPagePath(response.data.id)))
  } catch (e) {
    console.error(e)
    yield put(positionsCreatePageActions.createPositionError(new Error()))
  }
}

export function* createGrade(action: ReturnType<typeof positionsCreatePageActions.createGrade>) {
  try {
    const { name, positionId, positionType } = action.payload
    const positionDTO = genGradeCreateDTO(name, positionId, positionType)

    yield callApi(api.positionsService.createGrade, positionDTO)

    yield put(positionsCreatePageActions.createGradeSuccess())
  } catch (e) {
    console.error(e)
    yield put(positionsCreatePageActions.createGradeError(new Error()))
  }
}

export function* positionsCreatePageSagas() {
  yield takeLatest(positionsCreatePageActions.createPosition, createPosition)
  yield takeLatest(positionsCreatePageActions.createGrade, createGrade)
}
