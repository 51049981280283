import { Input } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { formatIncompletePhoneNumber, AsYouType } from 'libphonenumber-js'
import React, { FC } from 'react'

import { isDef, NString } from '../../../types/lang.types'

interface Props {
  value: NString
  placeholder?: string
  size?: SizeType
  onChange: (phone: string) => void
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onBlur: () => void
  onFocus: () => void
}

export const InputPhoneNumber: FC<Props> = props => {
  const { value, placeholder = 'Номер телефона', size = 'middle', onChange, onBlur, onFocus } = props

  const formatPhoneNumber = (phoneNumber: NString) => {
    if (!isDef(phoneNumber)) return
    if (phoneNumber.length === 4 && phoneNumber.substring(0, 1) === '8') {
      return new AsYouType().input(phoneNumber)
    }

    return formatIncompletePhoneNumber(phoneNumber, { defaultCountry: 'RU' })
  }

  const onChangePhoneHandler = (value: string) => {
    const number = new AsYouType().input(value)
    onChange(number.replaceAll(' ', ''))
  }

  return (
    <Input
      size={size}
      type="tel"
      value={formatPhoneNumber(value)}
      placeholder={placeholder}
      onChange={e => onChangePhoneHandler(e.target.value)}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}
