import { Button, Col, Collapse, Flex, Form, Input, Row, Select, Typography } from 'antd'
import clsx from 'clsx'

import { formatPluralized } from '../../../format/text.format'
import { ScheduleEditExercisesFormProps } from './schedule-edit-exercises-form.types'
import { genDaysOptions } from '../../../utils/days.utils'
import { isDef } from '../../../types/lang.types'
import { useScheduleEditExercisesForm } from './schedule-edit-exercises-form.hook'
import './schedule-edit-exercises-form.styles.less'

export const ScheduleEditExercisesForm: React.FC<ScheduleEditExercisesFormProps> = props => {
  const {
    isLoading,
    form,
    studioId,
    initialValues,
    totalExercises,
    trainersOptions,
    studiosOptions,
    studiosRoomsOptions,
    onEditExercises,
    onRemoveExercises,
  } = props

  const {
    theme,
    activeKeys,
    isTrainerOptionDisabled,
    onChangeTrainersHandler,
    onCollapseChangeHandler,
    onChangeStudioHandler,
    onEditHandler,
  } = useScheduleEditExercisesForm({
    form,
    onEditExercises,
  })

  return (
    <Form disabled={isLoading} form={form}>
      <Flex vertical gap={16}>
        <Collapse
          className={clsx('schedule-edit-exercises-form__collapse', 'schedule-edit-exercises-form__collapse--warning', {
            'schedule-edit-exercises-form__collapse--open': activeKeys.includes('1'),
            'schedule-edit-exercises-form__collapse--dark': theme === 'dark',
          })}
          bordered={false}
          expandIconPosition="end"
          onChange={onCollapseChangeHandler}
          items={[
            {
              key: '1',
              label: <Typography.Text strong>Отмена записей и оплаты</Typography.Text>,
              children: (
                <Flex gap={16} vertical align="baseline">
                  <p style={{ margin: 0 }}>
                    {`Вы собираетесь отменить ${formatPluralized(totalExercises || 0, [
                      'запись',
                      'записи',
                      'записей',
                    ])}. При этом оплата за них останется на балансе клиента и их можно
            использовать на оплату других аналогичных записей клиента.`}
                  </p>
                  <Button type="primary" loading={isLoading} onClick={onRemoveExercises}>
                    Применить изменения для {formatPluralized(totalExercises || 0, ['запись', 'записи', 'записей'])}
                  </Button>
                </Flex>
              ),
            },
          ]}
        />
        {Object.values(initialValues).some(value => value !== undefined) && (
          <Collapse
            className="schedule-edit-exercises-form__collapse"
            ghost
            expandIconPosition="end"
            defaultActiveKey={1}
            items={[
              {
                key: '1',
                label: <Typography.Text strong>Изменение записей: день недели, время и другое</Typography.Text>,
                children: (
                  <Flex gap={16} vertical align="baseline">
                    <div style={{ width: '100%' }}>
                      {/* {isDef(initialValues['studio']) && (
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Студия"
                              name={['studio', 'oldValue']}
                            >
                              <Select placeholder="Выберите" disabled options={studiosOptions} suffixIcon={null} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Выберите новое значение"
                              name={['studio', 'newValue']}
                            >
                              <Select
                                placeholder="Выберите"
                                options={studiosOptions}
                                onChange={onChangeStudioHandler}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )} */}
                      {isDef(initialValues['room']) && (
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Зал"
                              name={['room', 'oldValue']}
                            >
                              <Select placeholder="Выберите" disabled options={studiosRoomsOptions} suffixIcon={null} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Выберите новое значение"
                              name={['room', 'newValue']}
                              rules={[{ required: true, message: 'Выберите значение' }]}
                            >
                              <Select
                                placeholder="Выберите"
                                options={studiosRoomsOptions?.filter(room => room.studioId === studioId)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {isDef(initialValues['trainers']) && (
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              name={['trainers', 'oldValue']}
                              label="Исполнитель"
                              rules={[{ required: true, message: 'Пожалуйста, выберите исполнителя' }]}
                            >
                              <Select
                                disabled
                                placeholder="Выберите исполнителя"
                                mode="multiple"
                                options={[{ label: 'Без исполнителя', value: 'noTrainer' }, ...(trainersOptions || [])]}
                                suffixIcon={null}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              name={['trainers', 'newValue']}
                              label="Выберите новое значение"
                              rules={[{ required: true, message: 'Выберите значение' }]}
                            >
                              <Select placeholder="Выберите" mode="multiple" onChange={onChangeTrainersHandler}>
                                <Select.Option value="noTrainer" disabled={isTrainerOptionDisabled('noTrainer')}>
                                  Без исполнителя
                                </Select.Option>
                                {trainersOptions?.map(option => (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                    disabled={isTrainerOptionDisabled(String(option.value))}
                                  >
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {isDef(initialValues['dayOfWeek']) && (
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Текущий день недели"
                              name={['dayOfWeek', 'oldValue']}
                            >
                              <Select
                                placeholder="Выберите"
                                disabled
                                options={genDaysOptions(true)}
                                suffixIcon={null}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Выберите новое значение"
                              required
                              name={['dayOfWeek', 'newValue']}
                              rules={[{ required: true, message: 'Выберите значение' }]}
                            >
                              <Select placeholder="Выберите" options={genDaysOptions(true)} />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {isDef(initialValues['timeFrom']) && (
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Текущее время начала"
                              name={['timeFrom', 'oldValue']}
                            >
                              <Input type="time" disabled className="schedule-edit-exercises-form__time-input" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Выберите новое время начала"
                              rules={[{ required: true, message: 'Выберите значение' }]}
                              name={['timeFrom', 'newValue']}
                            >
                              <Input type="time" className="schedule-edit-exercises-form__time-input" />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      {isDef(initialValues['timeTo']) && (
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Текущее время окончания"
                              name={['timeTo', 'oldValue']}
                            >
                              <Input type="time" disabled className="schedule-edit-exercises-form__time-input" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              className="schedule-edit-exercises-form__form-item"
                              label="Выберите новое время окончания"
                              rules={[{ required: true, message: 'Выберите значение' }]}
                              name={['timeTo', 'newValue']}
                            >
                              <Input type="time" className="schedule-edit-exercises-form__time-input" />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <Button type="primary" loading={isLoading} onClick={onEditHandler}>
                      Применить изменения для {formatPluralized(totalExercises || 0, ['запись', 'записи', 'записей'])}
                    </Button>
                  </Flex>
                ),
              },
            ]}
          />
        )}
        <Collapse
          className="schedule-edit-exercises-form__collapse"
          collapsible="disabled"
          ghost
          expandIconPosition="end"
          items={[
            {
              key: '1',
              label: <Typography.Text strong>Оплатить записи</Typography.Text>,
              children: (
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      className="schedule-edit-exercises-form__form-item"
                      label="Выберите доступный метод оплаты"
                      name="paymentType"
                      required
                    >
                      <Select placeholder="Выберите" />
                    </Form.Item>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </Flex>
    </Form>
  )
}
