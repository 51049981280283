import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { ScheduleManagementPageModalAddCreateExercisesPayload } from './schedule-management-page-modal-add.types'

export interface ScheduleManagementPageModalAddState {
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleManagementPageModalAddState = {
  isLoading: false,
  error: null,
}

export const { actions: scheduleManagementPageModalAddActions, reducer: scheduleManagementPageModalAddReducer } =
  createSlice({
    name: '@@schedule-management-page-modal-add',
    initialState,
    reducers: {
      addExercises: (
        state: Draft<ScheduleManagementPageModalAddState>,
        _action: PayloadAction<ScheduleManagementPageModalAddCreateExercisesPayload>
      ) => {
        state.isLoading = true
      },
      addExercisesSuccess: (state: Draft<ScheduleManagementPageModalAddState>): void => {
        state.isLoading = false
      },
      addExercisesError: (state: Draft<ScheduleManagementPageModalAddState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })
