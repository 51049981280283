import { PositionsForm } from '../../../components/positions/positions-form/positions-form.component'
import { usePositionsCreatePageForm } from './positions-create-page-form.hook'

export const PositionsCreatePageForm = () => {
  const { form, isLoading, onFinishHandler } = usePositionsCreatePageForm()

  return (
    <PositionsForm
      form={form}
      isLoading={isLoading}
      type="create"
      submitText="Создать должность"
      onFinish={onFinishHandler}
    />
  )
}
