import { combineReducers } from '@reduxjs/toolkit'

import { scheduleLongtermPageListReducer } from './schedule-longterm-page-list/schedule-longterm-page-list.slice'
import { scheduleLongtermPageModalAddReducer } from './schedule-longterm-page-modal-add/schedule-longterm-page-modal-add.slice'
import { scheduleLongtermPageModalConflictsReducer } from './schedule-longterm-page-modal-conflicts/schedule-longterm-page-modal-conflicts.slice'
import { scheduleLongtermPageModalCreateReducer } from './schedule-longterm-page-modal-create/schedule-longterm-page-modal-create.slice'
import { scheduleLongtermPageModalEditReducer } from './schedule-longterm-page-modal-edit/schedule-longterm-page-modal-edit.slice'
import { scheduleLongtermPagePeekAboutReducer } from './schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.slice'
import { scheduleLongtermPagePeekHistoryReducer } from './schedule-longterm-page-peek-history/schedule-longterm-page-peek-history.slice'

export const scheduleLongtermPageReducer = combineReducers({
  scheduleLongtermPageList: scheduleLongtermPageListReducer,
  scheduleLongtermPagePeekAbout: scheduleLongtermPagePeekAboutReducer,
  scheduleLongtermPageModalEdit: scheduleLongtermPageModalEditReducer,
  scheduleLongtermPageModalAdd: scheduleLongtermPageModalAddReducer,
  scheduleLongtermPageModalConflicts: scheduleLongtermPageModalConflictsReducer,
  scheduleLongtermPageModalCreate: scheduleLongtermPageModalCreateReducer,
  scheduleLongtermPagePeekHistory: scheduleLongtermPagePeekHistoryReducer,
})
