import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import {
  genPositionFormValues,
  genPositionPermissionsFormValues,
  genPositionGrades,
  mapPositionGradesToOptions,
  mapPositionsToOptions,
} from '../../../mapping/positions.mapping'
import { mapStudiosToOptions } from '../../../mapping/studios.mapping'
import { mapClientCategoriesToOptions } from '../../../mapping/clients-categories.mapping'

const getPositionInternal = (state: AppState) => state.positionsEditPage.position
const getPositionsInternal = (state: AppState) => state.positionsEditPage.positions
const getStudiosInternal = (state: AppState) => state.positionsEditPage.studios
const getClientsCategoriesInternal = (state: AppState) => state.positionsEditPage.clientsCategories

export const getPositionEditPageIsLoading = (state: AppState) => state.positionsEditPage.isLoading
export const getPositionEditPagePermissionsIsLoading = (state: AppState) => state.positionsEditPage.isPermissionsLoading
export const getPositionEditPagePermissions = (state: AppState) => state.positionsEditPage.permissions

export const getPositionEditPageGrades = createSelector(getPositionInternal, genPositionGrades)
export const getPositionEditPageFormValues = createSelector(getPositionInternal, genPositionFormValues)
export const getPositionEditPagePermissionsFormValues = createSelector(
  getPositionEditPagePermissions,
  genPositionPermissionsFormValues
)

export const getPositionEditPagePositionsOptions = createSelector(getPositionsInternal, positions =>
  mapPositionsToOptions(positions?.content)
)

export const getPositionEditPageStudiosOptions = createSelector(getStudiosInternal, studios =>
  mapStudiosToOptions(studios?.content)
)

export const getPositionEditPageClientsCategoriesOptions = createSelector(getClientsCategoriesInternal, categories =>
  mapClientCategoriesToOptions(categories?.content)
)

export const getPositionEditPageGradesOptions = createSelector(getPositionInternal, mapPositionGradesToOptions)
