import { AxiosResponse } from 'axios'

import { ContractsApi } from '../../api/types/contracts-api.types'
import { HttpConnector } from '../connectors/http.connector'
import { Pagination } from '../../api/types/api.types'

export class ContractsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (params: { size: number }): Promise<AxiosResponse<Pagination<ContractsApi.Counterparties>>> => {
    return this.httpConnector.get<Pagination<ContractsApi.Counterparties>>('/contracts/counterparties', { params })
  }

  public updateManager = (id: string, data: ContractsApi.ManagerId): Promise<AxiosResponse<ContractsApi.Contracts>> => {
    return this.httpConnector.patch<ContractsApi.Contracts>(`/contracts/${id}`, data)
  }

  public createCounterparties = (
    data: ContractsApi.CounterpartiesCreateDTO
  ): Promise<AxiosResponse<Pagination<ContractsApi.Counterparties>>> => {
    return this.httpConnector.post(`/contracts/counterparties`, data)
  }
}
