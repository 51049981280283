import { Button, Flex, Typography } from 'antd'
import { Link } from 'react-router-dom'

import './positions-page-top-bar.styles.less'
import { formatPathName } from '../../../format/text.format'
import { AppPath } from '../../../types/path.types'
import { genPositionsCreatePagePath } from '../../../format/path.format'

export const PositionsPageTopBar = () => {
  return (
    <Flex className="positions-top-bar" justify="space-between" align="center">
      <Typography.Title level={2} className="positions-top-bar__title">
        Должности сотрудников
      </Typography.Title>
      <Link to={genPositionsCreatePagePath()}>
        <Button type="primary">{formatPathName(AppPath.POSITIONS_CREATE)}</Button>
      </Link>
    </Flex>
  )
}
