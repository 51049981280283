import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { genScheduleEditExercisesFormValues } from '../../../../mapping/exercises-timetable.mapping'
import {
  genScheduleManagementPagePeekAboutStudios,
  genScheduleManagementPagePeekAboutExercisesFilter,
} from '../schedule-management-page-peek-about/schedule-management-page-peek-about.selectors'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

export const genScheduleManagementPageModalEditIsLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModalEdit.isLoading

export const getScheduleManagementPageModalEditFormValues = createSelector(
  genScheduleManagementPagePeekAboutExercisesFilter,
  genScheduleManagementPagePeekAboutStudios,
  getCurrentStudioOffset,
  (exercisesFilter, studios, offset) => genScheduleEditExercisesFormValues(exercisesFilter, studios?.content, offset)
)
