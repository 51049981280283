import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { EmployeesApi } from '../../../api/types/employees-api.types'
import { ContractsApi } from '../../../api/types/contracts-api.types'
import { Pagination } from '../../../api/types/api.types'
import { Nullable } from '../../../types/lang.types'
import { ContractsPageUpdateManagerPayload } from './contracts-page.types'

export interface ContractsPageState {
  counterparties: Nullable<Pagination<ContractsApi.Counterparties>>
  managers: Nullable<Pagination<EmployeesApi.Employee>>
  isLoading: boolean
  isLoaded: boolean
  isUpdating: boolean
  isCreating: boolean
  error: Nullable<Error>
}

const initialState: ContractsPageState = {
  counterparties: null,
  managers: null,
  isLoading: false,
  isLoaded: false,
  isUpdating: false,
  isCreating: false,
  error: null,
}

export const { actions: contractsPageActions, reducer: contractsPageReducer } = createSlice({
  name: '@@contracts-page',
  initialState,
  reducers: {
    fetchAllCounterparties: (state: Draft<ContractsPageState>) => {
      state.isLoading = true
    },
    fetchAllCounterpartiesSuccess: (
      state: Draft<ContractsPageState>,
      action: PayloadAction<Nullable<Pagination<ContractsApi.Counterparties>>>
    ) => {
      state.counterparties = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllCounterpartiesError: (state: Draft<ContractsPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    fetchAllManagers: (state: Draft<ContractsPageState>) => {
      state.isLoading = true
    },
    fetchAllManagersSuccess: (
      state: Draft<ContractsPageState>,
      action: PayloadAction<Nullable<Pagination<EmployeesApi.Employee>>>
    ) => {
      state.managers = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllManagersError: (state: Draft<ContractsPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    updateManager: (state: Draft<ContractsPageState>, _: PayloadAction<ContractsPageUpdateManagerPayload>) => {
      state.isUpdating = true
    },
    updateManagerSuccess: (state: Draft<ContractsPageState>, action: PayloadAction<any>) => {
      state.isUpdating = false
    },
    updateManagerError: (state: Draft<ContractsPageState>, action: PayloadAction<Error>) => {
      state.isUpdating = false
      state.error = action.payload
    },
    createCounterparties: (
      state: Draft<ContractsPageState>,
      _: PayloadAction<ContractsApi.CounterpartiesCreateDTO>
    ) => {
      state.isCreating = true
    },
    createCounterpartiesSuccess: (state: Draft<ContractsPageState>) => {
      state.isCreating = false
    },
    createCounterpartiesError: (state: Draft<ContractsPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isCreating = false
    },
    updateCounterpartiesCardList: (
      state: Draft<ContractsPageState>,
      _: PayloadAction<Pagination<ContractsApi.Counterparties>>
    ) => {
      state.isLoaded = true
    },
    reset: () => initialState,
  },
})
