import * as React from 'react'
import { Typography } from 'antd'

export const ScheduleManagementPageTitle: React.FC = () => {
  return (
    <Typography.Title level={2} style={{ margin: 0 }}>
      Расписание занятий
    </Typography.Title>
  )
}
