import { Button, Card, Flex, Form } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import clsx from 'clsx'

import { PositionsFormGradesProps } from './positions-form-grades.types'
import { PositionsFormGrade } from './positions-form-grade/positions-form-grade.component'
import { useTheme } from '../../../../hooks/use-theme.hook'
import './positions-form-grades.styles.less'

export const PositionsFormGrades: React.FC<PositionsFormGradesProps> = props => {
  const { ...rest } = props

  const { theme } = useTheme()

  return (
    <Card
      className={clsx('positions-form-grades', {
        'positions-form-grades--dark': theme === 'dark',
      })}
    >
      <Form.List name="grades">
        {(fields, { add, remove }) => (
          <Flex gap={16} vertical>
            {fields.map((field, index) => (
              <PositionsFormGrade index={index} field={field} key={index} remove={remove} {...rest} />
            ))}
            <Form.Item>
              <Button onClick={() => add()} icon={<PlusOutlined />}>
                Добавить грейд
              </Button>
            </Form.Item>
          </Flex>
        )}
      </Form.List>
    </Card>
  )
}
