import { createSelector } from '@reduxjs/toolkit'

import { mapDirectionsToOptions } from '../../../../mapping/directions.mapping'
import { mapExercisesTypesToExercisesForm } from '../../../../mapping/exercises-types.mapping'
import { mapStudiosRoomsToOptions } from '../../../../mapping/studios-rooms.mapping'
import { mapTrainersToExercisesForm } from '../../../../mapping/trainers.mapping'
import { AppState } from '../../../app.store'

export const genScheduleManagementPageModalCreateIsLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModalCreate.isLoading

const getExercisesTypesInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModalCreate.exercisesTypes

const getStudiosRoomsInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModalCreate.studiosRooms

const getTrainersInternal = (state: AppState) => state.scheduleManagementPage.scheduleManagementPageModalCreate.trainers

const getDirectionsInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModalCreate.directions

export const getScheduleManagementPageModalCreateDirections = createSelector(
  getDirectionsInternal,
  directions => directions?.content
)

export const getScheduleManagementPageModalCreateIsLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModalCreate.isLoading

export const getScheduleManagementPageModalCreateDirectionsOptions = createSelector(
  getScheduleManagementPageModalCreateDirections,
  directions => mapDirectionsToOptions(directions)
)

export const getScheduleManagementPageModalCreateExercisesTypesOptions = createSelector(
  getExercisesTypesInternal,
  exercisesTypes => mapExercisesTypesToExercisesForm(exercisesTypes?.content)
)

export const getScheduleManagementPageModalCreateStudiosRoomsOptions = createSelector(
  getStudiosRoomsInternal,
  studiosRooms => mapStudiosRoomsToOptions(studiosRooms?.content)
)

export const getScheduleManagementPageModalCreateTrainersOptions = createSelector(getTrainersInternal, trainers =>
  mapTrainersToExercisesForm(trainers?.content)
)
