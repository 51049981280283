import { DefaultOptionType } from 'antd/lib/select'

import { formatFullName } from '../format/text.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { EmployeesApi } from '../api/types/employees-api.types'
import { ContractsApi } from '../api/types/contracts-api.types'

export function mapCounterpartiesToCardList(organizations: Nullable<ContractsApi.Counterparties[]>) {
  if (isDefAndNotEmpty(organizations)) {
    return organizations.map((counterparties: ContractsApi.Counterparties) => ({
      id: counterparties.id,
      bank: counterparties.bankName,
      directorName: counterparties.directorName,
      company: counterparties.companyName,
      telephone: counterparties.phoneNumber?.replace(/\D/g, ''),
      activeContracts: counterparties.activeContracts,
      email: counterparties.email,
      clientCount: counterparties.clientCount,
    }))
  }

  return null
}

export function mapManagersToDropdown(managers: Nullable<EmployeesApi.Employee[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(managers)) {
    return managers.map((manager: EmployeesApi.Employee): DefaultOptionType => {
      const { firstName, lastName, id } = manager

      const fullName = formatFullName(firstName, lastName)

      return {
        value: id,
        label: `Менеджер: ${fullName}`,
      }
    })
  }
}

export function getCounterpartiesDTO(
  data: ContractsApi.CounterpartiesCreateDTO
): Nullable<ContractsApi.CounterpartiesCreateDTO> {
  if (isDef(data)) {
    return {
      companyName: data.companyName,
      displayName: data.companyName,
      ogrn: data.ogrn,
      inn: data.inn,
      kpp: data.kpp,
      snoType: data.snoType,
      address: data.address,
      directorName: data.directorName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      bankName: data.bankName,
      bic: data.bic,
      bankAccount: data.bankAccount,
      correspondentAccount: data.correspondentAccount,
    }
  }

  return null
}
