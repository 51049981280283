import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { TrainersApi } from '../../../../api/types/trainers-api.types'
import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleLongtermPageModalConflictsCreateExercisePayload,
  ScheduleLongtermPageModalConflictsEditExercisePayload,
} from './schedule-longterm-page-modal-conflicts.types'

export interface ScheduleLongtermPageModalConflictsState {
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleLongtermPageModalConflictsState = {
  trainers: null,
  isLoading: false,
  error: null,
}

export const {
  actions: scheduleLongtermPageModalConflictsActions,
  reducer: scheduleLongtermPageModalConflictsReducer,
} = createSlice({
  name: '@@schedule-longterm-page-modal-conflicts',
  initialState,
  reducers: {
    fetchModalData: (state: Draft<ScheduleLongtermPageModalConflictsState>): void => {
      state.isLoading = true
    },
    fetchModalDataSuccess: (
      state: Draft<ScheduleLongtermPageModalConflictsState>,
      action: PayloadAction<Nullable<Pagination<TrainersApi.Trainer>>>
    ): void => {
      state.trainers = action.payload
      state.isLoading = false
    },
    fetchModalDataError: (
      state: Draft<ScheduleLongtermPageModalConflictsState>,
      action: PayloadAction<Error>
    ): void => {
      state.error = action.payload
      state.isLoading = false
    },
    createExercise: (
      state: Draft<ScheduleLongtermPageModalConflictsState>,
      _: PayloadAction<ScheduleLongtermPageModalConflictsCreateExercisePayload>
    ) => {
      state.isLoading = true
    },
    createExerciseSuccess: (state: Draft<ScheduleLongtermPageModalConflictsState>) => {
      state.isLoading = false
    },
    createExerciseError: (state: Draft<ScheduleLongtermPageModalConflictsState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    editExercise: (
      state: Draft<ScheduleLongtermPageModalConflictsState>,
      _: PayloadAction<ScheduleLongtermPageModalConflictsEditExercisePayload>
    ) => {
      state.isLoading = true
    },
    editExerciseSuccess: (state: Draft<ScheduleLongtermPageModalConflictsState>) => {
      state.isLoading = false
    },
    editExerciseError: (state: Draft<ScheduleLongtermPageModalConflictsState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
