import { Col, Flex, Form, Row, Select, Space, Typography } from 'antd'

import { CustomCard } from '../../../custom-card/custom-card.component'
import { PositionsFormPermissionsGroup } from './positions-form-permissions-group/positions-form-permissions-group.component'
import { PositionsFormPermissionsProps } from './positions-form-permissions.types'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import './positions-form-permissions.styles.less'

export const PositionsFormPermissions: React.FC<PositionsFormPermissionsProps> = props => {
  const { positionPermissions, permissionsForm, positionGradesOptions, isPermissionsLoading } = props
  const { positionId, sourcePositionId } = props
  const { positionsOptions, studiosOptions, clientsCategoriesOptions } = props
  const { onUpdatePermission, onChangePermissionsPosition, onCopyPermissions } = props

  const midIndex = Math.ceil((positionPermissions?.length || 0) / 2)
  const firstColumn = positionPermissions?.slice(0, midIndex)
  const secondColumn = positionPermissions?.slice(midIndex)

  if (isDefAndNotEmpty(positionPermissions) && onUpdatePermission) {
    return (
      <CustomCard customClassName="positions-form-permissions__card">
        <Form form={permissionsForm} disabled={isPermissionsLoading}>
          <Flex vertical gap={24}>
            <Flex vertical gap={10} align="baseline">
              <Space size={10} align="center">
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Матрица доступов
                </Typography.Title>
                <Select
                  size="small"
                  style={{ width: '168px' }}
                  value={positionId}
                  options={positionGradesOptions}
                  disabled={isPermissionsLoading}
                  onChange={onChangePermissionsPosition}
                />
              </Space>
              <Select
                size="small"
                style={{ width: '200px' }}
                placeholder="Скопировать настройки"
                value={sourcePositionId}
                options={positionsOptions?.filter(option => option.value !== positionId)}
                disabled={isPermissionsLoading}
                onSelect={onCopyPermissions}
              />
            </Flex>
            <Row gutter={50}>
              {firstColumn && (
                <Col span={12}>
                  {firstColumn.map((item, index) => (
                    <PositionsFormPermissionsGroup
                      key={index}
                      groupItem={item}
                      isLast={index === firstColumn.length - 1}
                      positionsOptions={positionsOptions}
                      studiosOptions={studiosOptions}
                      clientsCategoriesOptions={clientsCategoriesOptions}
                      permissionsForm={permissionsForm}
                      handlePermissionChange={onUpdatePermission}
                    />
                  ))}
                </Col>
              )}

              {secondColumn && (
                <Col span={12}>
                  {secondColumn.map((item, index) => (
                    <PositionsFormPermissionsGroup
                      key={index}
                      groupItem={item}
                      isLast={index === secondColumn.length - 1}
                      positionsOptions={positionsOptions}
                      studiosOptions={studiosOptions}
                      clientsCategoriesOptions={clientsCategoriesOptions}
                      permissionsForm={permissionsForm}
                      handlePermissionChange={onUpdatePermission}
                    />
                  ))}
                </Col>
              )}
            </Row>
          </Flex>
        </Form>
      </CustomCard>
    )
  }
  return null
}
