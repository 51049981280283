import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { TrainersApi } from '../../../../api/types/trainers-api.types'
import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleLongtermPagePeekHistoryFetchAuditPayload,
  ScheduleLongtermPagePeekHistoryFetchAuditSuccessPayload,
} from './schedule-longterm-page-peek-history.types'

export interface ScheduleLongtermPagePeekHistoryState {
  scheduleAudit: Nullable<Pagination<AuditApi.Audit>>
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleLongtermPagePeekHistoryState = {
  scheduleAudit: null,
  trainers: null,
  isLoading: false,
  error: null,
}

export const { actions: scheduleLongtermPagePeekHistoryActions, reducer: scheduleLongtermPagePeekHistoryReducer } =
  createSlice({
    name: '@@schedule-longterm-page-peek-history',
    initialState,
    reducers: {
      fetchAudit: (
        state: Draft<ScheduleLongtermPagePeekHistoryState>,
        _: PayloadAction<ScheduleLongtermPagePeekHistoryFetchAuditPayload>
      ): void => {
        state.isLoading = true
      },
      fetchAuditSuccess: (
        state: Draft<ScheduleLongtermPagePeekHistoryState>,
        action: PayloadAction<ScheduleLongtermPagePeekHistoryFetchAuditSuccessPayload>
      ): void => {
        state.scheduleAudit = action.payload.scheduleAudit
        state.trainers = action.payload.trainers
        state.isLoading = false
      },
      fetchAuditError: (state: Draft<ScheduleLongtermPagePeekHistoryState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })
