import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useScheduleLongtermPageParams } from '../schedule-longterm-page-hooks/schedule-longterm-page-params.hook'
import {
  genScheduleLongtermPageListStudiosRoomsTags,
  genScheduleLongtermPageListTableDataItems,
} from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.selectors'
import { genScheduleLongtermPagePath } from '../../../format/path.format'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { useStudio } from '../../../hooks/use-studios.hook'

export function useScheduleLongtermPageFilter() {
  const { push } = useHistory()
  const { studioOffset } = useStudio()

  const dispatch = useDispatch()

  const { studioId, ...params } = useScheduleLongtermPageParams()
  const { roomId } = params

  const schedule = useSelector(genScheduleLongtermPageListTableDataItems)
  const studiosRoomsTags = useSelector(genScheduleLongtermPageListStudiosRoomsTags)

  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const onCreateHandler = useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CREATE,
        props: { studioId, studioOffset },
      })
    )
  }, [dispatch, studioId, studioOffset])

  const onFilterHandler = useCallback(() => {
    setIsFilterOpen(!isFilterOpen)
  }, [isFilterOpen])

  const onSelectRoomHandler = useCallback(
    (roomId: string): void => {
      push(genScheduleLongtermPagePath(studioId, { ...params, roomId }))
    },
    [params, push, studioId]
  )

  const onResetRoomHandler = useCallback(() => {
    push(genScheduleLongtermPagePath(studioId, { ...params, roomId: null }))
  }, [params, push, studioId])

  return {
    roomId,
    schedule,
    studiosRoomsTags,
    isFilterOpen,
    onCreateHandler,
    onFilterHandler,
    onSelectRoomHandler,
    onResetRoomHandler,
  }
}
