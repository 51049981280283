import { ScheduleModalConflicts } from '../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.component'
import { useScheduleManagementPageModalConflicts } from './schedule-management-page-modal-conflicts.hook'
import { ScheduleManagementPageModalConflictsProps } from './schedule-management-page-modal-conflicts.types'

export const ScheduleManagementPageModalConflicts: React.FC<ScheduleManagementPageModalConflictsProps> = props => {
  const { action, studioId, studioOffset } = props

  const { isLoading, conflicts, studiosRoomsOptions, trainersOptions, onSaveHandler, onSkipHandler, onCancelHandler } =
    useScheduleManagementPageModalConflicts(props)

  return (
    <ScheduleModalConflicts
      studioId={studioId}
      action={action}
      isLoading={isLoading}
      conflicts={conflicts}
      trainersOptions={trainersOptions}
      studiosRoomsOptions={studiosRoomsOptions}
      studioOffset={studioOffset}
      onCancel={onCancelHandler}
      onSkip={onSkipHandler}
      onSave={onSaveHandler}
    />
  )
}
