import { createSelector } from '@reduxjs/toolkit'

import { mapDirectionsToOptions } from '../../../../mapping/directions.mapping'
import { mapExercisesTypesToExercisesForm } from '../../../../mapping/exercises-types.mapping'
import { mapStudiosRoomsToOptions } from '../../../../mapping/studios-rooms.mapping'
import { mapTrainersToExercisesForm } from '../../../../mapping/trainers.mapping'
import { AppState } from '../../../app.store'

const getExercisesTypesInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPageModalCreate.exercisesTypes

const getStudiosRoomsInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPageModalCreate.studiosRooms

const getTrainersInternal = (state: AppState) => state.scheduleLongtermPage.scheduleLongtermPageModalCreate.trainers

const getDirectionsInternal = (state: AppState) => state.scheduleLongtermPage.scheduleLongtermPageModalCreate.directions

export const getScheduleLongtermPageModalCreateDirections = createSelector(
  getDirectionsInternal,
  directions => directions?.content
)

export const getScheduleLongtermPageModalCreateIsLoading = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPageModalCreate.isLoading

export const getScheduleLongtermPageModalCreateDirectionsOptions = createSelector(
  getScheduleLongtermPageModalCreateDirections,
  directions => mapDirectionsToOptions(directions)
)

export const getScheduleLongtermPageModalCreateExercisesTypesOptions = createSelector(
  getExercisesTypesInternal,
  exercisesTypes => mapExercisesTypesToExercisesForm(exercisesTypes?.content)
)

export const getScheduleLongtermPageModalCreateStudiosRoomsOptions = createSelector(
  getStudiosRoomsInternal,
  studiosRooms => mapStudiosRoomsToOptions(studiosRooms?.content)
)

export const getScheduleLongtermPageModalCreateTrainersOptions = createSelector(getTrainersInternal, trainers =>
  mapTrainersToExercisesForm(trainers?.content)
)
