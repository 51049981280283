import { FilterOutlined, ProfileOutlined } from '@ant-design/icons'
import { Flex, Button } from 'antd'

import { StudiosRoomsTags } from '../../../components/studios-rooms-tags/studios-rooms-tags.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { useScheduleLongtermPageFilter } from './schedule-longterm-page-filter.hook'

export const ScheduleLongtermPageFilter: React.FC = () => {
  const {
    roomId,
    schedule,
    studiosRoomsTags,
    isFilterOpen,
    onCreateHandler,
    onFilterHandler,
    onSelectRoomHandler,
    onResetRoomHandler,
  } = useScheduleLongtermPageFilter()

  return (
    <Flex style={{ paddingBottom: '24px' }} vertical gap={16}>
      <Flex justify="space-between">
        {isDefAndNotEmpty(studiosRoomsTags) && isDefAndNotEmpty(schedule) && (
          <Button icon={<FilterOutlined style={{ color: '#0958D9' }} />} onClick={onFilterHandler}>
            Фильтры
          </Button>
        )}
        <Button type="primary" icon={<ProfileOutlined />} onClick={onCreateHandler}>
          Создать длинную запись
        </Button>
      </Flex>
      {isDefAndNotEmpty(studiosRoomsTags) && isFilterOpen && (
        <div>
          <StudiosRoomsTags
            tags={studiosRoomsTags}
            selectedId={roomId}
            onSelect={onSelectRoomHandler}
            onReset={onResetRoomHandler}
          />
        </div>
      )}
    </Flex>
  )
}
