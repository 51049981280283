import { put, select, take, takeLatest } from 'redux-saga/effects'

import { apiV2 } from '../../../../api/api-v2'
import { PaymentType } from '../../../../api/types/api.types'
import { ScheduleModalConflictsAction } from '../../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.types'
import { ScheduleOverview } from '../../../../components/schedule/schedule-overview/schedule-overview.types'
import { genScheduleExercisesAddDTO, getTotalExercises } from '../../../../mapping/exercises-timetable.mapping'
import { Nullable } from '../../../../types/lang.types'
import { AppModal } from '../../../../types/modal.types'
import { callApi } from '../../../../utils/sagas.utils'
import { modalActions } from '../../../common/modal/modal.slice'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { genScheduleLongtermPagePeekAboutSchedule } from '../schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPageModalAddActions } from './schedule-longterm-page-modal-add.slice'

function* addExercises(action: ReturnType<typeof scheduleLongtermPageModalAddActions.addExercises>) {
  const { studioId, scheduleId, scheduleFormValues } = action.payload

  const schedule: Nullable<ScheduleOverview> = yield select(genScheduleLongtermPagePeekAboutSchedule)

  const exercisesDTO = genScheduleExercisesAddDTO(scheduleFormValues)

  yield put(websocketTimetableActions.connect(scheduleId))

  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    if (schedule) {
      const { direction, type, client } = schedule
      const { timeslots, dateFrom, dateTo } = exercisesDTO

      if (direction && type) {
        const totalExercises = getTotalExercises(dateFrom, dateTo, exercisesDTO.timeslots)

        yield callApi(apiV2.exercisesTimetableService.addExercisesByTimetableId, scheduleId, exercisesDTO)
        yield put(scheduleLongtermPageModalAddActions.addExercisesSuccess())
        yield put(modalActions.closeLast())
        yield put(
          modalActions.show({
            modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
            props: {
              studioId,
              scheduleId,
              action: ScheduleModalConflictsAction.ADD,
              totalExercises,
              totalBookings: totalExercises,
              createdExercises: {
                directionId: direction.id,
                typeId: type.id,
                timeslots,
                phone: client?.phone,
                paymentType: PaymentType.ON_PLACE,
              },
            },
          })
        )
      } else {
        yield put(scheduleLongtermPageModalAddActions.addExercisesError(new Error()))
      }
    } else {
      yield put(scheduleLongtermPageModalAddActions.addExercisesError(new Error()))
    }
  } catch (e) {
    console.error(e)
    yield put(scheduleLongtermPageModalAddActions.addExercisesError(new Error()))
  }
}

export function* scheduleLongtermPageModalAddSagas() {
  yield takeLatest(scheduleLongtermPageModalAddActions.addExercises, addExercises)
}
