import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useTheme } from '../../../hooks/use-theme.hook'
import { getCurrentStudioOffset } from '../../../store/common/layout/layout.selectors'
import { isDef, NString, Nullable } from '../../../types/lang.types'
import { ScheduleExercisesFilter } from './schedule-exercises-filters.types'
import {
  genScheduleExercisesFiltersBookingStatusOptions,
  genScheduleExercisesFiltersPaymentStatusOptions,
  genScheduleExercisesFiltersPaymentTypeOptions,
  genScheduleExercisesFiltersTimeScopeOptions,
} from './schedule-exercises-filters.utils'

export interface UseScheduleExercisesFiltersProps {
  dateFrom?: NString
  dateTo?: NString
  exercisesFilter: ScheduleExercisesFilter
  onSelectFilter: (name: string, value?: string | string[]) => void
  onResetFilter: () => void
}

export function useScheduleExercisesFilters(props: UseScheduleExercisesFiltersProps) {
  const { dateFrom, dateTo, exercisesFilter, onSelectFilter, onResetFilter } = props

  const { theme } = useTheme()

  const studioOffset = useSelector(getCurrentStudioOffset)

  // const [timeFilter, setTimeFilter] = useState<{ [key: string]: Dayjs | undefined }>({
  //   timeFrom: undefined,
  //   timeTo: undefined,
  // })

  // const [isTimeFilterOpen, setIsTimeFilterOpen] = useState<{ [key: string]: boolean }>({
  //   timeFrom: false,
  //   timeTo: false,
  // })
  const [timeFilter, setTimeFilter] = useState<[Nullable<Dayjs>, Nullable<Dayjs>]>()
  const [dateFilter, setDateFilter] = useState<[Nullable<Dayjs>, Nullable<Dayjs>]>()

  const [isTimeFilterOpen, setIsTimeFilterOpen] = useState<boolean>(false)
  const [isDateFilterOpen, setIsDateFilterOpen] = useState<boolean>(false)

  const paymentStatusOptions = useMemo(genScheduleExercisesFiltersPaymentStatusOptions, [])
  const paymentTypeOptions = useMemo(genScheduleExercisesFiltersPaymentTypeOptions, [])
  const timeScopeOptions = useMemo(genScheduleExercisesFiltersTimeScopeOptions, [])
  const bookingStatusOptions = useMemo(genScheduleExercisesFiltersBookingStatusOptions, [])

  const hasActiveFilters = useMemo(() => {
    return Object.values(exercisesFilter).some(
      value => value !== undefined && !(Array.isArray(value) && value.length === 0)
    )
  }, [exercisesFilter])

  const hasOpenTimeFilters = useMemo(() => {
    return Object.values(isTimeFilterOpen).some(value => value === true)
  }, [isTimeFilterOpen])

  const disabledDate = useCallback(
    (current: Dayjs) => {
      const startDate = dayjs(dateFrom)
      const endDate = dayjs(dateTo)

      return current.isBefore(startDate) || current.isAfter(endDate)
    },
    [dateFrom, dateTo]
  )

  const handleTrainersChange = useCallback(
    (newValue: string | string[]) => {
      const prevValue = exercisesFilter.trainerIds
      if (typeof newValue === 'object') {
        if (prevValue?.includes('noTrainer')) {
          onSelectFilter(
            'trainerIds',
            newValue.filter(value => value !== 'noTrainer')
          )
        } else if (newValue.includes('noTrainer')) {
          onSelectFilter('trainerIds', ['noTrainer'])
        } else {
          onSelectFilter('trainerIds', newValue)
        }
      }
    },
    [exercisesFilter.trainerIds, onSelectFilter]
  )

  const handleStudioChange = useCallback(
    (value: string | string[]) => {
      onSelectFilter('studioId', value)
      onSelectFilter('roomId', undefined)
    },
    [onSelectFilter]
  )

  // const handleTimeChange = useCallback(
  //   (value: Dayjs | undefined, name: string) => {
  //     setTimeFilter(prevTimeFilter => ({
  //       ...prevTimeFilter,
  //       [name]: value,
  //     }))
  //     if (value) {
  //       const timeZone = studioOffset ?? 0

  //       const time = value.subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
  //       onSelectFilter(name, time)
  //     } else {
  //       onSelectFilter(name, undefined)
  //       setIsTimeFilterOpen(prevTimeFilter => ({
  //         ...prevTimeFilter,
  //         [name]: false,
  //       }))
  //     }
  //   },
  //   [onSelectFilter, studioOffset]
  // )

  const handleTimeChange = useCallback(
    (time?: [Nullable<Dayjs>, Nullable<Dayjs>]) => {
      const timeZone = studioOffset ?? 0

      setTimeFilter(time)
      onSelectFilter('timeFrom', time?.[0] ? time[0].subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]') : undefined)
      onSelectFilter('timeTo', time?.[1] ? time[1].subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]') : undefined)
    },
    [onSelectFilter, studioOffset]
  )

  const handleDatesChange = useCallback(
    (dates?: [Nullable<Dayjs>, Nullable<Dayjs>]) => {
      setDateFilter(dates)
      onSelectFilter('dateFrom', dates?.[0] ? dates[0].format('YYYY-MM-DD') : undefined)
      onSelectFilter('dateTo', dates?.[1] ? dates[1].format('YYYY-MM-DD') : undefined)
    },
    [onSelectFilter]
  )

  const handleResetFilter = useCallback(() => {
    setTimeFilter([null, null])
    setIsTimeFilterOpen(false)
    setDateFilter([null, null])
    setIsDateFilterOpen(false)
    onResetFilter()
  }, [onResetFilter])

  // const toggleTimeFilter = useCallback(
  //   (key: 'timeFrom' | 'timeTo') => {
  //     setIsTimeFilterOpen(prevState => {
  //       const newState = {
  //         ...prevState,
  //         [key]: !prevState[key],
  //       }

  //       if (prevState[key]) {
  //         handleTimeChange(undefined, key)
  //       }

  //       return newState
  //     })
  //   },
  //   [handleTimeChange]
  // )

  const toggleTimeFilter = useCallback(() => {
    setIsTimeFilterOpen(!isTimeFilterOpen)
  }, [isTimeFilterOpen])

  const toggleDateFilter = useCallback(() => {
    setIsDateFilterOpen(!isDateFilterOpen)
  }, [isDateFilterOpen])

  useEffect(() => {
    if (!isDef(timeFilter)) {
      setIsTimeFilterOpen(false)
    }
  }, [timeFilter])

  useEffect(() => {
    if (!isTimeFilterOpen) {
      handleTimeChange()
    }
  }, [handleTimeChange, isTimeFilterOpen])

  useEffect(() => {
    if (!isDef(dateFilter)) {
      setIsDateFilterOpen(false)
    }
  }, [dateFilter])

  useEffect(() => {
    if (!isDateFilterOpen) {
      handleDatesChange()
    }
  }, [handleDatesChange, isDateFilterOpen])

  return {
    theme,
    timeFilter,
    dateFilter,
    paymentStatusOptions,
    paymentTypeOptions,
    timeScopeOptions,
    bookingStatusOptions,
    isTimeFilterOpen,
    isDateFilterOpen,
    hasActiveFilters,
    hasOpenTimeFilters,
    disabledDate,
    handleTrainersChange,
    handleStudioChange,
    handleTimeChange,
    handleDatesChange,
    handleResetFilter,
    toggleTimeFilter,
    toggleDateFilter,
  }
}
