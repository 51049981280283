import { Rule } from 'antd/es/form'
import dayjs from 'dayjs'

export function genScheduleSlotsItemTimeValidationRules(start: string, end: string, timeslots: Array<any>): Rule[] {
  return [
    {
      validator: (_, value) => {
        // if (!start && !end) {
        //   return Promise.reject('Выберите время начала и окончания')
        // } else if (!start) {
        //   return Promise.reject('Выберите время начала')
        // } else if (!end) {
        //   return Promise.reject('Выберите время окончания')
        // }

        if (dayjs(start, 'HH:mm').isAfter(dayjs(end, 'HH:mm'))) {
          return Promise.reject('Время начала должно быть раньше времени окончания')
        } else if (dayjs(start, 'HH:mm').isSame(dayjs(end, 'HH:mm'))) {
          return Promise.reject('Время начала не может быть равно времени окончания')
        }

        // if (timeslots?.length >= 2 && typeof index === 'number') {
        //   let hasError = false
        //   ;(timeslots ?? []).forEach((timeSlot, timeSlotIndex) => {
        //     if (index === timeSlotIndex) return
        //     if (
        //       dayjs(timeSlot?.time?.[0], 'HH:mm').isSame(dayjs(start, 'HH:mm')) ||
        //       dayjs(timeSlot?.time?.[1], 'HH:mm').isSame(dayjs(end, 'HH:mm'))
        //     ) {
        //       hasError = true
        //     }
        //   })
        //   if (hasError) return Promise.reject('Время не должно повторяться с предыдущими слотами времени')
        // }

        return Promise.resolve()
      },
    },
  ]
}
