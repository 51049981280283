import React from 'react'
import { clsx } from 'clsx'

import { DEFAULT_EMPTY_SYMBOL } from '../../../../format/text.format'
import { CardStatisticProps } from './contracts-card-statistic.types'
import style from './contracts-card-statistic.module.scss'

export const CardStatistic = ({
  classNames,
  value = DEFAULT_EMPTY_SYMBOL,
  title = DEFAULT_EMPTY_SYMBOL,
}: CardStatisticProps) => {
  return (
    <div className={clsx(style.container, classNames)}>
      <span className={style.title}>{title}</span>
      <span className={style.value}>{value}</span>
    </div>
  )
}
