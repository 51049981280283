import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStudio } from '../../hooks/use-studios.hook'
import { scheduleManagementPageTableActions } from '../../store/pages/schedule-management-page/schedule-management-page-table/schedule-management-page-table.slice'
import {
  genScheduleManagementPageTableIsLoaded,
  genScheduleManagementPageTableIsLoading,
} from '../../store/pages/schedule-management-page/schedule-management-page-table/schedule-management-page-table.selectors'
import { useScheduleManagementPageParams } from './schedule-management-page-hooks/schedule-management-page-params.hook'
import { peekActions } from '../../store/common/peek/peek.slice'
import { AppPeek } from '../../types/peek.types'

export function useScheduleManagementPage() {
  const params = useScheduleManagementPageParams()
  const { studioId: selectedStudioId } = useStudio()
  const { scheduleId, studioId } = params

  const dispatch = useDispatch()

  const isLoading = useSelector(genScheduleManagementPageTableIsLoading)
  const isLoaded = useSelector(genScheduleManagementPageTableIsLoaded)

  React.useEffect(() => {
    if (scheduleId) {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_MANAGEMENT_PAGE_PEEK_ABOUT,
          props: { scheduleId, studioId },
        })
      )
    }
  }, [dispatch, scheduleId, studioId])

  React.useEffect(() => {
    dispatch(scheduleManagementPageTableActions.fetchPageData(params))
  }, [dispatch, params])

  React.useEffect(() => {
    return () => {
      dispatch(scheduleManagementPageTableActions.reset())
    }
  }, [dispatch])

  return {
    studioId,
    selectedStudioId,
    isLoading,
    isLoaded,
  }
}
