import { createSelector } from '@reduxjs/toolkit'

import { mapTrainersToOptions } from '../../../../mapping/trainers.mapping'
import { AppState } from '../../../app.store'

export const getScheduleLongtermPageModalConflictsIsLoading = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPageModalConflicts.isLoading

const getScheduleLongtermPageModalConflictsTrainersInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPageModalConflicts.trainers

export const getScheduleLongtermPageModalConflictsTrainersOptions = createSelector(
  getScheduleLongtermPageModalConflictsTrainersInternal,
  trainers => mapTrainersToOptions(trainers?.content)
)
