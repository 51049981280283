import { ScheduleAboutPeek } from '../../../components/schedule/schedule-about-peek/schedule-about-peek.component'
import { useScheduleLongtermPagePeekAbout } from './schedule-longterm-page-peek-about.hook'
import { ScheduleLongtermPagePeekAboutProps } from './schedule-longterm-page-peek-about.types'

export const ScheduleLongtermPagePeekAbout: React.FC<ScheduleLongtermPagePeekAboutProps> = props => {
  const { scheduleId, studioId } = props

  const {
    isTimetableLoading,
    isExercisesLoading,
    schedule,
    exercises,
    exercisesFilter,
    totalElements,
    pagination,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    schedulePath,
    onChangePageHandler,
    onClientHandler,
    onExerciseHandler,
    onEditExercisesHandler,
    onAddExercisesHandler,
    onRemoveExerciseHandler,
    onCancelPeekHandler,
    onSelectFilterHandler,
    onResetFilterHandler,
  } = useScheduleLongtermPagePeekAbout(scheduleId, studioId)

  return (
    <ScheduleAboutPeek
      isTimetableLoading={isTimetableLoading}
      isExercisesLoading={isExercisesLoading}
      studioId={studioId}
      schedule={schedule}
      exercises={exercises}
      exercisesFilter={exercisesFilter}
      totalExercises={totalElements}
      pagination={pagination}
      trainersOptions={trainersOptions}
      studiosOptions={studiosOptions}
      roomsOptions={roomsOptions}
      schedulePath={schedulePath}
      longterm
      onChangePage={onChangePageHandler}
      onClient={onClientHandler}
      onExercise={onExerciseHandler}
      onEditExercises={onEditExercisesHandler}
      onAddExercises={onAddExercisesHandler}
      onRemoveExercise={onRemoveExerciseHandler}
      onCancelPeek={onCancelPeekHandler}
      onSelectFilter={onSelectFilterHandler}
      onResetFilter={onResetFilterHandler}
    />
  )
}
