import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { ExercisesDirectionsApi } from '../../../../api/types/exercises-directions-api.types'
import { ExercisesTypesApi } from '../../../../api/types/exercises-types-api.types'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import { TrainersApi } from '../../../../api/types/trainers-api.types'
import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleLongtermPageModalCreateFetchDictionariesSuccessPayload,
  ScheduleLongtermPageModalCreateSchedulePayload,
} from './schedule-longterm-page-modal-create.types'

export interface ScheduleLongtermPageModalCreateState {
  directions: Nullable<Pagination<ExercisesDirectionsApi.ExerciseDirection>>
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  studiosRooms: Nullable<Pagination<StudiosRoomsApi.StudioRoom>>
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ScheduleLongtermPageModalCreateState = {
  directions: null,
  exercisesTypes: null,
  studiosRooms: null,
  trainers: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: scheduleLongtermPageModalCreateActions, reducer: scheduleLongtermPageModalCreateReducer } =
  createSlice({
    name: '@@schedule-longterm-page-modal-create',
    initialState,
    reducers: {
      fetchDictionaries: (state: Draft<ScheduleLongtermPageModalCreateState>, _: PayloadAction<string>) => {
        state.isLoading = true
      },
      fetchDictionariesSuccess: (
        state: Draft<ScheduleLongtermPageModalCreateState>,
        action: PayloadAction<ScheduleLongtermPageModalCreateFetchDictionariesSuccessPayload>
      ) => {
        const { directions, exercisesTypes, studiosRooms, trainers } = action.payload

        state.directions = directions
        state.exercisesTypes = exercisesTypes
        state.studiosRooms = studiosRooms
        state.trainers = trainers
        state.isLoading = false
        state.isLoaded = true
      },
      fetchDictionariesError: (state: Draft<ScheduleLongtermPageModalCreateState>, action: PayloadAction<Error>) => {
        state.error = action.payload
        state.isLoading = false
        state.isLoaded = true
      },
      createSchedule: (
        state: Draft<ScheduleLongtermPageModalCreateState>,
        _: PayloadAction<ScheduleLongtermPageModalCreateSchedulePayload>
      ) => {
        state.isLoading = true
      },
      createScheduleSuccess: (state: Draft<ScheduleLongtermPageModalCreateState>) => {
        state.isLoading = false
      },
      createScheduleError: (state: Draft<ScheduleLongtermPageModalCreateState>, action: PayloadAction<Error>) => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })
