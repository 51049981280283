import { createSelector } from '@reduxjs/toolkit'

import { mapWebsocketTimetableMessagesToConflicts } from '../../../../mapping/exercises-timetable.mapping'
import { AppState } from '../../../app.store'
import { WebsocketTimetableMessageExerciseAction } from './websocket-timetable.types'

const getMessages = (state: AppState) => state.websocket.websocketTimetable.messages

export const getWebsocketTimetableMessages = createSelector(getMessages, messages =>
  messages?.filter(message => Object.values(WebsocketTimetableMessageExerciseAction).includes(message.action))
)

export const getWebsocketTimetableConflicts = createSelector(
  getWebsocketTimetableMessages,
  mapWebsocketTimetableMessagesToConflicts
)
