import { put, takeLatest, all } from 'redux-saga/effects'

import { genExercisesTimetableDTO, getTotalExercises } from '../../../../mapping/exercises-timetable.mapping'
import { isDef } from '../../../../types/lang.types'
import { api } from '../../../../api/api'
import { modalActions } from '../../../common/modal/modal.slice'
import { scheduleManagementPageModalCreateActions } from './schedule-management-page-modal-create.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { AppModal } from '../../../../types/modal.types'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { apiV2 } from '../../../../api/api-v2'
import { ScheduleModalConflictsAction } from '../../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.types'

function* fetchDictionaries(action: ReturnType<typeof scheduleManagementPageModalCreateActions.fetchDictionaries>) {
  try {
    const [exercisesTypesResponse, directionsResponse, studiosRoomsResponse, trainersResponse]: [
      Awaited<ReturnType<typeof api.exercisesTypesService.fetchAll>>,
      Awaited<ReturnType<typeof api.exercisesDirectionsService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosRoomsService.fetchAll>>,
      Awaited<ReturnType<typeof api.trainersService.fetchAll>>
    ] = yield all([
      callApi(api.exercisesTypesService.fetchAll, { size: 200, studioId: action.payload }),
      callApi(api.exercisesDirectionsService.fetchAll, { size: 200 }),
      callApi(api.studiosRoomsService.fetchAll, action.payload, { size: 200 }),
      callApi(api.trainersService.fetchAll, { size: 300 }),
    ])

    yield put(
      scheduleManagementPageModalCreateActions.fetchDictionariesSuccess({
        exercisesTypes: exercisesTypesResponse.data,
        directions: directionsResponse.data,
        studiosRooms: studiosRoomsResponse.data,
        trainers: trainersResponse.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPageModalCreateActions.fetchDictionariesError(new Error()))
  }
}

function* createSchedule(action: ReturnType<typeof scheduleManagementPageModalCreateActions.createSchedule>) {
  try {
    const { studioId, scheduleFormValues } = action.payload
    const exercisesTimetableDTO = genExercisesTimetableDTO(scheduleFormValues)

    if (isDef(exercisesTimetableDTO)) {
      const response: Awaited<ReturnType<typeof apiV2.exercisesTimetableService.createTimetable>> = yield callApi(
        apiV2.exercisesTimetableService.createTimetable,
        exercisesTimetableDTO
      )
      const { id, type, direction } = response.data
      const { timeslots, dateFrom, dateTo } = exercisesTimetableDTO

      if (id && type && direction) {
        const totalExercises = getTotalExercises(dateFrom, dateTo, exercisesTimetableDTO?.timeslots)

        yield put(websocketTimetableActions.connect(id))
        yield put(scheduleManagementPageModalCreateActions.createScheduleSuccess())

        yield put(modalActions.closeLast())
        yield put(
          modalActions.show({
            modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS,
            props: {
              studioId,
              scheduleId: id,
              action: ScheduleModalConflictsAction.CREATE,
              totalExercises,
              createdExercises: {
                typeId: type.id,
                directionId: direction.id,
                timeslots,
              },
            },
          })
        )
      }
    } else {
      yield put(scheduleManagementPageModalCreateActions.createScheduleError(new Error()))
    }
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPageModalCreateActions.createScheduleError(new Error()))
  }
}

export function* scheduleManagementPageModalCreateSagas() {
  yield takeLatest(scheduleManagementPageModalCreateActions.fetchDictionaries, fetchDictionaries)
  yield takeLatest(scheduleManagementPageModalCreateActions.createSchedule.type, createSchedule)
}
