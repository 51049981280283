import { useDispatch, useSelector } from 'react-redux'

import { getCounterpartiesIsCreating } from '../../../store/pages/contracts-page/contracts-page.selectors'
import { ContractsApi } from '../../../api/types/contracts-api.types'
import { contractsPageActions } from '../../../store/pages/contracts-page/contracts-page.slice'

export const useContractsHeader = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getCounterpartiesIsCreating)

  const onCreateCounterpartiesHandler = (clientsFormValues: ContractsApi.CounterpartiesCreateDTO): void => {
    dispatch(contractsPageActions.createCounterparties(clientsFormValues))
  }

  return {
    onCreateCounterpartiesHandler,
    isLoading,
  }
}
