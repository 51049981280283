import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import { Nullable } from '../../../../types/lang.types'
import { ScheduleManagementPageParams } from '../../../../pages/schedule-management-page/schedule-management-page.types'
import { ScheduleManagementPageTableFetchPageDataSuccessPayload } from './schedule-management-page-table.types'

export interface ScheduleManagementPageTableState {
  exercisesTimetable: Nullable<ExercisesTimetableApi.ExercisesTimetable[]>
  studiosRooms: Nullable<StudiosRoomsApi.StudioRoom[]>
  isLoading: boolean
  isLoaded: boolean
  isCanceling: boolean
  error: Nullable<Error>
}

const initialState: ScheduleManagementPageTableState = {
  exercisesTimetable: null,
  studiosRooms: null,
  isLoading: false,
  isLoaded: false,
  isCanceling: false,
  error: null,
}

export const { actions: scheduleManagementPageTableActions, reducer: scheduleManagementPageTableReducer } = createSlice(
  {
    name: '@@schedule-management-page-table',
    initialState,
    reducers: {
      fetchPageData: (
        state: Draft<ScheduleManagementPageTableState>,
        _: PayloadAction<ScheduleManagementPageParams>
      ) => {
        state.isLoading = true
      },
      fetchPageDataSuccess: (
        state: Draft<ScheduleManagementPageTableState>,
        action: PayloadAction<ScheduleManagementPageTableFetchPageDataSuccessPayload>
      ) => {
        const { exercisesTimetable, studiosRooms } = action.payload

        state.exercisesTimetable = exercisesTimetable
        state.studiosRooms = studiosRooms
        state.isLoading = false
        state.isLoaded = true
      },
      fetchPageDataError: (state: Draft<ScheduleManagementPageTableState>) => {
        state.isLoading = false
        state.isLoaded = true
      },
      fetchExercisesTimetable: (state: Draft<ScheduleManagementPageTableState>) => {
        state.isLoading = true
      },
      fetchExercisesTimetableSuccess: (
        state: Draft<ScheduleManagementPageTableState>,
        action: PayloadAction<Nullable<ExercisesTimetableApi.ExercisesTimetable[]>>
      ) => {
        state.exercisesTimetable = action.payload
        state.isLoading = false
        state.isLoaded = true
      },
      fetchExercisesTimetableError: (state: Draft<ScheduleManagementPageTableState>) => {
        state.isLoading = false
        state.isLoaded = true
      },
      cancelSchedule: (state: Draft<ScheduleManagementPageTableState>, _: PayloadAction<string>) => {
        state.isCanceling = true
      },
      cancelScheduleSuccess: (state: Draft<ScheduleManagementPageTableState>) => {
        state.isCanceling = false
      },
      cancelScheduleError: (state: Draft<ScheduleManagementPageTableState>) => {
        state.isCanceling = false
      },
      reset: () => initialState,
    },
  }
)
