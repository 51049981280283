import { put, select, take, takeLatest } from 'redux-saga/effects'

import { genScheduleExercisesEditDTO } from '../../../../mapping/exercises-timetable.mapping'
import { modalActions } from '../../../common/modal/modal.slice'
import { scheduleManagementPageModalEditActions } from './schedule-management-page-modal-edit.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { ScheduleExercisesFilter } from '../../../../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'
import {
  genScheduleManagementPagePeekAboutExercisesFilter,
  genScheduleManagementPagePeekAboutExercisesTotalElement,
} from '../schedule-management-page-peek-about/schedule-management-page-peek-about.selectors'
import { apiV2 } from '../../../../api/api-v2'
import { AppModal } from '../../../../types/modal.types'
import { ScheduleModalConflictsAction } from '../../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.types'

function* updateExercises(action: ReturnType<typeof scheduleManagementPageModalEditActions.updateExercises>) {
  const { studioId, scheduleId, scheduleFormValues } = action.payload
  const studioOffset: number | undefined = yield select(getCurrentStudioOffset)
  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleManagementPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleManagementPagePeekAboutExercisesTotalElement)

  const exercisesDTO = genScheduleExercisesEditDTO(scheduleFormValues, exercisesFilter, studioOffset)

  if (Object.keys(exercisesDTO.update).length !== 0) {
    yield put(websocketTimetableActions.connect(scheduleId))

    try {
      yield take(websocketTimetableActions.connectionSuccess.type)

      yield callApi(apiV2.exercisesTimetableService.updateExercisesByTimetableId, scheduleId, exercisesDTO)
      yield put(scheduleManagementPageModalEditActions.updateExercisesSuccess())
      yield put(modalActions.closeLast())
      yield put(
        modalActions.show({
          modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS,
          props: {
            studioId,
            scheduleId,
            action: ScheduleModalConflictsAction.EDIT,
            totalExercises,
          },
        })
      )
    } catch (e) {
      console.error(e)
      yield put(scheduleManagementPageModalEditActions.updateExercisesError(new Error()))
    }
  } else {
    yield put(scheduleManagementPageModalEditActions.updateExercisesSuccess())
    yield put(modalActions.closeLast())
  }
}

function* removeExercises(action: ReturnType<typeof scheduleManagementPageModalEditActions.removeExercises>) {
  const { studioId, scheduleId } = action.payload

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleManagementPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleManagementPagePeekAboutExercisesTotalElement)

  yield put(websocketTimetableActions.connect(scheduleId))

  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    yield callApi(apiV2.exercisesTimetableService.removeExercisesByTimetableId, scheduleId, exercisesFilter)
    yield put(scheduleManagementPageModalEditActions.removeExercisesSuccess())
    yield put(modalActions.closeLast())
    yield put(
      modalActions.show({
        modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_PROGRESS,
        props: {
          studioId,
          scheduleId,
          action: ScheduleModalConflictsAction.EDIT,
          totalExercises,
        },
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPageModalEditActions.removeExercisesError(new Error()))
  }
}

export function* scheduleManagementPageModalEditSagas() {
  yield takeLatest(scheduleManagementPageModalEditActions.updateExercises, updateExercises)
  yield takeLatest(scheduleManagementPageModalEditActions.removeExercises, removeExercises)
}
