import * as React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { genScheduleManagementPageParams } from '../schedule-management-page.utils'
import { ScheduleManagementPageQueryParams, ScheduleManagementPageUrlParams } from '../schedule-management-page.types'

export function useScheduleManagementPageParams() {
  const { params } = useRouteMatch<ScheduleManagementPageUrlParams>()
  const { search } = useLocation<ScheduleManagementPageQueryParams>()

  return React.useMemo(() => genScheduleManagementPageParams(params, search), [params, search])
}
