import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStudio } from '../../../hooks/use-studios.hook'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { getWebsocketTimetableMessages } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.selectors'
import { websocketTimetableActions } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.slice'
import { scheduleLongtermPageListActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.slice'
import { scheduleLongtermPagePeekAboutActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.slice'
import { AppModal } from '../../../types/modal.types'
import { ScheduleLongtermPageModalConflictsProps } from '../schedule-longterm-page-modal-conflicts/schedule-longterm-page-modal-conflicts.types'

export interface UseScheduleLongtermPageModalProgressProps extends ScheduleLongtermPageModalConflictsProps {}

export function useScheduleLongtermPageModalProgress(props: UseScheduleLongtermPageModalProgressProps) {
  const { studioId, scheduleId, action, createdExercises } = props

  const { studioOffset } = useStudio()

  const dispatch = useDispatch()

  const messages = useSelector(getWebsocketTimetableMessages)

  const onConflictResolutionHandler = useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFLICTS,
        props: {
          scheduleId,
          studioId,
          studioOffset,
          action,
          createdExercises,
        },
      })
    )
  }, [action, createdExercises, dispatch, scheduleId, studioId, studioOffset])

  const onCloseHandler = useCallback((): void => {
    dispatch(websocketTimetableActions.disconnect())
    dispatch(modalActions.close(AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS))
    dispatch(websocketTimetableActions.resetMessages())
    if (action === 'CREATE') {
      dispatch(scheduleLongtermPageListActions.fetchExercisesTimetable())
    } else {
      dispatch(scheduleLongtermPagePeekAboutActions.setDefaultFilter())
      dispatch(scheduleLongtermPagePeekAboutActions.fetchTimetable(scheduleId))
    }
  }, [action, dispatch, scheduleId])

  const onCancelHandler = useCallback(
    (isProgressComplete: boolean): void => {
      if (isProgressComplete) {
        onCloseHandler()
      } else {
        dispatch(
          modalActions.show({
            modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM,
            props: {
              title: 'Вы уверены что хотите завершить?',
              text: `${action === 'CREATE' ? 'Создание' : 'Редактирование'} длинной записи еще не завершено.`,
              onSubmit: onCloseHandler,
            },
          })
        )
      }
    },
    [action, dispatch, onCloseHandler]
  )

  return {
    messages,
    studioOffset,
    onConflictResolutionHandler,
    onCancelHandler,
  }
}
