import * as React from 'react'
import { Button, Flex, Form, Input, Radio, Typography } from 'antd'

import { PositionsFormProps, PositionsFormValues } from './positions-form.types'
import { CustomCard } from '../../custom-card/custom-card.component'
import { getPositionsOptions } from './positions-form.utils'
import './positions-form.styles.less'
import { PositionsFormGrades } from './positions-form-grades/positions-form-grades.component'
import { PositionsFormPermissions } from './positions-form-permissions/positions-form-permissions.component'

export const PositionsForm: React.FC<React.PropsWithChildren<PositionsFormProps>> = props => {
  const { form, isLoading, submitText, type, positionGrades } = props
  const { ...rest } = props
  const { onFinish, onUpdateGrade, onCreateGrade, onRemoveGrade } = props

  const onFinishHandler = React.useCallback(
    (values: PositionsFormValues): void => {
      onFinish(values)
    },
    [onFinish]
  )

  return (
    <Flex vertical gap={10}>
      <CustomCard customClassName="position-form">
        <Form form={form} onFinish={onFinishHandler} autoComplete="off" layout="vertical" disabled={isLoading}>
          <Flex vertical gap={24}>
            <Typography.Title className="position-form__title" level={4}>
              Основная информация
            </Typography.Title>
            <Flex vertical gap={16}>
              <Form.Item
                label="Название"
                name="name"
                rules={[{ required: true, message: 'Введите название должности' }]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="positionType"
                label="Тип должности"
                rules={[{ required: true, message: 'Выберите тип должности' }]}
              >
                <Radio.Group disabled={type === 'edit'} options={getPositionsOptions()}></Radio.Group>
              </Form.Item>
              <PositionsFormGrades
                form={form}
                type={type}
                positionGrades={positionGrades}
                onUpdateGrade={onUpdateGrade}
                onCreateGrade={onCreateGrade}
                onRemoveGrade={onRemoveGrade}
              />
            </Flex>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {submitText}
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </CustomCard>
      <PositionsFormPermissions {...rest} />
    </Flex>
  )
}
