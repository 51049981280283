import * as React from 'react'
import { Button, Flex, Form, Input, Typography } from 'antd'
import { EditOutlined, SaveOutlined, MinusOutlined } from '@ant-design/icons'

import { PositionsFormGradeProps } from './positions-form-grade.types'
import { usePositionsFormGrade } from './positions-form-grade.hook'

export const PositionsFormGrade: React.FC<PositionsFormGradeProps> = props => {
  const { index, field, type } = props

  const { disabled, onEditHandler, onSaveHandler, onDeleteHandler } = usePositionsFormGrade(props)

  return (
    <Form.Item
      label={
        index === 0 ? (
          <Typography.Title level={5} style={{ margin: '0 0 8px' }}>
            Грейды должности
          </Typography.Title>
        ) : (
          ''
        )
      }
      required={false}
      key={field.key}
    >
      <Flex gap={10}>
        <Form.Item
          {...field}
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Введите название грейда',
            },
          ]}
          noStyle
        >
          <Input placeholder="Название грейда" disabled={disabled} />
        </Form.Item>
        <Flex gap={10}>
          {type === 'edit' ? (
            disabled ? (
              <Button icon={<EditOutlined />} onClick={onEditHandler} />
            ) : (
              <Button icon={<SaveOutlined />} onClick={onSaveHandler} />
            )
          ) : null}
          <Button icon={<MinusOutlined />} danger onClick={onDeleteHandler} />
        </Flex>
      </Flex>
    </Form.Item>
  )
}
