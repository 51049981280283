import { Flex } from 'antd'

import { PositionsCreatePageForm } from './positions-create-page-form/positions-create-page-form.component'
import { PositionsCreatePageTopBar } from './positions-create-page-top-bar/positions-create-page-top-bar.component'

export const PositionsCreatePage = () => {
  return (
    <Flex vertical gap={24}>
      <PositionsCreatePageTopBar />
      <PositionsCreatePageForm />
    </Flex>
  )
}
