import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { genScheduleOverview, genScheduleExercises } from '../../../../mapping/exercises-timetable.mapping'
import { mapTrainersToOptions } from '../../../../mapping/trainers.mapping'
import { mapStudiosToOptions, mapStudiosToRoomsOptions } from '../../../../mapping/studios.mapping'

const genScheduleManagementPagePeekAboutScheduleInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.timetable

const genScheduleManagementPagePeekAboutExercisesInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.exercises

const getScheduleManagementPagePeekAboutTrainersInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.trainers

export const genScheduleManagementPagePeekAboutStudios = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.studios

export const genScheduleManagementPagePeekAboutIsExercisesLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.isExercisesLoading

export const genScheduleManagementPagePeekAboutIsTimetableLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.isTimetableLoading

export const genScheduleManagementPagePeekAboutExercisesFilter = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPagePeekAbout.exercisesFilter

export const genScheduleManagementPagePeekAboutSchedule = createSelector(
  genScheduleManagementPagePeekAboutScheduleInternal,
  genScheduleOverview
)

export const genScheduleManagementPagePeekAboutExercises = createSelector(
  genScheduleManagementPagePeekAboutExercisesInternal,
  exercises => genScheduleExercises(exercises?.content)
)

export const genScheduleManagementPagePeekAboutExercisesTotalElement = createSelector(
  genScheduleManagementPagePeekAboutExercisesInternal,
  exercises => exercises?.totalElements
)

export const genScheduleManagementPagePeekAboutTrainersOptions = createSelector(
  getScheduleManagementPagePeekAboutTrainersInternal,
  trainers => mapTrainersToOptions(trainers?.content)
)

export const genScheduleManagementPagePeekAboutFilterTrainersOptions = createSelector(
  genScheduleManagementPagePeekAboutScheduleInternal,
  schedule =>
    schedule?.trainers?.map(trainer => ({
      label: trainer.name,
      value: trainer.id,
    }))
)

export const genScheduleManagementPagePeekAboutStudiosOptions = createSelector(
  genScheduleManagementPagePeekAboutStudios,
  studios => mapStudiosToOptions(studios?.content)
)

export const genScheduleManagementPagePeekAboutStudiosRoomsOptions = createSelector(
  genScheduleManagementPagePeekAboutStudios,
  studios => mapStudiosToRoomsOptions(studios?.content)
)

export const genScheduleManagementPagePeekAboutFilterRoomsOptions = createSelector(
  genScheduleManagementPagePeekAboutScheduleInternal,
  schedule =>
    schedule?.rooms?.map(room => ({
      label: room.name,
      value: room.id,
    }))
)
