import { createSelector } from '@reduxjs/toolkit'

import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { mapTrainersToOptions } from '../../../../mapping/trainers.mapping'
import { AppState } from '../../../app.store'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genScheduleAuditInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekHistory.scheduleAudit
const genTrainersInternal = (state: AppState) => state.scheduleLongtermPage.scheduleLongtermPagePeekHistory.trainers

export const genScheduleLongtermPagePeekHistoryIsLoading = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekHistory.isLoading

export const genScheduleLongtermPagePeekHistoryTotalElements = createSelector(
  genScheduleAuditInternal,
  audit => audit?.totalElements
)

export const genScheduleLongtermPagePeekHistoryScheduleHistory = createSelector(
  genScheduleAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

export const genScheduleLongtermPagePeekHistoryTrainersOptions = createSelector(genTrainersInternal, trainers =>
  mapTrainersToOptions(trainers?.content)
)
