import React from 'react'

import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { useContractsPageList } from './contracts-card-list.hook'
import { ContractsCard } from '../contracts-card/contracts-card.component'
import style from './contracts-card-list.module.scss'

export const ContractsCardList = () => {
  const { counterparties, managers, onChangeManagerHandler, isLoading } = useContractsPageList()

  if (!isLoading && (counterparties?.length === 0 || counterparties === null)) {
    return <PageEmpty description="Нет контрагентов" />
  }

  return (
    <div className={style.containerContractsCards}>
      {!isLoading &&
        counterparties?.map(counterparty => {
          const { id, telephone, clientCount, email, directorName, company, activeContracts } = counterparty

          if (activeContracts.length > 0) {
            return activeContracts.map(contract => (
              <ContractsCard
                key={contract.id}
                id={contract.id}
                telephone={telephone}
                email={email}
                directorName={directorName}
                companyName={company}
                clientCount={clientCount}
                contract={contract}
                contractStatus={contract.status}
                contractType={contract.type}
                managerName={contract.manager.name}
                validUntil={contract.validUntil}
                contractTurnover={contract.currentTurnover}
                contractAmount={contract.maxTotalAmount}
                managers={managers}
                onChangeManagers={onChangeManagerHandler}
              />
            ))
          } else {
            return (
              <ContractsCard
                id={id}
                key={id}
                directorName={directorName}
                telephone={telephone}
                email={email}
                companyName={company}
              />
            )
          }
        })}
    </div>
  )
}
