import React from 'react'
import { Avatar, Badge, Card, Flex, Select, Tag, Typography } from 'antd'
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { PresetStatusColorType } from 'antd/es/_util/colors'

import { formatPhoneNumber } from '../../../format/phone.format'
import { formatRublesPenniesCurrency } from '../../../format/number.format'
import { genContractsPageInfoPath } from '../../../format/path.format'
import { formatDateToHumanize } from '../../../format/date.format'
import { ContractsApi } from '../../../api/types/contracts-api.types'
import { ContractsCardProps } from './contracts-card.types'
import { CardStatistic } from './contracts-card-statistic/contracts-card-statistic.component'
import style from './contracts-card.module.scss'
import { useCalculateContractBalance } from './contracts-card.utils'

const contractBadgeStatusObj: Record<ContractsApi.ContractsStatus, PresetStatusColorType> = {
  NOT_STARTED: 'default',
  ACTIVE: 'success',
  AWAITS_PAYMENT: 'warning',
  EXPIRED: 'error',
}

const contractBadgeTextObj: Record<ContractsApi.ContractsStatus, string> = {
  NOT_STARTED: '',
  ACTIVE: 'Активный',
  AWAITS_PAYMENT: 'Оплата',
  EXPIRED: 'Архив',
}

const contractTagTextObj: Record<ContractsApi.ContractsType, string> = {
  POSTPAY: 'Постоплата',
  PREPAY: 'Предоплата',
}

export const ContractsCard = ({
  id,
  telephone,
  email,
  companyName,
  directorName,
  managerName,
  validUntil = '',
  contract = null,
  contractType = 'PREPAY',
  contractStatus,
  managers,
  contractTurnover = 0,
  contractAmount = 0,
  clientCount = 0,
  onChangeManagers,
}: ContractsCardProps) => {
  const expiresDate = formatDateToHumanize(validUntil)
  const telephoneNumber = formatPhoneNumber(`+${telephone}`)
  const turnover = formatRublesPenniesCurrency(contractTurnover)
  const { balance } = useCalculateContractBalance(contractAmount, contractType)

  function handleChangeManager(managerId: string) {
    if (id && contract) {
      onChangeManagers?.({ managerId }, contract.id)
    }
  }

  return (
    <Card
      hoverable
      actions={[
        <Flex gap={'middle'} vertical className={style.cardActions}>
          {contract && (
            <Flex justify={'flex-start'}>
              <Tag>{(contractType && contractTagTextObj[contractType]) || 'Нет даты'}</Tag>
            </Flex>
          )}
          <Flex gap={contract ? 'small' : 'middle'} vertical style={{ width: '280px' }}>
            <Flex gap={'small'}>
              <Flex gap={'small'} align={'center'} style={{ width: '150px' }}>
                <MailOutlined />
                <Typography.Link href={`mailto:${email}`} ellipsis>
                  {email || 'Email не указан'}
                </Typography.Link>
              </Flex>
              <Flex gap={'small'} style={{ width: '130px' }}>
                <PhoneOutlined />
                <Typography.Link href={`tel:+${telephone}`} ellipsis>
                  {telephoneNumber || 'Телефон не указан'}
                </Typography.Link>
              </Flex>
            </Flex>
            {managerName && (
              <Select
                defaultValue={`Менеджер: ${managerName}`}
                className={style.managerSelect}
                onChange={handleChangeManager}
                options={managers}
              />
            )}
          </Flex>
        </Flex>,
      ]}
    >
      <Link to={genContractsPageInfoPath(id)}>
        <Flex className={style.header} gap={'middle'} justify={'space-between'}>
          <Flex gap={'middle'}>
            <Avatar icon={<UserOutlined />} />
            <Flex className={style.nameContainer} vertical>
              <Typography.Title level={5} className={style.organizationName}>
                {companyName}
              </Typography.Title>
              <Typography.Text type={'secondary'}>{directorName}</Typography.Text>
            </Flex>
          </Flex>
          {contract && (
            <Flex vertical>
              <Badge
                status={contractStatus && contractBadgeStatusObj[contractStatus]}
                text={(contractStatus && contractBadgeTextObj[contractStatus]) || 'Закрыт'}
              />
              <Typography.Text type={'secondary'} className={style.date}>
                До {expiresDate}
              </Typography.Text>
            </Flex>
          )}
        </Flex>
        <Flex justify={'space-around'} gap={'small'}>
          <CardStatistic title="Сотрудники" value={clientCount} />
          {contract && (
            <>
              <CardStatistic title="Баланс" value={balance} />
              <CardStatistic title="Оборот" value={turnover} />
            </>
          )}
        </Flex>
      </Link>
    </Card>
  )
}
