import { ColumnsType } from 'antd/lib/table'
import { CheckCircleTwoTone } from '@ant-design/icons'

import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { PositionsTableActions, PositionsTableDataItem } from './positions-table.types'
import { PositionsTableColumnActions } from './positions-table-actions/positions-table-actions.component'
import { PositionType } from '../../../types/positions.types'

export function genPositionsTableColumns(events: PositionsTableActions): ColumnsType<PositionsTableDataItem> {
  const { positionsOptions, onEdit, onRemove } = events

  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (_, position) => <TableCellText text={String(position.name)} />,
    },
    {
      title: 'Наличие грейдов',
      dataIndex: 'grades',
      key: 'grades',
      render: (_, position) => (position.gradesCount ? <CheckCircleTwoTone /> : null),
    },
    {
      title: 'Должность-исполнитель',
      dataIndex: 'isExecutort',
      key: 'isExecutort',
      render: (_, position) => (position.positionType === PositionType.EXECUTOR ? <CheckCircleTwoTone /> : null),
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 120,
      render: (_, position) => (
        <PositionsTableColumnActions
          id={position.id}
          positionType={position.positionType}
          name={position.name}
          positionsOptions={positionsOptions}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ),
    },
  ]
}
