import { FormInstance } from 'antd'
import { useState, useMemo, useEffect } from 'react'

import { PermissionsApi } from '../../../../../api/types/permissions.types'
import { useTheme } from '../../../../../hooks/use-theme.hook'
import { isDefAndNotEmpty, isDef } from '../../../../../types/lang.types'
import { PositionsFormPermissionsValues } from '../positions-form-permissions.types'
import { PositionsFormPermissionsGroupOptions } from './positions-form-permissions-group.types'

export interface Props extends PositionsFormPermissionsGroupOptions {
  groupItem: PermissionsApi.Permissions
  permissionsForm?: FormInstance<PositionsFormPermissionsValues>
  handlePermissionChange: (resourceName: string, action: string, checked: boolean, resourceIds?: string[]) => void
}

export function usePositionsFormPermissionsGroup(props: Props) {
  const { permissionsForm, groupItem } = props
  const { positionsOptions, studiosOptions, clientsCategoriesOptions } = props
  const { handlePermissionChange } = props

  const { theme } = useTheme()

  const [switchStates, setSwitchStates] = useState<{ [name: string]: boolean }>({})

  const selectOptions = useMemo(() => {
    if (groupItem.name === 'studio') return studiosOptions
    if (groupItem.name === 'position') return positionsOptions
    if (groupItem.name === 'client_category') return clientsCategoriesOptions
  }, [clientsCategoriesOptions, groupItem.name, positionsOptions, studiosOptions])

  const handleSwitchChange = (actionName: string, checked: boolean) => {
    const values = permissionsForm?.getFieldValue([groupItem.name, actionName])

    setSwitchStates(prev => ({
      ...prev,
      [actionName]: checked,
    }))

    if (!checked && isDefAndNotEmpty(values)) {
      permissionsForm?.setFieldValue([groupItem.name, actionName], [])
      handlePermissionChange(groupItem.name, actionName, false, values)
    }
  }

  useEffect(() => {
    const initialSwitchStates: { [name: string]: boolean } = {}

    groupItem.actions.forEach(action => {
      const fieldValue = groupItem.permissions.filter(
        permission => permission.action === action.name && permission.allowed && isDef(permission.resourceId)
      )
      initialSwitchStates[action.name] = Array.isArray(fieldValue) && fieldValue.length > 0
    })

    setSwitchStates(initialSwitchStates)
  }, [groupItem])

  return {
    theme,
    switchStates,
    selectOptions,
    handleSwitchChange,
  }
}
