import { ScheduleModalAdd } from '../../../components/schedule/schedule-modal-add/schedule-modal-add.component'
import { useScheduleManagementPageModalAdd } from './schedule-management-page-modal-add.hook'
import { ScheduleManagementPageModalAddProps } from './schedule-management-page-modal-add.types'

export const ScheduleManagementPageModalAdd: React.FC<ScheduleManagementPageModalAddProps> = props => {
  const { scheduleId, studioId, studioOffset } = props

  const { isLoading, form, roomsOptions, trainersOptions, onCancelHandler, onSaveHandler } =
    useScheduleManagementPageModalAdd(scheduleId, studioId)

  return (
    <ScheduleModalAdd
      title="Редактирование расписания"
      isLoading={isLoading}
      form={form}
      studioOffset={studioOffset ?? 0}
      studiosRoomsOptions={roomsOptions}
      trainersOptions={trainersOptions}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
    />
  )
}
