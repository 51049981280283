import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { Form } from 'antd'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { genScheduleManagementPagePeekAboutTrainersOptions } from '../../../store/pages/schedule-management-page/schedule-management-page-peek-about/schedule-management-page-peek-about.selectors'
import { scheduleManagementPageModalAddActions } from '../../../store/pages/schedule-management-page/schedule-management-page-modal-add/schedule-management-page-modal-add.slice'
import { getScheduleManagementPageModalAddIsLoading } from '../../../store/pages/schedule-management-page/schedule-management-page-modal-add/schedule-management-page-modal-add.selectors'
import { genScheduleManagementPageTableStudiosRoomsOptions } from '../../../store/pages/schedule-management-page/schedule-management-page-table/schedule-management-page-table.selectors'

export function useScheduleManagementPageModalAdd(scheduleId: string, studioId: string) {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const isLoading = useSelector(getScheduleManagementPageModalAddIsLoading)
  const trainersOptions = useSelector(genScheduleManagementPagePeekAboutTrainersOptions)
  const roomsOptions = useSelector(genScheduleManagementPageTableStudiosRoomsOptions)

  const onSaveHandler = useCallback(() => {
    const scheduleFormValues = form.getFieldsValue()
    dispatch(scheduleManagementPageModalAddActions.addExercises({ studioId, scheduleId, scheduleFormValues }))
  }, [dispatch, form, scheduleId, studioId])

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(scheduleManagementPageModalAddActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    form,
    trainersOptions,
    roomsOptions,
    onCancelHandler,
    onSaveHandler,
  }
}
