import { List } from 'antd'

import { ScheduleListCard } from './schedule-list-card/schedule-list-card.component'
import { ScheduleListProps } from './schedule-list.types'

export const ScheduleList: React.FC<ScheduleListProps> = props => {
  const { schedule, ...rest } = props

  return (
    <List
      itemLayout="vertical"
      dataSource={schedule}
      renderItem={item => <ScheduleListCard scheduleItem={item} {...rest} />}
    />
  )
}
