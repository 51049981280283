import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'

import {
  genScheduleManagementPageModalEditIsLoading,
  getScheduleManagementPageModalEditFormValues,
} from '../../../store/pages/schedule-management-page/schedule-management-page-modal-edit/schedule-management-page-modal-edit.selectors'
import { scheduleManagementPageModalEditActions } from '../../../store/pages/schedule-management-page/schedule-management-page-modal-edit/schedule-management-page-modal-edit.slice'
import {
  genScheduleManagementPagePeekAboutExercisesTotalElement,
  genScheduleManagementPagePeekAboutStudiosOptions,
  genScheduleManagementPagePeekAboutStudiosRoomsOptions,
  genScheduleManagementPagePeekAboutTrainersOptions,
} from '../../../store/pages/schedule-management-page/schedule-management-page-peek-about/schedule-management-page-peek-about.selectors'
import { modalActions } from '../../../store/common/modal/modal.slice'

export function useScheduleManagementPageModalEdit(studioId: string, scheduleId: string) {
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const studiosOptions = useSelector(genScheduleManagementPagePeekAboutStudiosOptions)
  const studiosRoomsOptions = useSelector(genScheduleManagementPagePeekAboutStudiosRoomsOptions)
  const trainersOptions = useSelector(genScheduleManagementPagePeekAboutTrainersOptions)
  const isLoading = useSelector(genScheduleManagementPageModalEditIsLoading)
  const formValues = useSelector(getScheduleManagementPageModalEditFormValues)

  const totalExercises = useSelector(genScheduleManagementPagePeekAboutExercisesTotalElement)

  const onEditExercisesHandler = useCallback((): void => {
    const scheduleFormValues = form.getFieldsValue()
    dispatch(scheduleManagementPageModalEditActions.updateExercises({ studioId, scheduleId, scheduleFormValues }))
  }, [dispatch, form, scheduleId, studioId])

  const onRemoveExercisesHandler = useCallback((): void => {
    dispatch(scheduleManagementPageModalEditActions.removeExercises({ studioId, scheduleId }))
  }, [dispatch, scheduleId, studioId])

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [dispatch, form, formValues])

  useEffect(() => {
    return () => {
      dispatch(scheduleManagementPageModalEditActions.reset())
    }
  }, [dispatch])

  return {
    form,
    formValues,
    isLoading,
    totalExercises,
    studiosOptions,
    studiosRoomsOptions,
    trainersOptions,
    onEditExercisesHandler,
    onRemoveExercisesHandler,
    onCancelHandler,
  }
}
