import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { peekActions } from '../../../store/common/peek/peek.slice'
import {
  genScheduleLongtermPageListIsLoading,
  genScheduleLongtermPageListTableDataItems,
} from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.selectors'
import { AppPeek } from '../../../types/peek.types'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { useScheduleLongtermPageParams } from '../schedule-longterm-page-hooks/schedule-longterm-page-params.hook'
import { scheduleLongtermPageListActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.slice'
import { ExerciseTypeFormat } from '../../../api/types/api.types'

export function useScheduleLongtermPageTable() {
  const dispatch = useDispatch()
  const history = useHistory()

  const params = useScheduleLongtermPageParams()
  const { studioId } = params

  const schedule = useSelector(genScheduleLongtermPageListTableDataItems)
  const isLoading = useSelector(genScheduleLongtermPageListIsLoading)

  const onCreateTransactionHandler = (phone: string, clientId: string) => {
    history.push({
      pathname: `/transactions/${studioId}/create`,
      state: { phone, clientId },
    })
  }

  const onEditHandler = useCallback(
    (id: string): void => {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_LONGTERM_PAGE_PEEK_ABOUT,
          props: { scheduleId: id, studioId },
        })
      )
    },
    [dispatch, studioId]
  )

  const onRemoveHandler = useCallback(
    (id: string): void => {
      const onSubmit = () => {
        dispatch(scheduleLongtermPageListActions.cancelSchedule(id))
      }

      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM,
          props: {
            title: 'Отмена длинной записи',
            text: 'Вы уверены, что хотите отменить длинную запись',
            onSubmit,
          },
        })
      )
    },
    [dispatch]
  )

  const onHistoryHandler = useCallback(
    (id: string): void => {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_LONGTERM_PAGE_PEEK_HISTORY,
          props: { id, format: ExerciseTypeFormat.PERSONAL },
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    return () => {
      dispatch(scheduleLongtermPageListActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    schedule,
    onCreateTransactionHandler,
    onEditHandler,
    onRemoveHandler,
    onHistoryHandler,
  }
}
