import React from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'

import { ContractsPageInfoParams } from './contracts-info-page.types'

export const ContractsPageInfo = () => {
  const { id } = useParams<ContractsPageInfoParams>()

  return <Typography.Title level={2}>ООО Альфа банк | Договор {id}</Typography.Title>
}
