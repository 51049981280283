import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useStudio } from '../../../hooks/use-studios.hook'
import {
  genScheduleManagementPageTableIsLoading,
  genScheduleManagementPageTableTableDataItems,
} from '../../../store/pages/schedule-management-page/schedule-management-page-table/schedule-management-page-table.selectors'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { isDef } from '../../../types/lang.types'
import { AppPeek } from '../../../types/peek.types'
import { peekActions } from '../../../store/common/peek/peek.slice'
import { ExerciseTypeFormat } from '../../../api/types/api.types'
import { scheduleManagementPageTableActions } from '../../../store/pages/schedule-management-page/schedule-management-page-table/schedule-management-page-table.slice'

export function useScheduleManagementPageTable() {
  const { studioId } = useStudio()

  const dispatch = useDispatch()

  const schedule = useSelector(genScheduleManagementPageTableTableDataItems)
  const isLoading = useSelector(genScheduleManagementPageTableIsLoading)

  const onEditHandler = React.useCallback(
    (id: string): void => {
      if (isDef(studioId)) {
        dispatch(
          peekActions.show({
            peek: AppPeek.SCHEDULE_MANAGEMENT_PAGE_PEEK_ABOUT,
            props: { scheduleId: id, studioId },
          })
        )
      }
    },
    [dispatch, studioId]
  )

  const onRemoveHandler = React.useCallback(
    (id: string): void => {
      const onSubmit = () => {
        dispatch(scheduleManagementPageTableActions.cancelSchedule(id))
      }

      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM,
          props: {
            title: 'Отмена длинной записи',
            text: 'Вы уверены, что хотите отменить длинную запись',
            onSubmit,
          },
        })
      )
    },
    [dispatch]
  )

  const onHistoryHandler = React.useCallback(
    (id: string): void => {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_MANAGEMENT_PAGE_PEEK_HISTORY,
          props: { id, format: ExerciseTypeFormat.GROUP },
        })
      )
    },
    [dispatch]
  )

  React.useEffect(() => {
    return () => {
      dispatch(scheduleManagementPageTableActions.reset())
    }
  }, [dispatch])

  return {
    schedule,
    isLoading,
    onEditHandler,
    onRemoveHandler,
    onHistoryHandler,
  }
}
