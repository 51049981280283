import { DefaultOptionType } from 'antd/lib/select'
import { SearchApi } from '@api/types/search-api.types'

import { PositionsApi } from '../api/types/positions-api.types'
import { PositionsTableDataItem } from '../components/positions/positions-table/positions-table.types'
import { Nullable, isDef, isDefAndNotEmpty } from '../types/lang.types'
import { PositionType } from '../types/positions.types'
import { sortOptions } from '../utils/options.utils'
import { PositionsFormGradeValues } from '../components/positions/positions-form/positions-form-grades/positions-form-grade/positions-form-grade.types'
import { PositionsFormValues } from '../components/positions/positions-form/positions-form.types'
import { PermissionsApi } from '../api/types/permissions.types'
import { PositionsFormPermissionsValues } from '../components/positions/positions-form/positions-form-permissions/positions-form-permissions.types'

export function mapPositionsToOptions(positions: Nullable<PositionsApi.Position[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(positions)) {
    const options = positions.map(
      (position: PositionsApi.Position): DefaultOptionType => ({
        value: position.id,
        label: position.name,
      })
    )
    return sortOptions(options)
  }
}

export function mapPositionsToRemovePositionOptions(
  positions: Nullable<PositionsApi.Position[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(positions)) {
    return positions
      .filter(position => position.positionType === PositionType.CUSTOM)
      .reduce((acc: DefaultOptionType[], position: PositionsApi.Position) => {
        acc.push({
          value: position.id,
          label: position.name,
        })

        acc.sort((optionA, optionB) =>
          typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
            ? -1
            : 1
        )
        return acc
      }, [])
  }
}

export function mapPositionsToPositionsTableRowList(
  positions: Nullable<PositionsApi.Position[]>
): Nullable<PositionsTableDataItem[]> {
  if (isDefAndNotEmpty(positions)) {
    return positions.map(position => ({
      id: position.id,
      name: position.name,
      gradesCount: position.grades ? position.grades.length : null,
      positionType: position.positionType,
    }))
  }
}

export function genPositionGrades(data: Nullable<PositionsApi.Position>): Nullable<PositionsFormGradeValues[]> {
  if (isDef(data) && isDefAndNotEmpty(data.grades)) {
    return data.grades.map(grade => ({
      id: grade.id,
      name: grade.name,
    }))
  }
}

export function mapPositionGradesToOptions(data: Nullable<PositionsApi.Position>): DefaultOptionType[] | undefined {
  if (isDef(data)) {
    const options: DefaultOptionType[] = []

    data.grades?.forEach(grade => {
      options.push({
        value: grade.id,
        label: `Для грейда ${grade.name}`,
      })
    })

    sortOptions(options)

    options.unshift({
      value: data.id,
      label: 'Для должности',
    })

    return options
  }
}

export function genPositionFormValues(data: Nullable<PositionsApi.Position>): Nullable<PositionsFormValues> {
  if (isDef(data)) {
    return {
      name: data.name,
      grades: data.grades?.map(grade => grade.name),
      positionType: data.positionType,
    }
  }
}

export function genPositionPermissionsFormValues(
  positionPermissions: Nullable<PermissionsApi.Permissions[]>
): Nullable<PositionsFormPermissionsValues> {
  if (isDef(positionPermissions)) {
    const formValues: PositionsFormPermissionsValues = {}

    positionPermissions.forEach(resource => {
      formValues[resource.name] = {}
      if (!resource.granular) {
        resource.permissions?.forEach(permission => {
          formValues[resource.name][permission.action] = permission.allowed
        })
      } else {
        resource.actions?.forEach(action => {
          const resourceIds: string[] = resource.permissions
            ?.filter(permission => permission.action === action.name && permission.allowed)
            ?.map(permission => permission.resourceId)
            ?.filter((id): id is string => isDef(id))

          formValues[resource.name][action.name] = resourceIds?.length > 0 ? resourceIds : []
        })
      }
    })

    return formValues
  }
}

export function genPositionDTO(data: PositionsFormValues): PositionsApi.PositionDTO {
  return {
    name: data.name,
    positionType: data.positionType,
  }
}

export function genGradeCreateDTO(
  name: string,
  positionId: string,
  positionType: PositionType
): PositionsApi.GradeCreateDTO {
  return {
    name: name,
    parentId: positionId,
    positionType: positionType,
  }
}

export function genGradeUpdateDTO(name: string): PositionsApi.GradeUpdateDTO {
  return {
    name: name,
  }
}

export function mapPositionsToPositionsAutocompleteOptionDataItems(positions: Nullable<SearchApi.SearchItem[]>) {
  if (isDefAndNotEmpty(positions)) {
    return positions?.map((position: SearchApi.SearchItem) => {
      const { id, details, label } = position

      return {
        slug: id,
        label: label,
        details: details,
      }
    })
  }

  return null
}
